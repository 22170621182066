import axios from 'axios';
import {
 authHeaders,
 GET_CUSTOMER_INTEREST_CATEGORIES,
 GET_CUSTOMER_INTEREST_CATEGORY,
 POST_CUSTOMER_INTEREST_CATEGORY,
 PATCH_CUSTOMER_INTEREST_CATEGORY,
 DELETE_CUSTOMER_INTEREST_CATEGORY,
} from 'actions';

/** Get customer interest categories
 * @param {
 *      name: string
 *      page: int
 *      page_size: int
 * } payload
 */
export const getCustomerInterestCategories = (params, token) => axios.get(
    GET_CUSTOMER_INTEREST_CATEGORIES,
    params
    ? {
        params,
        ...authHeaders(token),
    }
    : authHeaders(token),
);

/** Get a customer interest category
 * @route {
 *      customerInterestCategoryId: uuid
 * }
 */
 export const getCustomerInterestCategory = (customerInterestCategoryId, token) => axios.get(
     GET_CUSTOMER_INTEREST_CATEGORY.replace(':customerInterestCategoryId', customerInterestCategoryId),
     authHeaders(token),
);

 /** Add new customer interest category
  * @param {
  *      tenant: uuid,
  *      name: string
  * }
  */
 export const addCustomerInterestCategory = (payload, token) => {
     if (!payload) return null;
     return axios.post(POST_CUSTOMER_INTEREST_CATEGORY, payload, authHeaders(token));
 };

 /** Update customer interest category
  * @param {
  *      tenant: uuid,
  *      name: string
  * }
  */
 export const updateCustomerInterestCategory = (customerInterestCategoryId, payload, token) => {
     if (!payload) return null;
     return axios.patch(PATCH_CUSTOMER_INTEREST_CATEGORY.replace(':customerInterestCategoryId', customerInterestCategoryId), payload, authHeaders(token));
 };

 /** Delete customer interest category
  * @route {
  *      customerInterestCategoryId: uuid
  * }
  */
 export const deleteCustomerInterestCategory = (customerInterestCategoryId, token) => axios.delete(
     DELETE_CUSTOMER_INTEREST_CATEGORY.replace(':customerInterestCategoryId', customerInterestCategoryId),
     authHeaders(token),
);
