import {
 createContext, useContext, useEffect, useState,
} from 'react';
import { getTenant } from 'actions';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';

export const TenantContext = createContext({} as any);

export const TenantContextProvider = ({ children }) => {
    const { setTenantLoading, handleException } = useContext(HomeContext);
    const { loggedInUser, userInfo } = useContext(AuthContext);
    const [tenant, setTenant] = useState<any>();

    /*eslint-disable */
    useEffect(() => {
        if (!tenant && userInfo?.tenant) {
            doGetTenant(userInfo?.tenant, null);
        }
    }, [userInfo])
    /* eslint-enable */

    const doGetTenant = async (tenantId, callback) => {
        try {
            setTenantLoading(true);

            const response = await getTenant(tenantId, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                setTenant(data);
                if (callback) callback();
            }

            setTenantLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <TenantContext.Provider
            value={{
                tenant,
                doGetTenant,
            }}
        >
            {children}
        </TenantContext.Provider>
    );
};
