import moment from 'moment';
import address_code from 'json/address_code.json';
import address_data_full from 'json/address_data_full.json';
import avatar from 'assets/images/avatar.png';
import {
    HOME_URL,
    APPOINTMENT_URL,
    SALES_URL,
    CLIENTS_URL,
    STAFFS_URL,
    BRANCH_URL,
    CHECKIN_OUT_URL,
    COMBO_URL,
    CUSTOMER_EXPERIENCE_URL,
    DEBT_URL,
    DISCOUNT_URL,
    LANDING_PAGE_URL,
    LISTINGS_URL,
    LOYALTY_URL,
    OVERVIEW_URL,
    REGIMEN_URL,
    REPORT_URL,
    REVENUE_URL,
    SERVICES_URL,
    SETTINGS_URL,
    SMART_MARKETING_URL,
    MALE,
    FEMALE,
    UNKNOWN
} from "actions";

export const allOption = {
    value: 0,
    label: 'Tất cả'
}

export const menus = [
    // {
    //     id: 1,
    //     icon: 'icon-home',
    //     label: 'Trang chủ',
    //     url: HOME_URL
    // },
    {
        id: 2,
        icon: 'icon-calendar',
        label: 'Lịch hẹn',
        url: APPOINTMENT_URL
    },
    {
        id: 3,
        icon: 'icon-shopping-cart',
        label: 'Bán hàng',
        url: SALES_URL
    },
    // {
    //     id: 4,
    //     icon: 'icon-calendar-2',
    //     label: 'Nhân viên',
    //     url: STAFFS_URL
    // },
    {
        id: 5,
        icon: 'icon-user',
        label: 'Khách hàng',
        url: CLIENTS_URL
    },
    // {
    //     id: 6,
    //     icon: 'icon-suitecase',
    //     label: 'Dich vụ',
    //     url: SERVICES_URL
    // },
    // {
    //     id: 7,
    //     icon: 'icon-folder',
    //     label: 'Danh mục',
    //     url: LISTINGS_URL
    // },
    {
        id: 8,
        icon: 'icon-creditcard',
        label: 'Thẻ liệu trình',
        url: COMBO_URL
    },
    // {
    //     id: 9,
    //     icon: 'icon-wallet',
    //     label: 'Công nợ',
    //     url: DEBT_URL
    // },
    // {
    //     id: 10,
    //     icon: 'icon-note',
    //     label: 'Quản lý thu chi',
    //     url: REVENUE_URL
    // },
    // {
    //     id: 11,
    //     icon: 'icon-clock',
    //     label: 'Chấm công',
    //     url: CHECKIN_OUT_URL
    // },
    // {
    //     id: 12,
    //     icon: 'icon-graph',
    //     label: 'Phác đồ điều trị',
    //     url: REGIMEN_URL
    // },
    // {
    //     id: 13,
    //     icon: 'icon-pie',
    //     label: 'Báo cáo',
    //     url: REPORT_URL
    // },
    // {
    //     id: 14,
    //     icon: 'icon-global',
    //     label: 'Website landing page',
    //     url: LANDING_PAGE_URL
    // },
    // {
    //     id: 15,
    //     icon: 'icon-diamond',
    //     label: 'Khuyến mãi và Voucher',
    //     url: DISCOUNT_URL
    // },
    // {
    //     id: 16,
    //     icon: 'icon-fly',
    //     label: 'Trải nghiệm khách hàng',
    //     url: CUSTOMER_EXPERIENCE_URL
    // },
    {
        id: 17,
        label: <>
            <span className="label">SPAGO</span>
            <span className="combo">STANDARD</span>
        </>
    },
    // {
    //     id: 18,
    //     icon: 'icon-dashboard',
    //     label: 'Tổng quan',
    //     url: OVERVIEW_URL
    // },
    // {
    //     id: 19,
    //     icon: 'icon-wifi',
    //     label: 'Smart Marketing',
    //     url: SMART_MARKETING_URL
    // },
    // {
    //     id: 20,
    //     icon: 'icon-branch',
    //     label: 'Chi nhánh',
    //     url: BRANCH_URL
    // },
    // {
    //     id: 21,
    //     icon: 'icon-gift',
    //     label: 'Loyalty & Tích điểm',
    //     url: LOYALTY_URL
    // },
    // {
    //     id: 22,
    //     icon: 'icon-gear',
    //     label: 'Cài đặt',
    //     url: SETTINGS_URL
    // }
];

export const fakeBranchOptions = [
    {
        value: 1,
        label: '28 Nguyên Hồng'
    },
    {
        value: 2,
        label: '134 Trương Định'
    }
];

export const fakeStaffOptions = [
    {
        value: 1,
        label: 'Tất cả nhân viên'
    },
    {
        value: 2,
        label: 'Chưa được gán'
    },
    {
        value: 3,
        label: 'Đỗ Hoàng Hải'
    },
    {
        value: 4,
        label: 'Hoàng Ngọc Anh'
    },
    {
        value: 5,
        label: 'Nguyễn Kim Khánh'
    },
    {
        value: 6,
        label: 'Lê Thị Ngân'
    },
    {
        value: 7,
        label: 'Nguyễn Thị Kim Dung'
    },
    {
        value: 8,
        label: 'Lê Thị Lan'
    }
];

export const fakeStatusOptions = [
    {
        value: 'NEW',
        label: 'Mới'
    },
    {
        value: 'CONFIRMED',
        label: 'Đã xác nhận'
    },
    {
        value: 'DOING',
        label: 'Đang làm'
    },
    {
        value: 'FINISHED',
        label: 'Đã hoàn thành'
    },
    {
        value: 'CANCELED',
        label: 'Đã hủy'
    },
];

export const fakeDateTypeOptions = [
    {
        value: 'day',
        label: 'Ngày'
    },
    {
        value: 'three-day',
        label: '3 ngày'
    },
    {
        value: 'week',
        label: '1 tuần'
    }
];

export const repeatEnds = [
    {
        value: 1,
        label: '8 buổi'
    },
    {
        value: 2,
        label: '9 buổi'
    },
    {
        value: 3,
        label: '10 buổi'
    }
];

export const fakeServices = [
    {
        label: "Giảm béo",
        options: [
            {
                value: 1,
                label: 'Giảm béo bụng',
                duration: '1h 30min',
                price: '2.500.000đ'
            },
            {
                value: 2,
                label: 'Giảm béo mặt',
                duration: '1h 30min',
                price: '2.500.000đ'
            }
        ]
    },
    {
        label: "Làm tóc",
        options: [
            {
                value: 3,
                label: 'Cắt tóc nam',
                duration: '1h 30min',
                price: '2.500.000đ'
            },
            {
                value: 4,
                label: 'Cắt tóc nữ',
                duration: '1h 30min',
                price: '2.500.000đ'
            }
        ]
    }
];

export const fakeRanks = [
    {
        id: 'Mới',
        name: 'Hạng mới',
        color: 'blue'
    },
    {
        id: 'Đồng',
        name: 'Hạng đồng',
        color: 'brown'
    },
    {
        id: 'Bạc',
        name: 'Hạng bạc',
        color: 'grey'
    },
    {
        id: 'Vàng',
        name: 'Hạng vàng',
        color: 'gold'
    },
    {
        id: 'Kim cương',
        name: 'Hạng kim cương',
        color: 'diamond',
    },
];

export const clientGroups = [
    {
        value: 0,
        label: 'Tất cả'
    },
    {
        value: 1,
        label: 'Nhóm demo'
    }
];

export const clientSources = [
    {
        value: 0,
        label: 'Tất cả'
    },
    {
        value: 1,
        label: 'Nguồn demo'
    }
];

export const clientJobs = [
    {
        value: 0,
        label: 'Doanh nhân'
    },
    {
        value: 1,
        label: 'Kinh doanh'
    },
    {
        value: 2,
        label: 'Kế toán'
    },
    {
        value: 3,
        label: 'CNTT'
    },
    {
        value: 4,
        label: 'Giáo viên'
    },
    {
        value: 5,
        label: 'Nghệ sĩ'
    },
    {
        value: 6,
        label: 'Kiến trúc sư'
    },
    {
        value: 7,
        label: 'Du lịch'
    },
    {
        value: 8,
        label: 'Phiên dịch'
    },
    {
        value: 9,
        label: 'Marketing'
    },
    {
        value: 10,
        label: 'Nhân viên văn phòng'
    },
    {
        value: 11,
        label: 'Báo chí'
    },
    {
        value: 12,
        label: 'Ngân hàng'
    },
    {
        value: 13,
        label: 'Bất động sản'
    },
    {
        value: 14,
        label: 'Luật sư'
    },
    {
        value: 15,
        label: 'Bác sĩ'
    },
    {
        value: 16,
        label: 'Khác'
    }
];

export const clientCallings = [
    {
        value: 'Chị',
        label: 'Chị',
        gender: FEMALE,
    },
    {
        value: 'Cô',
        label: 'Cô',
        gender: FEMALE,
    },
    {
        value: 'Dì',
        label: 'Dì',
        gender: FEMALE,
    },
    {
        value: 'Anh',
        label: 'Anh',
        gender: MALE,
    },
    {
        value: 'Chú',
        label: 'Chú',
        gender: MALE,
    },
    {
        value: 'Bác',
        label: 'Bác',
        gender: MALE,
    },
    {
        value: 'Em',
        label: 'Em',
        gender: UNKNOWN,
    },
    {
        value: 'Anh/Chị',
        label: 'Anh/Chị',
        gender: UNKNOWN,
    },
    {
        value: 'Khác',
        label: 'Khác',
        gender: UNKNOWN,
    }
];

export const clientGenders = [
    {
        value: FEMALE,
        label: 'Nữ'
    },
    {
        value: MALE,
        label: 'Nam'
    },
    {
        value: UNKNOWN,
        label: 'Không xác định'
    },
];

export const provinces = [
    {
        value: 0,
        label: 'Huyện Điện Biên, Tỉnh Điện Biên'
    },
    {
        value: 1,
        label: 'Huyện Điện Biên Đông, Tỉnh Điện Biên'
    },
    {
        value: 2,
        label: 'Huyện Mường Ảng, Tỉnh Điện Biên'
    }
];

export const fakeClients = [
    {
        value: 1,
        name: 'Nguyễn Tường Vy',
        mobile: '0933767565',
        note: 'Còn nợ 1.500.000đ',
        gender: 'Nữ',
        rank: fakeRanks[1],
        date: '06-04-2021, 11:12',
        branch: fakeBranchOptions[0]
    },
    {
        value: 2,
        name: 'Cao Thị Vân Anh',
        mobile: '0933767565',
        gender: 'Nữ',
        rank: fakeRanks[2],
        date: '06-04-2021, 11:12',
        branch: fakeBranchOptions[0]
    },
    {
        value: 3,
        name: 'Hoàng Thị Thủy',
        mobile: '0933767565',
        note: 'Còn nợ 1.500.000đ',
        gender: 'Nữ',
        rank: fakeRanks[3],
        date: '06-04-2021, 11:12',
        branch: fakeBranchOptions[0]
    },
    {
        value: 4,
        name: 'Lê Thị Thu Hương',
        mobile: '0933767565',
        gender: 'Nữ',
        rank: fakeRanks[4],
        date: '06-04-2021, 11:12',
        branch: fakeBranchOptions[0]
    },
    {
        value: 5,
        name: 'Nguyễn Tường Vy',
        mobile: '0933767565',
        note: 'Còn nợ 1.500.000đ',
        gender: 'Nữ',
        rank: fakeRanks[1],
        date: '06-04-2021, 11:12',
        branch: fakeBranchOptions[0]
    },
    {
        value: 6,
        name: 'Nguyễn Tường Vy',
        mobile: '0933767565',
        note: 'Còn nợ 1.500.000đ',
        gender: 'Nữ',
        rank: fakeRanks[1],
        date: '06-04-2021, 11:12',
        branch: fakeBranchOptions[0]
    }
];

export const appointmentStatuses = [
    {
        value: 'NEW',
        label: 'Mới',
        icon: <i className="spa__status-icon new icon-star" />
    },
    {
        value: 'CONFIRMED',
        label: 'Đã xác nhận',
        icon: <i className="spa__status-icon confirmed icon-like" />
    },
    {
        value: 'DOING',
        label: 'Đang làm',
        icon: <i className="spa__status-icon in-progress icon-chevron-down" />
    },
    {
        value: 'FINISHED',
        label: 'Đã hoàn thành',
        icon: <i className="spa__status-icon done icon-chevron-right" />
    },
    {
        value: 'CANCELED',
        label: 'Đã hủy',
        icon: <i className="spa__status-icon canceled icon-chevron-right" />
    }
];

export const fakeDiscounts = [
    {
        value: 1,
        label: 'No discount'
    }
];

export const fakeProducts = [
    {
        id: 1,
        name: 'Dầu gội Sunsilk',
        oldPrice: '280.000đ',
        price: '230.000đ',
        description: '200/900 sản phẩm đã bán',
        quantity: 1
    },
    {
        id: 2,
        name: 'Dầu gội Eschenter',
        price: '340.000đ',
        description: '10/120 sản phẩm đã bán',
        quantity: 3
    },
    {
        id: 3,
        name: 'Dưỡng ẩm da Skincare',
        price: '1.450.000đ',
        description: '2/20 sản phẩm đã bán',
        quantity: 2
    },
    {
        id: 4,
        name: 'Dưỡng ẩm tóc Matchittsa',
        price: '720.000đ',
        description: '8/56 sản phẩm đã bán',
        quantity: 1
    }
];

export const tipOptions = [
    {
        value: 1,
        label: 'đ'
    },
    {
        value: 2,
        label: '%'
    }
];

export const paymentMethods = [
    {
        value: 'CASH',
        label: 'Tiền mặt'
    },
    {
        value: 'COD',
        label: 'COD'
    },
    {
        value: 'CARD',
        label: 'Thẻ/CK'
    },
    {
        value: 'VOUCHER',
        label: 'Voucher'
    },
    {
        value: 'DEPOSIT',
        label: 'Đặt cọc'
    },
    {
        value: 'OTHER',
        label: 'Khác'
    }
];

export const topUpMethods = [
    {
        value: 'CASH',
        label: 'Tiền mặt'
    },
    {
        value: 'COD',
        label: 'COD'
    },
    {
        value: 'CARD',
        label: 'Chuyển khoản'
    },
    {
        value: 'OTHER',
        label: 'Khác'
    }
];

export const fakeGifts = [
    {
        id: 1,
        point: 100,
        rank: fakeRanks[0],
        name: 'Combo dầu gội & xả snusilk',
        description: 'Combo gồm dầu gội 300ml và dầu xả 500ml chính hãng sunsilk giúp làm mượt tóc, chăm sóc tóc toàn diện. Dành cho tóc yếu, sơ. trẻ ngọn cần phục hồi nhanh'
    },
    {
        id: 1,
        point: 500,
        rank: fakeRanks[1],
        name: 'Combo dầu gội & xả snusilk',
        description: 'Combo gồm dầu gội 300ml và dầu xả 500ml chính hãng sunsilk giúp làm mượt tóc, chăm sóc tóc toàn diện. Dành cho tóc yếu, sơ. trẻ ngọn cần phục hồi nhanh'
    },
    {
        id: 1,
        point: 700,
        rank: fakeRanks[2],
        name: 'Combo dầu gội & xả snusilk',
        description: 'Combo gồm dầu gội 300ml và dầu xả 500ml chính hãng sunsilk giúp làm mượt tóc, chăm sóc tóc toàn diện. Dành cho tóc yếu, sơ. trẻ ngọn cần phục hồi nhanh'
    },
    {
        id: 1,
        point: 1000,
        rank: fakeRanks[3],
        name: 'Combo dầu gội & xả snusilk',
        description: 'Combo gồm dầu gội 300ml và dầu xả 500ml chính hãng sunsilk giúp làm mượt tóc, chăm sóc tóc toàn diện. Dành cho tóc yếu, sơ. trẻ ngọn cần phục hồi nhanh'
    }
];

export const fakeIncome = [
    {
        group: 'Dịch vụ',
        quantity: 2,
        total: '420.000đ'
    },
    {
        group: 'Sản phẩm',
        quantity: 35,
        total: '34.500.000đ'
    },
    {
        group: 'Đặt cọc',
        quantity: 8,
        total: '5.250.000đ'
    },
    {
        group: 'Đào tạo',
        quantity: 0,
        total: '0đ'
    },
    {
        group: 'Khác',
        quantity: 0,
        total: '0đ'
    }
];

export const fakeOutcom = [
    {
        group: 'Tiền mặt',
        quantity: 2,
        total: '10.000.000đ'
    },
    {
        group: 'Lương',
        quantity: 10,
        total: '20.000.000đ'
    },
    {
        group: 'Khác',
        quantity: 0,
        total: '0đ'
    }
];

export const fakeAppointments = [
    {
        id: 'B000039',
        client: {
            id: 'KH00001',
            name: 'Chị Nguyễn Hồng Vân'
        },
        service: 'Giảm béo',
        date: '06-04-2021 11:20',
        duration: '2 giờ 30 phút',
        branch: fakeBranchOptions[0],
        staff: fakeStaffOptions[2],
        total: '500.000đ',
        status: fakeStatusOptions[0]
    },
    {
        id: 'B000040',
        client: {
            id: 'KH00001',
            name: 'Chị Nguyễn Hồng Vân'
        },
        service: 'Giảm béo',
        date: '06-04-2021 11:20',
        duration: '2 giờ 30 phút',
        branch: fakeBranchOptions[0],
        staff: fakeStaffOptions[2],
        total: '500.000đ',
        status: fakeStatusOptions[1]
    },
    {
        id: 'B000041',
        client: {
            id: 'KH00001',
            name: 'Chị Nguyễn Hồng Vân'
        },
        service: 'Giảm béo',
        date: '06-04-2021 11:20',
        duration: '2 giờ 30 phút',
        branch: fakeBranchOptions[0],
        staff: fakeStaffOptions[2],
        total: '500.000đ',
        status: fakeStatusOptions[2]
    },
    {
        id: 'B000042',
        client: {
            id: 'KH00001',
            name: 'Chị Nguyễn Hồng Vân'
        },
        service: 'Giảm béo',
        date: '06-04-2021 11:20',
        duration: '2 giờ 30 phút',
        branch: fakeBranchOptions[0],
        staff: fakeStaffOptions[2],
        total: '500.000đ',
        status: fakeStatusOptions[3]
    },
    {
        id: 'B000043',
        client: {
            id: 'KH00001',
            name: 'Chị Nguyễn Hồng Vân'
        },
        service: 'Giảm béo',
        date: '06-04-2021 11:20',
        duration: '2 giờ 30 phút',
        branch: fakeBranchOptions[0],
        staff: fakeStaffOptions[2],
        total: '500.000đ',
        status: fakeStatusOptions[4]
    },
    {
        id: 'B000044',
        client: {
            id: 'KH00001',
            name: 'Chị Nguyễn Hồng Vân'
        },
        service: 'Giảm béo',
        date: '06-04-2021 11:20',
        duration: '2 giờ 30 phút',
        branch: fakeBranchOptions[0],
        staff: fakeStaffOptions[2],
        total: '500.000đ',
        status: fakeStatusOptions[0]
    }
];

export const payStatusOptions = [
    {
        value: 'DRAFT',
        label: 'Còn nợ'
    },
    {
        value: 'PAYING',
        label: 'Còn nợ'
    },
    {
        value: 'FINISH',
        label: 'Đã thanh toán'
    },
    {
        value: 'NEW',
        label: 'Chưa thanh toán'
    },
    {
        value: 'FINISHED',
        label: 'Đã thanh toán'
    }
]

export const fakeInvoices = [
    {
        id: 'O000039',
        client: {
            id: 'KH00001',
            name: 'Chị Nguyễn Hồng Vân'
        },
        date: '06-04-2021 11:20',
        branch: fakeBranchOptions[0],
        tips: '100.000đ',
        total: '500.000đ',
        status: payStatusOptions[0]
    },
    {
        id: 'O000040',
        client: {
            id: 'KH00001',
            name: 'Chị Nguyễn Hồng Vân'
        },
        date: '06-04-2021 11:20',
        branch: fakeBranchOptions[0],
        tips: '0đ',
        total: '500.000đ',
        status: payStatusOptions[0]
    },
    {
        id: 'O000041',
        client: {
            id: 'KH00001',
            name: 'Chị Nguyễn Hồng Vân'
        },
        date: '06-04-2021 11:20',
        branch: fakeBranchOptions[0],
        tips: '0đ',
        total: '500.000đ',
        status: payStatusOptions[0]
    },
    {
        id: 'O000039',
        client: {
            id: 'KH00001',
            name: 'Chị Nguyễn Hồng Vân'
        },
        date: '06-04-2021 11:20',
        branch: fakeBranchOptions[0],
        tips: '150.000đ',
        total: '500.000đ',
        status: payStatusOptions[1]
    },
    {
        id: 'O000042',
        client: {
            id: 'KH00001',
            name: 'Chị Nguyễn Hồng Vân'
        },
        date: '06-04-2021 11:20',
        branch: fakeBranchOptions[0],
        tips: '0đ',
        total: '500.000đ',
        status: payStatusOptions[1]
    },
    {
        id: 'O000043',
        client: {
            id: 'KH00001',
            name: 'Chị Nguyễn Hồng Vân'
        },
        date: '06-04-2021 11:20',
        branch: fakeBranchOptions[0],
        tips: '0đ',
        total: '500.000đ',
        status: payStatusOptions[0]
    }
];

export const fakeTopups = [
    {
        id: 1,
        date: '12:30 22/04/2021',
        name: 'Nạp tiền vào ví',
        amount: '+15.000.000đ'
    },
    {
        id: 2,
        date: '17:39 18/04/2021',
        name: 'Nạp tiền vào ví',
        amount: '+2.500.000đ'
    },
    {
        id: 3,
        date: '12:30 14/04/2021',
        name: 'Nạp tiền vào ví',
        amount: '+3.000.000đ'
    },
    {
        id: 4,
        date: '17:39 12/04/2021',
        name: 'Nạp tiền vào ví',
        amount: '+5.000.000đ'
    }
];

export const fakeCombos = [
    {
        id: 'T00001',
        service: 'Phun mí',
        client: fakeClients[0],
        price: '4.000.000đ',
        totalQuantity: 10,
        doneQuantity: 1,
        frequency: '3 ngày/buổi'
    },
    {
        id: 'T00002',
        service: 'Giảm béo bụng',
        client: fakeClients[1],
        price: '15.500.000đ',
        totalQuantity: 6,
        doneQuantity: 2,
        frequency: '2 ngày/buổi'
    },
    {
        id: 'T00003',
        service: 'Trị nám vùng mặt',
        client: fakeClients[0],
        price: '26.000.000đ',
        totalQuantity: 12,
        doneQuantity: 10,
        frequency: 'Hàng ngày'
    },
    {
        id: 'T00004',
        service: 'Tẩy da chết vùng mặt',
        client: fakeClients[1],
        price: '8.200.000đ',
        totalQuantity: 10,
        doneQuantity: 0,
        frequency: '2 ngày/buổi'
    },
    {
        id: 'T00005',
        service: 'Tẩy da chết toàn thân',
        client: fakeClients[0],
        price: '4.000.000đ',
        totalQuantity: 7,
        doneQuantity: 0,
        frequency: 'Hằng tháng'
    },
    {
        id: 'T00006',
        service: 'Dưỡng tóc',
        client: fakeClients[2],
        price: '2.500.000đ',
        totalQuantity: 20,
        doneQuantity: 0,
        frequency: 'Hằng ngày'
    },
    { 
        id: 'T00007',
        service: 'Hấp tóc',
        client: fakeClients[0],
        price: '2.000.000đ',
        totalQuantity: 4,
        doneQuantity: 0,
        frequency: '4 ngày/buổi'
    },
    {
        id: 'T00008',
        service: 'Xăm môi nghệ thuật',
        client: fakeClients[1],
        price: '5.000.000đ',
        totalQuantity: 3,
        doneQuantity: 0,
        frequency: '2 ngày/buổi'
    },
    {
        id: 'T00009',
        service: 'Trị tàn nhang',
        client: fakeClients[0],
        price: '11.000.000đ',
        totalQuantity: 10,
        doneQuantity: 0,
        frequency: 'Hằng tuần'
    }
];

export const comboQuantities = Array(100).fill(0).map((i, index) => {
    return {
        value: index + 1,
        label: `${index + 1} buổi`,
    }
});

export const fakeCallHistories = [
    {
        id: 1,
        name: 'Thanh Hằng - Hỗ trợ viên',
        avatar,
        mobile: '0963456654',
        date: '20/04/2021',
        time: '09:39',
        duration: '2p35s',
        price: '1.421đ'
    },
    {
        id: 2,
        name: 'Thanh Hằng - Hỗ trợ viên',
        avatar,
        mobile: '0963456654',
        date: '18/04/2021',
        time: '11:20',
        duration: '0p31s',
        price: '285đ'
    },
    {
        id: 3,
        name: 'Thanh Hằng - Hỗ trợ viên',
        avatar,
        mobile: '0963456654',
        date: '12/04/2021',
        time: '15:34',
        duration: '6p22s',
        price: '0đ'
    }
];

const getToday = moment().format('YYYY-MM-DD');
const getTomorrow = moment().add(1, 'd').format('YYYY-MM-DD');
export const fakeStaffsSchedules = [
    {
        id: 1,
        name: 'Chưa được gán',
        color: '#FFFFFF',
        appointments: [
            {
                id: 11,
                start: `${getToday} 11:00`,
                end: `${getToday} 13:00`,
                client: fakeClients[0],
                service: {
                    id: 1,
                    name: 'Massage mặt'
                },
                status: fakeStatusOptions[0],
            },
            {
                id: 21,
                start: `${getToday} 06:00`,
                end: `${getToday} 12:00`,
                client: fakeClients[0],
                service: {
                    id: 1,
                    name: 'Massage mặt 2'
                },
                status: fakeStatusOptions[0],
            },
            {
                id: 31,
                start: `${getToday} 09:00`,
                end: `${getToday} 16:00`,
                client: fakeClients[0],
                service: {
                    id: 1,
                    name: 'Massage mặt 3'
                },
                status: fakeStatusOptions[0],
            },
            {
                id: 41,
                start: `${getToday} 10:00`,
                end: `${getToday} 10:30`,
                client: fakeClients[0],
                service: {
                    id: 1,
                    name: 'Massage mặt 22222'
                },
                status: fakeStatusOptions[0],
            },
            {
                id: 51,
                start: `${getTomorrow} 06:00`,
                end: `${getTomorrow} 12:30`,
                client: fakeClients[1],
                service: {
                    id: 1,
                    name: 'Massage mặt'
                },
                status: fakeStatusOptions[0],
            }
        ]
    },
    {
        id: 2,
        name: 'Đỗ Hoàng Hải',
        color: '#D06969',
        appointments: [
            {
                id: 12,
                start: `${getToday} 09:00`,
                end: `${getToday} 11:30`,
                client: fakeClients[2],
                service: {
                    id: 2,
                    name: 'Massage mặt'
                },
                status: fakeStatusOptions[1],
            },
            {
                id: 22,
                start: `${getTomorrow} 13:00`,
                end: `${getTomorrow} 17:30`,
                client: fakeClients[3],
                service: {
                    id: 2,
                    name: 'Massage mặt'
                },
                status: fakeStatusOptions[1],
            }
        ]
    },
    {
        id: 3,
        name: 'Hoàng Ngọc Anh',
        color: '#C8D069',
        appointments: [
            {
                id: 13,
                start: `${getToday} 10:00`,
                end: `${getToday} 13:30`,
                client: fakeClients[0],
                service: {
                    id: 3,
                    name: 'Trị nám'
                },
                status: fakeStatusOptions[2],
            },
            {
                id: 23,
                start: `${getToday} 14:00`,
                end: `${getToday} 14:30`,
                client: fakeClients[3],
                service: {
                    id: 4,
                    name: 'Trị nạm toàn thân'
                },
                status: fakeStatusOptions[1],
            }
        ]
    },
    {
        id: 4,
        name: 'Nguyễn Kim Khánh',
        color: '#6ED069',
        appointments: [
            {
                id: 15,
                start: `${getToday} 18:00`,
                end: `${getToday} 20:30`,
                client: fakeClients[1],
                service: {
                    id: 5,
                    name: 'Phun môi tạo kiểu'
                },
                status: fakeStatusOptions[3],
            },
            {
                id: 25,
                start: `${getTomorrow} 11:00`,
                end: `${getTomorrow} 16:00`,
                client: fakeClients[2],
                service: {
                    id: 5,
                    name: 'Phun môi tạo kiểu'
                },
                status: fakeStatusOptions[3],
            }
        ]
    },
    {
        id: 5,
        name: 'Lê Thị Ngân',
        color: '#69CAD0',
        appointments: [
            {
                id: 16,
                start: `${getToday} 10:00`,
                end: `${getToday} 16:30`,
                client: fakeClients[0],
                service: {
                    id: 6,
                    name: 'Trẻ hóa da'
                },
                status: fakeStatusOptions[4],
            }
        ]
    },
    {
        id: 6,
        name: 'Nguyễn Thị Kim Dung',
        color: '#6976D0',
        appointments: [
            {
                id: 17,
                start: `${getToday} 03:30`,
                end: `${getToday} 19:00`,
                client: fakeClients[3],
                service: {
                    id: 7,
                    name: 'Trị nám, tàn nhang'
                },
                status: fakeStatusOptions[1],
            }
        ]
    },
    {
        id: 7,
        name: 'Lê Thị Lan',
        color: '#D069C6',
        appointments: [
            {
                id: 18,
                start: `${getToday} 22:30`,
                end: `${getTomorrow} 02:30`,
                client: fakeClients[1],
                service: {
                    id: 8,
                    name: 'Đắp mặt thảo mộc'
                },
                status: fakeStatusOptions[0],
            },
            {
                id: 28,
                start: `${getToday} 10:30`,
                end: `${getToday} 18:15`,
                client: fakeClients[2],
                service: {
                    id: 8,
                    name: 'Đắp mặt thảo mộc'
                },
                status: fakeStatusOptions[0],
            }
        ]
    }
];

export const fakeAddressList = address_code.map((ac, acIndex) => {
    return {
        value: ac,
        label: address_data_full[acIndex]
    }
});

export const notificationTitle = [
    {
        id: 'New appointment',
        title: 'Lịch hẹn mới',
        icon: 'icon-calendar'
    },
    {
        id: 'Daily reports',
        title: 'Báo cáo hằng ngày',
        icon: 'icon-bell'
    },
    {
        id: 'Bad feedback',
        title: 'Phản hồi xấu',
        icon: 'icon-bad'
    },
    {
        id: 'Password changed',
        title: 'Thay đổi mật khẩu',
        icon: 'icon-user'
    },
    {
        id: 'Appointment status changed',
        title: 'Trạng thái lịch hẹn',
        icon: 'icon-calendar'
    },
    {
        id: 'Receipt',
        title: 'Phiếu thu',
        icon: 'icon-invoice'
    },
    {
        id: 'Upgrade tenant',
        title: 'Nâng cấp spa',
        icon: 'icon-branch'
    },
    {
        id: 'Updated password',
        title: 'Thay đổi mật khẩu',
        icon: 'icon-user'
    },
    {
        id: 'Downgrade tenant warning',
        title: 'Cảnh báo hạ cấp spa',
        icon: 'icon-branch'
    },
    {
        id: 'Appointment incoming',
        title: 'Lịch hẹn sắp tới',
        icon: 'icon-calendar'
    },
    {
        id: 'Daily incoming appointment',
        title: 'Lịch hẹn sắp tới',
        icon: 'icon-calendar'
    },
    {
        id: 'Updated Appointment Allowed',
        title: 'Cho phép lịch hẹn',
        icon: 'icon-calendar'
    },
    {
        id: 'User block status changed',
        title: 'Chặn nhân viên',
        icon: 'icon-user'
    },
    {
        id: 'User role changed',
        title: 'Chức vụ nhân viên',
        icon: 'icon-user'
    },
    {
        id: 'Update appointment',
        title: 'Cập nhật lịch hẹn',
        icon: 'icon-calendar'
    },
    {
        id: 'Delete appointment',
        title: 'Xoá lịch hẹn',
        icon: 'icon-calendar'
    },
    {
        id: 'Invoice',
        title: 'Hoá đơn',
        icon: 'icon-invoice'
    },
    {
        id: 'Assigned customer item',
        title: 'Gán thẻ liệu trình',
        icon: 'icon-invoice'
    },
    {
        id: 'Appointment assigned',
        title: 'Gán lịch hẹn',
        icon: 'icon-calendar'
    },
    {
        id: 'CMS Notification',
        title: 'Thông báo hệ thống',
        icon: 'icon-gear'
    },
    {
        id: 'Minimum Inventory',
        title: 'Thông báo kho',
        icon: 'icon-dashboard'
    },
    {
        id: 'Checkin success',
        title: 'Chấm công',
        icon: 'icon-user'
    },
    {
        id: 'Checkin reminding',
        title: 'Nhắc chấm công',
        icon: 'icon-user'
    },
    {
        id: 'User shift changed',
        title: 'Ca làm việc',
        icon: 'icon-user'
    },
    {
        id: 'Shift update',
        title: 'Ca làm việc',
        icon: 'icon-user'
    },
    {
        id: 'Tenant subscription reminding',
        title: 'Nhắc đăng ký spa',
        icon: 'icon-branch'
    },
    {
        id: 'Tenant subscription success',
        title: 'Đăng ký spa',
        icon: 'icon-branch'
    },
    {
        id: 'Balance reminding',
        title: 'Số dư ví',
        icon: 'icon-wallet'
    },
];