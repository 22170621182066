import axios from 'axios';
import {
 authHeaders, GET_SHIFTS,
} from 'actions';

/** Get shifts
 * @param {
 *      page: int
 *      page_size: int
 * } payload
 */
export const getShifts = (params, token) => axios.get(
    GET_SHIFTS,
    params
        ? {
            params,
            ...authHeaders(token),
        }
        : authHeaders(token),
);
