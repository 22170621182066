import axios from 'axios';
import {
 authHeaders, GET_TENANT,
} from 'actions';

/** Get tenant
 * @route {
 *      tenantId: uuid
 * }
 */
export const getTenant = (tenantId, token) => axios.get(GET_TENANT.replace(':tenantId', tenantId), authHeaders(token));
