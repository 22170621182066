import { createContext, useContext, useState } from 'react';
import { getPrinters } from 'actions';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';

export const PrinterContext = createContext({} as any);

export const PrinterContextProvider = ({ children }) => {
    const { setPrinterLoading, handleException } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [printers, setPrinters] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPrinters, setTotalPrinters] = useState(0);

    const doGetPrinters = async (params, callback) => {
        try {
            setPrinterLoading(true);
            const response = await getPrinters(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const printerData = data.results || [];
                setPrinters(printerData);
                setCurrentPage(params?.page || 1);
                setTotalPrinters(data.count);
                if (callback) callback();
            }

            setPrinterLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <PrinterContext.Provider
            value={{
                printers,
                currentPage,
                totalPrinters,
                doGetPrinters,
            }}
        >
            {children}
        </PrinterContext.Provider>
    );
};
