import { createContext, useContext, useState } from 'react';
import { getCustomerTreatments, getCustomerTreatment } from 'actions';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';

export const CustomerTreatmentContext = createContext({} as any);

export const CustomerTreatmentContextProvider = ({ children }) => {
    const { setCustomerTreatmentLoading, handleException } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [customerTreatments, setCustomerTreatments] = useState<any[]>([]);
    const [customerTreatment, setCustomerTreatment] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCustomerTreatments, setTotalCustomerTreatments] = useState(0);

    const doGetCustomerTreatments = async (params, callback) => {
        try {
            setCustomerTreatmentLoading(true);

            const response = await getCustomerTreatments(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const notificationData = data.results || [];
                setCustomerTreatments(notificationData);
                setCurrentPage(params?.page || 1);
                setTotalCustomerTreatments(data.count);
                if (callback) callback();
            }

            setCustomerTreatmentLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doGetCustomerTreatment = async (customerTreatmentId, callback) => {
        try {
            setCustomerTreatmentLoading(true);

            const response = await getCustomerTreatment(customerTreatmentId, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                setCustomerTreatment(data);
                if (callback) callback();
            }

            setCustomerTreatmentLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <CustomerTreatmentContext.Provider
            value={{
                customerTreatments,
                customerTreatment,
                currentPage,
                totalCustomerTreatments,
                setCustomerTreatment,
                doGetCustomerTreatments,
                doGetCustomerTreatment,
            }}
        >
            {children}
        </CustomerTreatmentContext.Provider>
    );
};
