import { Form, TimePicker as AntTimePicker } from 'antd';
import './index.scss';

export const TimePicker = ({
	className = '', name, label = '', rules = [] as any, ...rest
}) => (
	<Form.Item className={`spa__time-picker ${className}`} name={name} rules={rules} label={label}>
		<AntTimePicker {...rest} />
	</Form.Item>
);
