import { createContext, useContext, useState } from 'react';
import { getCustomerGroups } from 'actions';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';

export const CustomerGroupContext = createContext({} as any);

export const CustomerGroupContextProvider = ({ children }) => {
    const { setLoading, handleException } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [customerGroups, setCustomerGroups] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCustomerGroups, setTotalCustomerGroups] = useState(0);

    const doGetCustomerGroups = async (params, callback) => {
        try {
            setLoading(true);

            const response = await getCustomerGroups(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const notificationData = data.results || [];
                setCustomerGroups(notificationData);
                setCurrentPage(params?.page || 1);
                setTotalCustomerGroups(data.count);
                if (callback) callback();
            }

            setLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <CustomerGroupContext.Provider
            value={{
                customerGroups,
                currentPage,
                totalCustomerGroups,
                doGetCustomerGroups,
            }}
        >
            {children}
        </CustomerGroupContext.Provider>
    );
};
