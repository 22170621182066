import axios from 'axios';
import { authHeaders, GET_CAREERS } from 'actions';

/** Get careers
 * @param {
 *      page: int
 *      page_size: int
 * } payload
 */
export const getCareers = (token) => axios.get(
    GET_CAREERS,
    authHeaders(token),
);
