import axios from 'axios';
import { authHeaders, GET_SEARCH_ALL } from 'actions';

/** Get ranks
 * @param {
 *      search: string
 * } payload
 */
export const getSearchResults = (params, token) => axios.get(
        GET_SEARCH_ALL,
        params
            ? {
                  params,
                  ...authHeaders(token),
              }
            : authHeaders(token),
    );
