import { Scrollbars } from 'react-custom-scrollbars';

import './index.scss';

export const ScrollbarsIf = ({
 enable = true, autoHide = true, mode = 'dark', children,
}) => (enable ? (
        <Scrollbars
            autoHide={autoHide}
            className="spa__scrollbars"
            renderThumbVertical={(props) => <div {...props} className={`thumb-vertical ${mode}`} />}
            renderTrackHorizontal={(props) => <div {...props} className={`thumb-horizontal ${mode}`} />}
        >
            {children}
        </Scrollbars>
    ) : (
        children
    ));
