import { createContext, useContext, useState } from 'react';
import { addFeedback, getFeedbacks, updateFeedback } from 'actions';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';

export const FeedbackContext = createContext({} as any);

export const FeedbackContextProvider = ({ children }) => {
    const { setFeedbackLoading, handleException } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [feedbacks, setFeedbacks] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalFeedbacks, setTotalFeedbacks] = useState(0);

    const doGetFeedbacks = async (params, callback) => {
        try {
            setFeedbackLoading(true);

            const response = await getFeedbacks(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const mediaData = data.results || [];
                setFeedbacks(mediaData);
                setCurrentPage(params?.page || 1);
                setTotalFeedbacks(data.count);
                if (callback) callback();
            }

            setFeedbackLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doAddFeedback = async (payload, callback) => {
        try {
            setFeedbackLoading(true);

            const response = await addFeedback(payload, loggedInUser);
            if (response && response.status === 201) {
                if (callback) callback();
            }

            setFeedbackLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doUpdateFeedback = async (feedbackId, payload, callback) => {
        try {
            setFeedbackLoading(true);

            const response = await updateFeedback(feedbackId, payload, loggedInUser);
            if (response && response.status === 200) {
                if (callback) callback();
            }

            setFeedbackLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <FeedbackContext.Provider
            value={{
                feedbacks,
                currentPage,
                totalFeedbacks,
                setFeedbacks,
                doAddFeedback,
                doGetFeedbacks,
                doUpdateFeedback,
            }}
        >
            {children}
        </FeedbackContext.Provider>
    );
};
