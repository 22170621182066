import { createContext, useContext, useState } from 'react';
import { getCareers } from 'actions';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';

export const CareerContext = createContext({} as any);

export const CareerContextProvider = ({ children }) => {
    const { setLoading, handleException } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [careers, setCareers] = useState<any[]>([]);

    const doGetCareers = async (callback) => {
        try {
            setLoading(true);

            const response = await getCareers(loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                setCareers(data || []);
                if (callback) callback();
            }

            setLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <CareerContext.Provider
            value={{
                careers,
                doGetCareers,
            }}
        >
            {children}
        </CareerContext.Provider>
    );
};
