import {
 useContext, useEffect, useRef, useState,
} from 'react';
import { Popover } from 'antd';
import moment from 'moment';

import './index.scss';
import { ScrollbarsIf, Button } from 'components';
import {
    convertTimeBack,
 enumerateDaysBetweenDates, formatCurrency, getFirstLetter, getTimes,
} from 'utils';
import {
 JQUERY_URL, JQUERY_UI_URL, JQUERY_TOUCH_PUNCH_URL, SELECTED_CELL, ADD_APPOINTMENT_URL, SHORT_DATE_FORMAT, VIEW_APPOINTMENT_URL, SELECTED_CLIENT,
} from 'actions';
import { useHistory } from 'react-router';
import { AppointmentContext } from 'contexts';

export enum CalendarMode {
    OneDay = 'day',
    ThreeDay = 'three-day',
    OneWeek = 'week',
}
interface IAppointmentCalendarProp {
    mode: CalendarMode;
    step: number;
    block: number;
    startDate: Date;
    schedules: any[];
    workingHour: number[];
    workingDays: number[];
    maxDuration: number;
    filters: any;
    onUpdated: any;
    paginable: boolean;
    pagination: any;
    onPagination: any;
}

export const AppointmentCalendar = ({
 mode, step, block, startDate, schedules, workingHour, workingDays, maxDuration, filters, onUpdated, paginable, pagination, onPagination,
}: IAppointmentCalendarProp) => {
    const history = useHistory();
    const {
        appointments,
        doUpdateAppointment,
    } = useContext(AppointmentContext);
    const [timesLabel, setTimesLabel] = useState<string[]>([]);
    const [selectedDates, setSelectedDates] = useState<Date[]>([]);
    const [currentTime, setCurrentTime] = useState<Date>(new Date());
    const [currentTimePosition, setCurrentTimePosition] = useState<number>(0);
    const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);
    const [appts, setAppointments] = useState<any[]>(schedules);
    const [isDrag, setIsDrag] = useState(false);
    const [isStop, setIsStop] = useState(false);
    const [updateAppointment, setUpdateAppointment] = useState<any>();
    const [scrolled, setScrolled] = useState(false);
    const currentTimeRef = useRef<any>(null);
    const blockHeight = 30;
    let currentTimeInterval;
    let currentTimeTimeout;
    let scriptInterval;
    let JQuery;

    const executeScroll = () => {
        if (currentTimeRef) {
            currentTimeRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };
    const getCurrentTimePosition = () => {
        if (!timesLabel.length || !currentTime) return 0;
        const currentTimeMoment = moment(currentTime);
        const minutes = currentTimeMoment.minutes();
        const hours = currentTimeMoment.hours();
        return (minutes + hours * 60) * (blockHeight / block) - 11;
    };

    /*eslint-disable */
    useEffect(() => {
        setTimesLabel(getTimes(block));
        const today = new Date();
        const currentSeconds = today.getSeconds();
        setCurrentTime(new Date());
        currentTimeTimeout = setTimeout(() => {
            currentTimeInterval = setInterval(() => {
                setCurrentTime(new Date());
            }, 60000);
        }, (60 - currentSeconds) * 1000);

        const scripts = document.scripts;
        let count = 0;

        for (let i = 0; i < scripts.length; i++) {
            if (scripts[i].src.indexOf(JQUERY_URL) > -1 || scripts[i].src.indexOf(JQUERY_UI_URL) > -1 || scripts[i].src.indexOf(JQUERY_TOUCH_PUNCH_URL) > -1) {
                count++;
            }
        }
        scriptInterval = setInterval(() => {
            if (count === 3) {
                setScriptLoaded(true);
                clearInterval(scriptInterval);
            }
        }, 200);
        return () => {
            setScrolled(false);
            if (currentTimeInterval) clearInterval(currentTimeInterval);
            if (currentTimeTimeout) clearTimeout(currentTimeTimeout);
            if (scriptInterval) clearInterval(scriptInterval);
        };
    }, []);

    useEffect(() => {
        if (mode === CalendarMode.OneDay) setSelectedDates([startDate]);
        else {
            let endDate = moment(startDate)
                .add(mode === CalendarMode.ThreeDay ? 3 : 7, 'd')
                .toDate();
            setSelectedDates(enumerateDaysBetweenDates(startDate, endDate));
        }
    }, [startDate]);

    useEffect(() => {
        setCurrentTimePosition(getCurrentTimePosition());
    }, [currentTime]);

    useEffect(() => {
        setAppointments(filters?.user ? schedules.filter((s) => s.id === filters?.user) : schedules);
    }, [schedules, filters]);

    useEffect(() => {
        if (scriptLoaded && window && window['$'] && !JQuery) {
            if (!scrolled) {
                executeScroll();
                setScrolled(true);
            }
            JQuery = window['$'];
        }
        if ((mode) && !!appts.length) {
            setTimeout(() => {
                initDraggableItems();
            }, 1000);
        }
    }, [mode, scriptLoaded, JQuery, window, appts]);

    useEffect(() => {
        if (isDrag && isStop) {
            setIsDrag(false);
            if (updateAppointment) {
                const { duration, oldIndex, newIndex, appointmentId, startTime, itemId } = updateAppointment;
                const appointment = appointments.find((a) => a.id === appointmentId);
                const oldCol = mode === CalendarMode.OneDay ? schedules[oldIndex].id : selectedDates[oldIndex];
                const newCol = mode === CalendarMode.OneDay ? schedules[newIndex].id : selectedDates[newIndex];

                if (appointment) doUpdateAppointment(appointmentId, {
                    branch: appointment?.branch,
                    appointment_date: mode !== CalendarMode.OneDay ? moment(newCol).format(SHORT_DATE_FORMAT) : appointment?.appointment_date,
                    appointment_items: [
                        ...appointment.appointment_items.map((a) => {
                            const {
                                seller, note, quantity,
                            } = a;
                            const newDuration = itemId === a.id ? duration : a.duration;
                            const newStartTime = itemId === a.id ? startTime : a.start_time;
                            const newMainStaffs = (a.main_staffs.some((as) => as.id === oldCol) || !oldCol)
                                ? (newCol
                                        ? [...a.main_staffs.filter((as) => as.id !== oldCol).map((as) => as.id), newCol]
                                        : a.main_staffs.filter((as) => as.id !== oldCol).map((as) => as.id)
                                    )
                                : a.main_staffs.map((as) => as.id);
                            const newAssistantStaffs = ((a.assistant_staffs.some((as) => as.id === oldCol) || !oldCol) && !newMainStaffs.some((ns) => ns === newCol))
                                ? (newCol
                                    ? [...a.assistant_staffs.filter((as) => as.id !== oldCol).map((as) => as.id), newCol]
                                    : a.assistant_staffs.filter((as) => as.id !== oldCol).map((as) => as.id))
                                : a.assistant_staffs.map((as) => as.id);
                            return {
                                id: a.id,
                                product_id: a.id,
                                main_staffs: newMainStaffs,
                                assistant_staffs: newAssistantStaffs,
                                seller,
                                product: a.product_id,
                                note: note || '',
                                quantity,
                                start_time: newStartTime,
                                duration: newDuration,
                                actual_price: a.actual_price,
                            };
                        })
                    ],
                }, () => {
                    if (onUpdated) onUpdated();
                })
            }
        }
        if (isStop) setIsStop(false);
    }, [isDrag, isStop]);
    /* eslint-enable */

    const initDraggableItems = () => {
        if (JQuery && JQuery.fn && JQuery.fn.draggable) {
            console.log('loaded');
            const columnWidth = JQuery('.spa__calendar--day').outerWidth();
            const items = JQuery('.spa__calendar--item');
            let prevDragValue = {
                top: 0,
                left: 0,
            };
            const dragTimeElement = JQuery('.current-drag-time');
            items
                .draggable({
                    containment: '.spa__calendar--days',
                    stack: '.spa__calendar--item',
                    grid: [columnWidth, step * (blockHeight / block)],
                    scroll: true,
                    distance: 3,
                    start: (event, ui) => {
                        // Start
                        console.log(event, ui);
                    },
                    drag(event, ui) {
                        // Dragging
                        console.log(event, ui);
                        const current = JQuery(this);
                        const offset = ui?.position;
                        const timeLabel = current.find('span.f-regular');
                        const timeDuration = parseInt(current.attr('data-duration'), 10);
                        if (offset && (prevDragValue.top !== offset.top || prevDragValue.left !== offset.left)) {
                            prevDragValue = offset;
                            const noOfMinutes = offset.top / (blockHeight / block);
                            const hour = Math.floor(noOfMinutes / 60);
                            const minutes = noOfMinutes % 60;
                            const currentDragTime = `${`0${hour}`.slice(-2)}:${`0${minutes}`.slice(-2)}`;
                            const endNoOfMinutes = noOfMinutes + timeDuration;
                            const endHour = Math.floor(endNoOfMinutes / 60);
                            const endMinutes = endNoOfMinutes % 60;
                            const endDragTime = `${`0${endHour}`.slice(-2)}:${`0${endMinutes}`.slice(-2)}`;
                            if (dragTimeElement.hasClass('d-none')) dragTimeElement.removeClass('d-none');
                            dragTimeElement.css({ top: `${offset.top}px` }).html(currentDragTime);
                            if (timeLabel) timeLabel.html(`${currentDragTime}-${endDragTime}`);
                        }
                        if (!isDrag) setIsDrag(true);
                    },
                    stop: (event, ui) => {
                        // Stop
                        console.log(event, ui);
                        const current = JQuery(event.target);
                        const cWidth = JQuery('.spa__calendar--item').outerWidth();
                        if (current) {
                            const timeLabel = current.find('span.f-regular');
                            const timeLabelParts = timeLabel.text().split('-');
                            if (timeLabelParts.length === 2) {
                                const left = ui?.position?.left;
                                const oldIndex = parseInt(current.attr('data-parent-index'), 10);
                                const duration = parseInt(current.attr('data-duration'), 10);
                                const appointmentId = current.attr('data-id');
                                const itemId = current.attr('data-child-id');
                                const newIndex = Math.round(left / cWidth) + parseInt(current.attr('data-parent-index'), 10);
                                setUpdateAppointment((old) => ({
                                    ...old,
                                    oldIndex,
                                    newIndex,
                                    startTime: timeLabelParts[0],
                                    duration,
                                    appointmentId,
                                    itemId,
                                }));
                            }
                        }
                        setIsStop(true);
                        dragTimeElement.addClass('d-none');
                    },
                })
                .resizable({
                    handles: 's',
                    grid: step * (blockHeight / block),
                    maxHeight: maxDuration * (blockHeight / block),
                    start: (event, ui) => {
                        // Start
                        console.log(event, ui);
                    },
                    resize: (event, ui) => {
                        // Resizing
                        console.log(event, ui);
                        const current = ui?.element;
                        const offset = ui?.position;
                        const height = ui?.size?.height;
                        const timeLabel = current.find('span.f-regular');
                        const timeDuration = height / (blockHeight / block);
                        current.attr('data-duration', timeDuration);
                        const noOfMinutes = offset.top / (blockHeight / block);
                        const hour = Math.floor(noOfMinutes / 60);
                        const minutes = noOfMinutes % 60;
                        const currentDragTime = `${`0${hour}`.slice(-2)}:${`0${minutes}`.slice(-2)}`;
                        const endNoOfMinutes = noOfMinutes + timeDuration;
                        const endHour = Math.floor(endNoOfMinutes / 60);
                        const endMinutes = endNoOfMinutes % 60;
                        const endDragTime = `${`0${endHour}`.slice(-2)}:${`0${endMinutes}`.slice(-2)}`;
                        if (timeLabel) timeLabel.html(`${currentDragTime}-${endDragTime}`);

                        if (!isDrag) setIsDrag(true);
                    },
                    stop: (event, ui) => {
                        // Stop
                        console.log(event, ui);
                        const current = JQuery(event.target);
                        const cWidth = JQuery('.spa__calendar--item').outerWidth();
                        if (current) {
                            const timeLabel = current.find('span.f-regular');
                            const timeLabelParts = timeLabel.text().split('-');
                            if (timeLabelParts.length === 2) {
                                const left = ui?.position?.left;
                                const oldIndex = parseInt(current.attr('data-parent-index'), 10);
                                const duration = parseInt(current.attr('data-duration'), 10);
                                const appointmentId = current.attr('data-id');
                                const itemId = current.attr('data-child-id');
                                const newIndex = Math.round(left / cWidth) + parseInt(current.attr('data-parent-index'), 10);
                                setUpdateAppointment((old) => ({
                                    ...old,
                                    oldIndex,
                                    newIndex,
                                    startTime: timeLabelParts[0],
                                    duration,
                                    appointmentId,
                                    itemId,
                                }));
                            }
                        }
                        setIsStop(true);
                    },
                });
        }
    };

    const getStatusIcon = (item) => {
        let icon = '';
        switch (item.status.value) {
            case 'NEW':
                icon = 'icon-star';
                break;
            case 'CONFIRMED':
                icon = 'icon-like';
                break;
            case 'DOING':
                icon = 'icon-chevron-down';
                break;
            case 'FINISHED':
                icon = 'icon-chevron-right';
                break;
            case 'CANCELED':
                icon = 'icon-chevron-right';
                break;
            default:
                break;
        }
        return icon;
    };

    const getDateAppointments = (date: Date): any[] => {
        const selectedDateAppointments = [] as any;
        appts.forEach((a) => {
            const { color, id, name } = a;
            a.appointments.forEach((sa) => {
                if (moment(sa.start).isSame(date, 'd')) {
                    selectedDateAppointments.push({
                        ...sa,
                        staff: {
                            id,
                            name,
                            avatar_url: a.avatar_url,
                        },
                        color,
                    });
                } else if (moment(sa.end).isSame(date, 'd')) {
                    const newStartDate = moment(date).hour(0).minute(0).toDate();
                    selectedDateAppointments.push({
                        ...sa,
                        staff: {
                            id,
                            name,
                            avatar_url: a.avatar_url,
                        },
                        color,
                        newStart: newStartDate,
                    });
                }
            });
        });
        return selectedDateAppointments;
    };
    const handleAddAppointment = (data) => {
        localStorage.setItem(SELECTED_CELL, JSON.stringify(data));
        localStorage.removeItem(SELECTED_CLIENT);
        history.push(`${ADD_APPOINTMENT_URL}?${new URLSearchParams(data).toString()}`);
    };
    return (
        <div className={`spa__calendar ${mode}`}>
            <div className="d-flex spa__calendar--label">
                {mode === CalendarMode.OneDay ? (
                    <>
                        {appts.map((s) => (
                            <div
                                className="spa__calendar--day"
                                key={s.id}
                                style={{ width: `calc(100% / ${appts.length < 7 ? appts.length : 7})` }}
                            >
                                <div className={`spa__calendar--day--label name ${s.id === 1 ? 'blank' : ''}`}>
                                    <div className="avatar mb-2" style={{ backgroundColor: s.color }}>
                                        {s.avatar_url ? (
                                            <img src={s.avatar_url} alt={s.name} />
                                        ) : (
                                            <h1 className="h1 m-0">
                                                {s.id ? getFirstLetter(s.name) : ''}
                                            </h1>
                                        )}
                                    </div>
                                    <p>{s.name}</p>
                                </div>
                            </div>
                        ))}
                    </>
                ) : (
                    selectedDates.map((d) => {
                        const selectedDay = moment(d);
                        return (
                            <div className="spa__calendar--day" key={d.toString()}>
                                <div className="spa__calendar--day--label date">
                                    <div className="avatar mb-2">
                                        <h1 className="h1 m-0">{selectedDay.format('DD')}</h1>
                                    </div>
                                    <p className="text-capitalize">{selectedDay.format('dddd')}</p>
                                </div>
                            </div>
                        );
                    })
                )}
            </div>
            <div className="spa__calendar--grid">
                {paginable && (
                    <>
                        {pagination?.prev && (
                            <Button className="spa__calendar--btn prev" onClick={() => onPagination(false)}>
                                <i className="icon-chevron-left" />
                            </Button>
                        )}
                        {pagination?.next && (
                            <Button className="spa__calendar--btn next" onClick={onPagination}>
                                <i className="icon-chevron-right" />
                            </Button>
                        )}
                    </>
                )}
                <ScrollbarsIf>
                    <div className="d-flex align-items-start">
                        <div className="spa__calendar--times">
                            <div className="current-drag-time d-none" />
                            <div className="drag-time" style={{ top: `${currentTimePosition}px` }} />
                            {timesLabel.map((t) => (
                                <span className="spa__calendar--time" key={t}>
                                    {/:00$/.test(t) ? t : ''}
                                </span>
                            ))}
                            <div className="current-time" ref={currentTimeRef} style={{ top: `${currentTimePosition}px` }}>
                                {moment(currentTime).format('HH:mm')}
                            </div>
                        </div>
                        <div className="spa__calendar--days d-flex">
                            {mode === CalendarMode.OneDay ? (
                                <>
                                    {appts.map((s, parentIndex) => {
                                        const startTime = convertTimeBack(s.shift?.start_time);
                                        const endTime = convertTimeBack(s.shift?.end_time);
                                        return (
                                            <div
                                                className="spa__calendar--day spa__calendar--staff"
                                                key={s.id}
                                                style={{ width: `calc(100% / ${appts.length < 7 ? appts.length : 7})` }}
                                            >
                                                <div className="spa__calendar--day--content d-flex flex-column">
                                                    {timesLabel.map((t) => {
                                                        const currentInMinutes = convertTimeBack(t);
                                                        const currentDay = moment(startDate).days();
                                                        const isWorkingDay = currentDay >= workingDays[0] && currentDay <= workingDays[1];
                                                        const isWorkingHour = currentInMinutes >= (startTime || workingHour[0])
                                                            && currentInMinutes <= (endTime || workingHour[1]);
                                                        return (
                                                            <a
                                                                className={`spa__calendar--cell ${isWorkingDay && isWorkingHour ? 'working' : ''}`}
                                                                key={t}
                                                                href="/"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    const newParams = {
                                                                        startDate: moment(startDate).format(SHORT_DATE_FORMAT),
                                                                        startTime: currentInMinutes,
                                                                        staff: s.id,
                                                                        branch: filters?.branch,
                                                                    };
                                                                    if (!s.id) delete newParams.staff;
                                                                    if (!filters?.branch) delete newParams.branch;
                                                                    handleAddAppointment(newParams);
                                                                }}
                                                            >
                                                                {t}
                                                            </a>
                                                        );
                                                    })}
                                                    {s.appointments
                                                        && s.appointments.length > 0
                                                        && s.appointments
                                                            .map((sa, childIndex) => {
                                                                const sDate = moment(sa.start);
                                                                const newSDate = sa.newStart ? moment(sa.newStart) : null;
                                                                const eDate = moment(sa.end);
                                                                const durationInMinutes = eDate.diff(newSDate || sDate, 'minutes');
                                                                const top = (newSDate ? newSDate.hours() * 60 + newSDate.minutes() : sDate.hours() * 60 + sDate.minutes())
                                                                    * (blockHeight / block);
                                                                const height = durationInMinutes * (blockHeight / block);
                                                                let noOfCollosion = 0;
                                                                s.appointments.forEach((a) => {
                                                                    const aStart = moment(a.start);
                                                                    const aEnd = moment(a.end);
                                                                    const isCollosion = sDate.diff(aStart, 'minutes') > 0 && aEnd.diff(sDate, 'minutes') > 0;
                                                                    if (isCollosion) noOfCollosion += 1;
                                                                });
                                                                const duration = moment.duration(eDate.diff(sDate));
                                                                const durationInHoursMinutes = `
                                                                        ${parseInt(duration.asHours().toString(), 10)} giờ ${duration.asMinutes() % 60} phút
                                                                    `;
                                                                return (
                                                                    <Popover
                                                                        content={(
                                                                            <div className="spa__appointment--popover spa__appointment--client">
                                                                                <div className="spa__appointment--client--info spa__border-box">
                                                                                    <div className="d-flex align-items-center mb-2">
                                                                                        <div className="avatar">
                                                                                            <h1 className="h1 m-0">{getFirstLetter(sa.client.name)}</h1>
                                                                                        </div>
                                                                                        <div className="client-info ml-2">
                                                                                            <div className="client-info--name f-medium d-flex align-items-center">
                                                                                                <span>{sa.client.name}</span>
                                                                                            </div>
                                                                                            <p className="client-info--phone">{sa.client.mobile}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <p className="note mt-2 mb-2">{sa.note}</p>
                                                                                </div>
                                                                                <div
                                                                                    className={`
                                                                                            spa__appointment--time
                                                                                            spa__border-box
                                                                                            d-flex align-items-center
                                                                                            justify-content-between
                                                                                        `}
                                                                                >
                                                                                    <div className="info">
                                                                                        <p className="info--date m-0">
                                                                                            <span className="info--note mr-2">
                                                                                                {`${sDate.format('HH:mm')} - ${eDate.format('HH:mm')}`}
                                                                                            </span>
                                                                                            <small className={`spa__status ${sa.status.value.toLowerCase()}`}>
                                                                                                {sa.status.label}
                                                                                            </small>
                                                                                        </p>
                                                                                        <div className="info-block">
                                                                                            <p className="info-block--name f-medium m-0">{sa.service.name}</p>
                                                                                            <p className="info--note info-block--description m-0">
                                                                                                {`${durationInHoursMinutes} với ${s.name}`}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <p className="f-medium mb-0 ml-5">
                                                                                        {formatCurrency(sa.service.price * sa.service.quantity)}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        placement="right"
                                                                        overlayClassName="spa__calendar--item--popover no-padding"
                                                                        key={sa.id}
                                                                    >
                                                                        <div
                                                                            className={`spa__calendar--item ${s.id ? '' : 'unassigned'}`}
                                                                            style={{
                                                                                backgroundColor: s.color,
                                                                                top: `${top}px`,
                                                                                height: `${height}px`,
                                                                                marginRight: noOfCollosion > 0 ? `${15 * noOfCollosion}px` : '0px',
                                                                                width: noOfCollosion > 0 ? `calc(100% - ${15 * noOfCollosion}px)` : '',
                                                                                zIndex: noOfCollosion,
                                                                            }}
                                                                            data-duration={sa.duration}
                                                                            data-parent-index={parentIndex}
                                                                            data-child-index={childIndex}
                                                                            data-id={sa.appointmentId}
                                                                            data-child-id={sa.id}
                                                                        >
                                                                            <a
                                                                                className="spa__calendar--item--content"
                                                                                href="/"
                                                                                onClick={(e) => e.preventDefault()}
                                                                                onMouseUp={() => {
                                                                                    if (!isDrag) {
                                                                                        history.push(VIEW_APPOINTMENT_URL.replace(':appointmentId', sa.appointmentId));
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <h5 className="h5 f-medium d-flex align-items-center">
                                                                                    <span className="f-regular mr-1">
                                                                                        {`${sDate.format('HH:mm')}-${eDate.format('HH:mm')}`}
                                                                                    </span>
                                                                                    <span>{sa.client.name}</span>
                                                                                </h5>
                                                                                <p>{sa.service.name}</p>
                                                                                <i className={`spa__status-icon ${sa.status.value.toLowerCase()} ${getStatusIcon(sa)}`} />
                                                                                {sa.paid && (
                                                                                    <i className="icon-invoice" />
                                                                                )}
                                                                            </a>
                                                                        </div>
                                                                    </Popover>
                                                                );
                                                            })}
                                                </div>
                                            </div>
                                        );
                                    })}
                                    {moment(startDate).isSame(moment(), 'd') && <div className="current-time-line" style={{ top: `${currentTimePosition}px` }} />}
                                </>
                            ) : (
                                selectedDates.map((d, parentIndex) => {
                                    const selectedDay = moment(d);
                                    const dateAppointments = getDateAppointments(d);
                                    return (
                                        <div className="spa__calendar--day" key={d.toString()}>
                                            <div className="spa__calendar--day--content">
                                                {timesLabel.map((t) => {
                                                    const currentInMinutes = convertTimeBack(t);
                                                    const currentDay = moment(d).days();
                                                    const isWorkingDay = currentDay >= workingDays[0] && currentDay <= workingDays[1];
                                                    const isWorkingHour = currentInMinutes >= workingHour[0] && currentInMinutes <= workingHour[1];
                                                    return (
                                                        <a
                                                            className={`spa__calendar--cell ${isWorkingDay && isWorkingHour ? 'working' : ''}`}
                                                            key={t}
                                                            href="/"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                const newParams = {
                                                                    startDate: selectedDay.format(SHORT_DATE_FORMAT),
                                                                    startTime: currentInMinutes,
                                                                    branch: filters?.branch,
                                                                };
                                                                if (!filters?.branch) delete newParams.branch;
                                                                handleAddAppointment(newParams);
                                                            }}
                                                        >
                                                            {t}
                                                        </a>
                                                    );
                                                })}
                                                {dateAppointments.map((sa, childIndex) => {
                                                    const sDate = moment(sa.start);
                                                    const newSDate = sa.newStart ? moment(sa.newStart) : null;
                                                    const eDate = moment(sa.end);
                                                    const durationInMinutes = eDate.diff(newSDate || sDate, 'minutes');
                                                    const top = (newSDate ? newSDate.hours() * 60 + newSDate.minutes() : sDate.hours() * 60 + sDate.minutes())
                                                        * (blockHeight / block);
                                                    const height = durationInMinutes * (blockHeight / block);
                                                    let noOfCollosion = 0;
                                                    dateAppointments.forEach((a) => {
                                                        const aStart = moment(a.start);
                                                        const aEnd = moment(a.end);
                                                        const isCollosion = sDate.diff(aStart, 'minutes') > 0 && aEnd.diff(sDate, 'minutes') > 0;
                                                        if (isCollosion) noOfCollosion += 1;
                                                    });
                                                    const duration = moment.duration(eDate.diff(sDate));
                                                    const durationInHoursMinutes = `
                                                            ${parseInt(duration.asHours().toString(), 10)} giờ ${duration.asMinutes() % 60} phút
                                                        `;
                                                    return (
                                                        <Popover
                                                            content={(
                                                                <div className="spa__appointment--popover spa__appointment--client">
                                                                    <div className="spa__appointment--client--info spa__border-box">
                                                                        <div className="d-flex align-items-center mb-2">
                                                                            <div className="avatar">
                                                                                {sa.client.avatar ? (
                                                                                    <img src={sa.client.avatar_url} alt={sa.client.name} />
                                                                                ) : (
                                                                                    <h1 className="h1 m-0">{getFirstLetter(sa.client.name)}</h1>
                                                                                )}
                                                                            </div>
                                                                            <div className="client-info ml-2">
                                                                                <div className="client-info--name f-medium d-flex align-items-center">
                                                                                    <span>{sa.client.name}</span>
                                                                                    {/* <div className={`spa__rank ${sa.client.rank.color} ml-2`}>
                                                                                        <i className="icon-cup" />
                                                                                    </div> */}
                                                                                </div>
                                                                                <p className="client-info--phone">{sa.client.mobile}</p>
                                                                            </div>
                                                                        </div>
                                                                        {/* <p className="spa__badge new text-uppercase">Khách hàng mới</p> */}
                                                                        <p className="note mt-2 mb-2">{sa.note}</p>
                                                                    </div>
                                                                    <div
                                                                        className={`
                                                                            spa__appointment--time
                                                                            spa__border-box
                                                                            d-flex align-items-center
                                                                            justify-content-between
                                                                        `}
                                                                    >
                                                                        <div className="info">
                                                                            <p className="info--date m-0">
                                                                                <span className="info--note mr-2">
                                                                                    {`${sDate.format('HH:mm')} - ${eDate.format('HH:mm')}`}
                                                                                </span>
                                                                                <small className={`spa__status ${sa.status.value.toLowerCase()}`}>{sa.status.label}</small>
                                                                            </p>
                                                                            <div className="info-block">
                                                                                <p className="info-block--name f-medium m-0">{sa.service.name}</p>
                                                                                <p className="info--note info-block--description m-0">
                                                                                    {`${durationInHoursMinutes} với ${sa.staff.name}`}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <p className="f-medium mb-0 ml-5">{formatCurrency(sa.service.price * sa.service.quantity)}</p>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            placement="right"
                                                            overlayClassName="spa__calendar--item--popover no-padding"
                                                            key={sa.id}
                                                        >
                                                            <div
                                                                className={`spa__calendar--item ${sa.staff.id ? '' : 'unassigned'}`}
                                                                style={{
                                                                    backgroundColor: sa.color,
                                                                    top: `${top}px`,
                                                                    height: `${height}px`,
                                                                    marginRight: noOfCollosion > 0 ? `${15 * noOfCollosion}px` : '0px',
                                                                    width: noOfCollosion > 0 ? `calc(100% - ${15 * noOfCollosion}px)` : '',
                                                                    zIndex: noOfCollosion,
                                                                }}
                                                                data-duration={sa.duration}
                                                                data-parent-index={parentIndex}
                                                                data-child-index={childIndex}
                                                                data-id={sa.appointmentId}
                                                                data-child-id={sa.id}
                                                            >
                                                                <a
                                                                    className="spa__calendar--item--content"
                                                                    href="/"
                                                                    onClick={(e) => e.preventDefault()}
                                                                    onMouseUp={() => {
                                                                        if (!isDrag) {
                                                                            history.push(VIEW_APPOINTMENT_URL.replace(':appointmentId', sa.appointmentId));
                                                                        }
                                                                    }}
                                                                >
                                                                    <h5 className="h5 f-medium d-flex align-items-center">
                                                                        <span className="f-regular mr-1">{`${sDate.format('HH:mm')}-${eDate.format('HH:mm')}`}</span>
                                                                        <span>{sa.client.name}</span>
                                                                    </h5>
                                                                    <p>{sa.service.name}</p>
                                                                    <i className={`spa__status-icon ${sa.status.value} ${getStatusIcon(sa)}`} />
                                                                    {!!sa.staff.id && (
                                                                        <div className="spa__calendar--item--staff d-flex align-items-center mt-1">
                                                                            <div className="avatar mr-1">
                                                                                {sa.staff.avatar_url ? (
                                                                                    <img src={sa.staff.avatar_url} alt={sa.staff.name} />
                                                                                ) : (
                                                                                    <h5 className="f-medium mb-0">{getFirstLetter(sa.staff.name)}</h5>
                                                                                )}
                                                                            </div>
                                                                            <p className="text-uppercase f-medium mb-0">{sa.staff.name}</p>
                                                                        </div>
                                                                    )}
                                                                    {sa.paid && (
                                                                        <i className="icon-invoice" />
                                                                    )}
                                                                </a>
                                                            </div>
                                                        </Popover>
                                                    );
                                                })}
                                            </div>
                                            {selectedDay.isSame(moment(), 'd') && <div className="current-time-line" style={{ top: `${currentTimePosition}px` }} />}
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    </div>
                </ScrollbarsIf>
            </div>
        </div>
    );
};
