import { createContext, useContext, useState } from 'react';
import { saveAs } from 'file-saver';
import {
    getCustomers,
    getCustomer,
    addCustomer,
    updateCustomerInfo,
    updateCustomerRank,
    updateCustomerInterests,
    importCustomersFromExcel,
    exportCustomersToExcel,
    assignStaffToCustomer,
    getCustomerStatistics,
    deleteCustomer,
} from 'actions';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';
import { CallHistoryContextProvider } from './CallHistoryContext';
import { FeedbackCategoryContextProvider } from './FeedbackCategoryContext';
import { FeedbackContextProvider } from './FeedbackContext';
import { MediaContextProvider } from './MediaContext';
import { DebtContextProvider } from './DebtContext';
import { ReceiptContextProvider } from './ReceiptContext';
import { CustomerTreatmentContextProvider } from './CustomerTreatmentContext';
import { CustomerInterestCategoryContextProvider } from './CustomerInterestCategoryContext';

export const CustomerContext = createContext({} as any);

export const CustomerContextProvider = ({ children }) => {
    const { setCustomerLoading, handleException } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [customers, setCustomers] = useState<any[]>([]);
    const [customer, setCustomer] = useState<any>();
    const [customerStatistics, setCustomerStatistics] = useState<any>();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCustomers, setTotalCustomers] = useState(0);

    const doGetCustomers = async (params, callback) => {
        try {
            setCustomerLoading(true);
            const response = await getCustomers(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const customerData = data.results || [];
                setCustomers(customerData);
                setCurrentPage(params?.page || 1);
                setTotalCustomers(data.count);
                if (callback) callback();
            }

            setCustomerLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doGetCustomer = async (customerId, callback, isLoading = true) => {
        try {
            if (isLoading) {
                setCustomerLoading(true);
                setCustomer(null);
            }
            const response = await getCustomer(customerId, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                setCustomer(data);
                if (callback) callback(data);
            }

            if (isLoading) {
                setCustomerLoading(false);
            }
        } catch (error) {
            handleException(error);
        }
    };

    const doGetCustomerStatistics = async (customerId, callback) => {
        try {
            setCustomerLoading(true);

            const response = await getCustomerStatistics(customerId, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                setCustomerStatistics(data);
                if (callback) callback();
            }

            setCustomerLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doAddCustomer = async (payload, callback) => {
        try {
            setCustomerLoading(true);

            const response = await addCustomer(payload, loggedInUser);
            if (response && response.status === 201) {
                setCustomers((old) => [response.data, ...old]);
                if (callback) callback(response.data);
            }

            setCustomerLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doUpdateCustomerInfo = async (customerId, payload, callback, isFormData = false) => {
        try {
            setCustomerLoading(true);

            const response = await updateCustomerInfo(customerId, payload, loggedInUser, isFormData);
            if (response && response.status === 200) {
                setCustomer(response?.data);
                setCustomers((old) => old.map((o) => (o.id === response?.data?.id ? response.data : o)));
                if (callback) callback();
            }

            setCustomerLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doUpdateCustomerRank = async (customerId, payload, callback) => {
        try {
            setCustomerLoading(true);
            const newPayload = {
                ...payload,
                ids: [customerId],
            };

            const response = await updateCustomerRank(newPayload, loggedInUser);
            if (response && response.status === 200) {
                if (callback) callback();
            }

            setCustomerLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doUpdateCustomerInterests = async (customerId, payload, callback) => {
        try {
            setCustomerLoading(true);

            const response = await updateCustomerInterests(customerId, payload, loggedInUser);
            if (response && response.status === 200) {
                setCustomer(response.data);
                if (callback) callback();
            }

            setCustomerLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doImportCustomers = async (payload, callback) => {
        try {
            setCustomerLoading(true);

            const response = await importCustomersFromExcel(payload, loggedInUser);
            if (response && response.status === 200) {
                if (callback) callback(response.data);
            }

            setCustomerLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doExportCustomers = async (params, callback) => {
        try {
            setCustomerLoading(true);

            const response = await exportCustomersToExcel(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                const headerLine = response.headers['content-disposition'];
                const filename = headerLine.split('"')[1].replace('"', '');
                saveAs(blob, filename);
                if (callback) callback();
            }

            setCustomerLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doAssignStaffToCustomer = async (customerId, payload, callback) => {
        try {
            setCustomerLoading(true);

            const response = await assignStaffToCustomer(customerId, payload, loggedInUser);
            if (response && response.status === 200) {
                if (callback) callback();
            }

            setCustomerLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doDeleteCustomer = async (customerId, callback) => {
        try {
            setCustomerLoading(true);

            const response = await deleteCustomer(customerId, loggedInUser);
            if (response && response.status === 204) {
                if (callback) callback();
            }

            setCustomerLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <CustomerContext.Provider
            value={{
                customers,
                customer,
                customerStatistics,
                currentPage,
                totalCustomers,
                setCustomer,
                doGetCustomers,
                doGetCustomer,
                doAddCustomer,
                doUpdateCustomerInfo,
                doUpdateCustomerRank,
                doUpdateCustomerInterests,
                doImportCustomers,
                doExportCustomers,
                doAssignStaffToCustomer,
                doGetCustomerStatistics,
                doDeleteCustomer,
            }}
        >
            <ReceiptContextProvider>
                <DebtContextProvider>
                    <CallHistoryContextProvider>
                        <FeedbackCategoryContextProvider>
                            <FeedbackContextProvider>
                                <MediaContextProvider>
                                    <CustomerTreatmentContextProvider>
                                        <CustomerInterestCategoryContextProvider>
                                            {children}
                                        </CustomerInterestCategoryContextProvider>
                                    </CustomerTreatmentContextProvider>
                                </MediaContextProvider>
                            </FeedbackContextProvider>
                        </FeedbackCategoryContextProvider>
                    </CallHistoryContextProvider>
                </DebtContextProvider>
            </ReceiptContextProvider>
        </CustomerContext.Provider>
    );
};
