import axios from 'axios';
import {
    authHeaders,
    GET_APPOINTMENTS,
    GET_APPOINTMENT,
    POST_APPOINTMENT,
    PATCH_APPOINTMENT,
    DELETE_APPOINTMENT,
    POST_APPOINTMENT_CHECKOUT,
    POST_COMBO_EXTRA_APPOINTMENT,
    POST_APPOINTMENT_NEW,
} from 'actions';

/** Get appointments
 * @param {
 *      customer: string
 *      state: string
 *      is_hidden: string
 *      appointment_date: string
 *      branch: string
 *      start_time: string
 *      code: string
 *      source: string
 *      user: string
 *      ordering: string
 *      customer_item: string
 *      root_appointment: string
 *      search: string
 *      seller: string
 *      have_commission: string
 *      page: int
 *      page_size: int
 * } payload
 */
export const getAppointments = (params, token) => axios.get(
        GET_APPOINTMENTS,
        params
            ? {
                  params,
                  ...authHeaders(token),
              }
            : authHeaders(token),
    );

/** Get a appointment
 * @route {
 *      appointmentId: uuid
 * }
 */
export const getAppointment = (appointmentId, token) => axios.get(GET_APPOINTMENT.replace(':appointmentId', appointmentId), authHeaders(token));

/** Add new appointment
 * @param {
 *      appointment_date: 'YYYY-MM-DD',
 *      appointment_items: [
 *          {
 *              id: uuid,
 *              user: uuid,
 *              main_staffs: uuid[],
 *              assistant_staffs: uuid[],
 *              seller: uuid
 *              customer_item: uuid,
 *              product: uuid,
 *              note: string,
 *              quantity: int,
 *              start_time: string,
 *              duration: int,
 *              actual_price: int,
 *          }
 *      ],
 *      is_update_following: boolean,
 *      customer_name: string,
 *      customer_title: string,
 *      state: "NEW",
 *      start_time: string,
 *      note: string,
 *      duration: int,
 *      repetition_time: int,
 *      interval: int,
 *      time_unit: "DAY",
 *      is_hidden: boolean,
 *      customer: uuid,
 *      customer_item: uuid,
 *      customer_treatment: uuid,
 *      branch: uuid
 * }
 */
export const addAppointment = (payload, token) => {
    if (!payload) return null;
    return axios.post(POST_APPOINTMENT, payload, authHeaders(token));
};

export const addAppointmentNew = (appointmentId, payload, token) => {
    if (!payload) return null;
    return axios.post(POST_APPOINTMENT_NEW.replace(':appointmentId', appointmentId), payload, authHeaders(token));
};

/** Update appointment info
 * @param {
 * @param {
 *      appointment_date: 'YYYY-MM-DD',
 *      appointment_items: [
 *          {
 *              id: uuid,
 *              user: uuid,
 *              main_staffs: uuid[],
 *              assistant_staffs: uuid[],
 *              seller: uuid
 *              customer_item: uuid,
 *              product: uuid,
 *              note: string,
 *              quantity: int,
 *              start_time: string,
 *              duration: int,
 *              actual_price: int,
 *          }
 *      ],
 *      is_update_following: boolean,
 *      customer_name: string,
 *      customer_title: string,
 *      state: "NEW",
 *      start_time: string,
 *      note: string,
 *      duration: int,
 *      repetition_time: int,
 *      interval: int,
 *      time_unit: "DAY",
 *      is_hidden: boolean,
 *      customer: uuid,
 *      customer_item: uuid,
 *      customer_treatment: uuid,
 *      branch: uuid
 * }
 */
export const updateAppointment = (appointmentId, payload, token) => {
    if (!payload) return null;
    return axios.patch(PATCH_APPOINTMENT.replace(':appointmentId', appointmentId), payload, authHeaders(token));
};

/** Delete apppointment
 */
export const deleteAppointment = (appointmentId, token) => axios.delete(DELETE_APPOINTMENT.replace(':appointmentId', appointmentId), authHeaders(token));

/** Checkout appointment unpaid
 * @param {
 *      extra_fee: int,
 *      tip: 0,
 *      discount: int,
 *      note: string,
 *      appointment_date: 'YYYY-MM-DD',
 *      start_time: string
 * }
 */
export const checkoutAppointment = (appointmentId, payload, token) => {
    if (!payload) return null;
    return axios.post(POST_APPOINTMENT_CHECKOUT.replace(':appointmentId', appointmentId), payload, authHeaders(token));
};

/** @param {
 *      root_appointment: uuid,
 *      appointment_date: 'YYYY-MM-DD',
 *      appointment_items: [
 *          {
 *              id: uuid,
 *              user: uuid,
 *              main_staffs: uuid[],
 *              assistant_staffs: uuid[],
 *              seller: uuid
 *              customer_item: uuid,
 *              product: uuid,
 *              note: string,
 *              quantity: int,
 *              start_time: string,
 *              duration: int,
 *              actual_price: int,
 *          }
 *      ],
 *      is_update_following: boolean,
 *      customer_name: string,
 *      customer_title: string,
 *      state: "NEW",
 *      start_time: string,
 *      note: string,
 *      duration: int,
 *      repetition_time: int,
 *      interval: int,
 *      time_unit: "DAY",
 *      is_hidden: boolean,
 *      customer: uuid,
 *      customer_item: uuid,
 *      customer_treatment: uuid,
 *      branch: uuid
 * }
 */
export const addExtraComboAppointment = (payload, token) => {
    if (!payload) return null;
    return axios.post(POST_COMBO_EXTRA_APPOINTMENT, payload, authHeaders(token));
};
