import axios from 'axios';
import { authHeaders, GET_CUSTOMER_GROUPS } from 'actions';

/** Get customer groups
 * @param {
 *      page: int
 *      page_size: int
 * } payload
 */
export const getCustomerGroups = (params, token) => axios.get(
    GET_CUSTOMER_GROUPS,
    params
    ? {
            params,
            ...authHeaders(token),
        }
    : authHeaders(token),
);
