import {
    ACCESS_TOKEN,
} from "actions";

export const getToken = () => {
    const localAccessToken = localStorage.getItem(ACCESS_TOKEN);
    return localAccessToken || null;
}

export const removeToken = () => {
    localStorage.removeItem(ACCESS_TOKEN);
}