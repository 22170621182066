import axios from 'axios';
import { authHeaders, GET_CUSTOMER_TREATMENTS, GET_CUSTOMER_TREATMENT } from 'actions';

/** Get customer treatments
 * @param {
 *      treatment: string
 *      customer: uuid
 *      name: string
 *      ordering: string
 *      page: int
 *      page_size: int
 * } payload
 */
export const getCustomerTreatments = (params, token) => axios.get(
    GET_CUSTOMER_TREATMENTS,
    params
        ? {
                params,
                ...authHeaders(token),
            }
        : authHeaders(token),
);

/** Get customer treatment
 * @route {
 *      customerTreatmentId: uuid
 * }
 */
 export const getCustomerTreatment = (customerTreatmentId, token) => axios.get(
     GET_CUSTOMER_TREATMENT.replace(':customerTreatmentId', customerTreatmentId),
     authHeaders(token),
);
