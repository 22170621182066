import { Drawer as AntDrawer } from 'antd';

import './index.scss';

export const Drawer = ({ open = false, children, ...rest }) => (
	<div className="spa__drawer">
		<AntDrawer {...rest} visible={open} placement="right" footer={null} closable closeIcon={<i className="icon-remove" />}>
			{children}
		</AntDrawer>
	</div>
);
