import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import {
 Menu, Dropdown, Avatar, Image,
} from 'antd';

import './index.scss';
import avatar from 'assets/images/avatar.png';
import { Button, Notification } from 'components';
import {
 ADD_APPOINTMENT_URL, ADD_CLIENT_URL, LOGIN_URL, MY_PROFILE_URL, SEARCH_PREVIOUS_PAGE_URL, SEARCH_URL, SELECTED_CLIENT,
} from 'actions';
import { removeToken } from 'utils';
import { useContext, useState } from 'react';
import { AuthContext } from 'contexts';

export const Header = ({ title = '' }) => {
    const history = useHistory();
    const location = useLocation();
    const [notification, setNotification] = useState(false);
    const {
        userInfo,
    } = useContext(AuthContext);
    const handleMenuClick = (item) => {
        switch (item.key) {
            case 'logout':
                removeToken();
                localStorage.clear();
                if (window) window.location.replace(LOGIN_URL);
                else history.push(LOGIN_URL);
                break;
            default:
                break;
        }
    };
    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item className="no-hover">
                {userInfo && (
                    <div className="spa__my-profile-block d-flex align-items-center">
                        <Image src={userInfo?.avatar_url || avatar} preview={false} width={60} />
                        <div className="spa__my-profile--info ml-2">
                            <h5 className="h5 f-medium m-0">{userInfo?.fullname}</h5>
                            <Link to={MY_PROFILE_URL} className="spa__link">
                                Trang cá nhân
                            </Link>
                        </div>
                    </div>
                )}
            </Menu.Item>
            <Menu.Item key="logout">Đăng xuất</Menu.Item>
        </Menu>
    );
    const handleSearch = () => {
        localStorage.setItem(SEARCH_PREVIOUS_PAGE_URL, location.pathname);
        history.push(SEARCH_URL);
    };
    return (
        <div className="spa__header d-flex align-items-center">
            <h1 className="h1 m-0 f-bold">{title}</h1>
            <ul className="spa__header--menu d-flex align-items-center">
                <li>
                    <Button
                        type="primary"
                        shape="round"
                        onClick={() => {
                            localStorage.removeItem(SELECTED_CLIENT);
                            history.push(ADD_APPOINTMENT_URL);
                        }}
                    >
                        <div className="spa__header--btn d-flex align-items-center">
                            <span className="spa__header--btn--dot mr-2" />
                            <span className="spa__header--btn--label">Đặt lịch mới</span>
                        </div>
                    </Button>
                </li>
                <li>
                    <Button type="primary" shape="round" onClick={() => history.push(ADD_CLIENT_URL)}>
                        <div className="spa__header--btn d-flex align-items-center">
                            <span className="spa__header--btn--dot mr-2" />
                            <span className="spa__header--btn--label">Thêm khách hàng</span>
                        </div>
                    </Button>
                </li>
                <li>
                    <button type="button" className=" ml-2 spa__header--icon" onClick={handleSearch}>
                        <i className="icon-search" />
                    </button>
                </li>
                <li>
                    <button type="button" className="spa__header--icon" onClick={() => setNotification(true)}>
                        <i className="icon-bell" />
                    </button>
                </li>
                <li>
                    <Dropdown overlay={menu} trigger={['click']} overlayClassName="header">
                        <Avatar alt="Trang cá nhân" src={<Image src={userInfo?.avatar_url || avatar} preview={false} />} size={45} />
                    </Dropdown>
                </li>
            </ul>
            <Notification open={notification} onClose={() => setNotification(false)} width={356} />
        </div>
    );
};
