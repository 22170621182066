import { Form, DatePicker as AntDatePicker } from 'antd';
import './index.scss';

export const DatePicker = ({
	className = '', name, label = '', rules = [] as any, ...rest
}) => (
	<Form.Item className={`spa__date-picker ${className}`} name={name} rules={rules} label={label}>
		<AntDatePicker {...rest} />
	</Form.Item>
);

export const DateRangePicker = ({
	className = '', name, label = '', rules = [] as any, ...rest
}) => (
	<Form.Item className={`spa__date-range-picker ${className}`} name={name} rules={rules} label={label}>
		<AntDatePicker.RangePicker {...rest} />
	</Form.Item>
);
