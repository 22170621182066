import { createContext, useContext, useState } from 'react';
import { getProducts, getProduct, sellProduct } from 'actions';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';

export const ProductContext = createContext({} as any);

export const ProductContextProvider = ({ children }) => {
    const { setProductLoading, handleException } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [products, setProducts] = useState<any[]>([]);
    const [product, setProduct] = useState<any>();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalProducts, setTotalProducts] = useState(0);

    const doGetProducts = async (params, callback) => {
        try {
            setProductLoading(true);

            const response = await getProducts(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const productData = data.results || [];
                setProducts(productData);
                setCurrentPage(params?.page || 1);
                setTotalProducts(data.count);
                if (callback) callback();
            }

            setProductLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doGetProduct = async (productId, callback) => {
        try {
            setProductLoading(true);

            const response = await getProduct(productId, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                setProduct(data);
                if (callback) callback();
            }

            setProductLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doSellProduct = async (productId, payload, callback) => {
        try {
            setProductLoading(true);

            const response = await sellProduct(productId, payload, loggedInUser);
            if (response && response.status === 200) {
                if (callback) callback();
            }

            setProductLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <ProductContext.Provider
            value={{
                products,
                product,
                currentPage,
                totalProducts,
                doGetProducts,
                doGetProduct,
                doSellProduct,
                setProducts,
            }}
        >
            {children}
        </ProductContext.Provider>
    );
};
