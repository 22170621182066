import axios from 'axios';
import { authHeaders, GET_PROMOTIONS, GET_PROMOTION } from 'actions';

/** Get promotions
 * @param {
 *      name: string
 *      start_date: date
 *      end_date: date
 *      is_voucher: boolean
 *      voucher_code: string
 *      type: string
 *      is_for_birthday: string
 *      gender: string
 *      created_at: string
 *      page: int
 *      page_size: int
 * } payload
 */
export const getPromotions = (params, token) => axios.get(
    GET_PROMOTIONS,
    params
        ? {
                params,
                ...authHeaders(token),
            }
        : authHeaders(token),
);

/** Get promotion
 * @route {
 *      promotionId: uuid
 * }
 */
 export const getPromotion = (promotionId, token) => axios.get(GET_PROMOTION.replace(':promotionId', promotionId), authHeaders(token));
