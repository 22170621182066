import { createContext, useContext, useState } from 'react';
import {
 getInvoiceItems,
} from 'actions';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';

export const InvoiceItemContext = createContext({} as any);

export const InvoiceItemContextProvider = ({ children }) => {
    const { setInvoiceItemLoading, handleException } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [invoiceItems, setInvoiceItems] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalInvoiceItems, setTotalInvoiceItems] = useState(0);

    const doGetInvoiceItems = async (params, callback) => {
        try {
            setInvoiceItemLoading(true);

            const response = await getInvoiceItems(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const invoiceData = data.results || [];
                setInvoiceItems(invoiceData);
                setCurrentPage(params?.page || 1);
                setTotalInvoiceItems(data.count);
                if (callback) callback();
            }

            setInvoiceItemLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <InvoiceItemContext.Provider
            value={{
                invoiceItems,
                currentPage,
                totalInvoiceItems,
                doGetInvoiceItems,
            }}
        >
            {children}
        </InvoiceItemContext.Provider>
    );
};
