import axios from 'axios';
import {
 authHeaders, GET_PROFILES, GET_PROFILE, POST_PROFILE, PATCH_PROFILE, DELETE_PROFILE,
} from 'actions';

/** Get profiles
 * @param {
 *      customer: uuid
 *      user: uuid
 *      page: int
 *      page_size: int
 * } payload
 */
export const getProfiles = (params, token) => axios.get(
        GET_PROFILES,
        params
        ? {
                params,
                ...authHeaders(token),
            }
        : authHeaders(token),
    );

/** Get profile
 * @route {
 *      rankId: uuid
 * }
 */
export const getProfile = (profileId, token) => axios.get(GET_PROFILE.replace(':profileId', profileId), authHeaders(token));

/** Add new profile
 * @param {
 *      tenant: uuid,
 *      content: string,
 *      customer: uuid,
 *      user: uuid,
 * }
 */
export const addProfile = (payload, token) => {
    if (!payload) return null;
    return axios.post(POST_PROFILE, payload, authHeaders(token));
};

/** Update profile
 * @param {
 *      tenant: uuid,
 *      content: string,
 *      customer: uuid,
 *      user: uuid,
 * }
 */
export const updateProfile = (profileId, payload, token) => {
    if (!payload) return null;
    return axios.patch(PATCH_PROFILE.replace(':profileId', profileId), payload, authHeaders(token));
};

/** Delete profile
 * @route {
 *      profileId: uuid
 * }
 */
export const deleteProfile = (profileId, token) => axios.delete(DELETE_PROFILE.replace(':profileId', profileId), authHeaders(token));
