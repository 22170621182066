import { createContext, useContext, useState } from 'react';
import { getCalls, getCall } from 'actions';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';

export const CallHistoryContext = createContext({} as any);

export const CallHistoryContextProvider = ({ children }) => {
    const { setCallLoading, handleException } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [calls, setCalls] = useState<any[]>([]);
    const [call, setCall] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCalls, setTotalCalls] = useState(0);

    const doGetCalls = async (params, callback) => {
        try {
            setCallLoading(true);

            const response = await getCalls(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const callData = data.results || [];
                setCalls(callData);
                setCurrentPage(params?.page || 1);
                setTotalCalls(data.count);
                if (callback) callback();
            }

            setCallLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doGetCall = async (callId, callback) => {
        try {
            setCallLoading(true);

            const response = await getCall(callId, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                setCall(data);
                if (callback) callback();
            }

            setCallLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <CallHistoryContext.Provider
            value={{
                calls,
                call,
                currentPage,
                totalCalls,
                doGetCalls,
                doGetCall,
            }}
        >
            {children}
        </CallHistoryContext.Provider>
    );
};
