import axios from 'axios';
import { authHeaders, GET_PRINTERS } from 'actions';

/** Get printers
 * @param {
 *      ip: string
 *      branch: string
 *      page: int
 *      page_size: int
 * } payload
 */
export const getPrinters = (params, token) => axios.get(
    GET_PRINTERS,
    params
        ? {
                params,
                ...authHeaders(token),
            }
        : authHeaders(token),
);
