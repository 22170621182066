import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import './index.scss';
import { ScrollbarsIf } from 'components';
import { menus } from 'utils';
import { PREVIOUS_PAGE_URL, SIDEBAR, WINDOW_SIDEBAR_BREAKPOINT } from 'actions';

export const Sidebar = () => {
	const location = useLocation();
	const [expanded, setExpanded] = useState(sessionStorage.getItem(SIDEBAR) === 'true');
	useEffect(() => {
		if (window) {
			if (!sessionStorage.getItem(SIDEBAR)) setExpanded(window.innerWidth > WINDOW_SIDEBAR_BREAKPOINT);
			window.addEventListener('resize', () => {
				setExpanded(window.innerWidth > WINDOW_SIDEBAR_BREAKPOINT);
			});
		}
	}, []);
	return (
		<div className={`spa__sidebar ${expanded ? 'expanded' : ''}`}>
			<button
				type="button"
				className={expanded ? 'expanded' : ''}
				onClick={() => {
					sessionStorage.setItem(SIDEBAR, (!expanded).toString());
					setExpanded(!expanded);
				}}
			>
				{expanded ? <i className="icon-collapse" /> : <i className="icon-expand" />}
			</button>
			<ScrollbarsIf autoHide mode="">
				<ul>
					{menus.map((m) => (m.url ? (
						<li key={m.id} className={location.pathname.indexOf(m.url) > -1 ? 'active' : ''}>
							<Link
								to={m.url}
								onClick={() => {
									localStorage.setItem(PREVIOUS_PAGE_URL, m.url);
								}}
							>
								<i className={m.icon} />
								<span>{m.label}</span>
							</Link>
						</li>
					) : (
						<li key={m.id} className="spa__sidebar--label f-medium">
							{m.label}
						</li>
					)))}
				</ul>
			</ScrollbarsIf>
		</div>
	);
};
