import axios from 'axios';
import {
 authHeaders, GET_MEDIAS, GET_MEDIA, POST_MEDIA, PATCH_MEDIA, DELETE_MEDIA,
} from 'actions';

/** Get medias
 * @param {
 *      customer: string
 *      user: string
 *      page: int
 *      page_size: int
 * } payload
 */
export const getMedias = (params, token) => axios.get(
        GET_MEDIAS,
        params
            ? {
                  params,
                  ...authHeaders(token),
              }
            : authHeaders(token),
    );

/** Get a media
 * @route {
 *      mediaId: uuid
 * }
 */
export const getMedia = (mediaId, token) => axios.get(GET_MEDIA.replace(':mediaId', mediaId), authHeaders(token));

/** Add new media
 * @param {
 *      tenant: uuid,
 *      url: string,
 *      customer: string,
 *      user: uuid,
 * }
 */
export const addMedia = (payload, token) => {
    if (!payload) return null;
    return axios.post(POST_MEDIA, payload, authHeaders(token, 'multipart/form-data'));
};

/** Update customer info
 * @param {
 *      tenant: uuid,
 *      url: string,
 *      customer: string,
 *      user: uuid,
 * }
 */
export const updateMedia = (mediaId, payload, token) => {
    if (!payload) return null;
    return axios.patch(PATCH_MEDIA.replace(':mediaId', mediaId), payload, authHeaders(token));
};

/** Delete media
 * @route {
 *      mediaId: uuid
 * }
 */
export const deleteMedia = (mediaId, token) => axios.delete(DELETE_MEDIA.replace(':mediaId', mediaId), authHeaders(token));
