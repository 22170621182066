import { createContext, useContext, useState } from 'react';
import { getSources } from 'actions';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';

export const SourceContext = createContext({} as any);

export const SourceContextProvider = ({ children }) => {
    const { setLoading, handleException } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [sources, setSources] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalSources, setTotalSources] = useState(0);

    const doGetSources = async (params, callback) => {
        try {
            setLoading(true);

            const response = await getSources(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const notificationData = data.results || [];
                setSources(notificationData);
                setCurrentPage(params?.page || 1);
                setTotalSources(data.count);
                if (callback) callback();
            }

            setLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <SourceContext.Provider
            value={{
                sources,
                currentPage,
                totalSources,
                doGetSources,
            }}
        >
            {children}
        </SourceContext.Provider>
    );
};
