import { createContext, useContext, useState } from 'react';
import { getUsers, getUser, postRegisterDevice } from 'actions';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';
import { ShiftContextProvider } from './ShiftContext';

export const UserContext = createContext({} as any);

export const UserContextProvider = ({ children }) => {
    const { setUserLoading, handleException } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [users, setUsers] = useState<any[]>([]);
    const [branchUsers, setBranchUsers] = useState<any[]>([]);
    const [user, setUser] = useState<any>();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalUsers, setTotalUsers] = useState(0);

    const doGetUsers = async (params, callback) => {
        try {
            if (users.length > 0) {
                const newUsers = users.filter((u) => u.branch === params?.branch);
                setBranchUsers(newUsers);
                setTotalUsers(newUsers.length);
                return;
            }
            setUserLoading(true);

            const response = await getUsers(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const userData = data.results || [];
                if (params?.branch) {
                    const newUsers = userData.filter((u) => u.branch === params?.branch);
                    setBranchUsers(newUsers);
                    setTotalUsers(newUsers.length);
                } else {
                    setUsers(userData);
                    setTotalUsers(data.count);
                }
                setCurrentPage(params?.page || 1);
                if (callback) callback();
            }

            setUserLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doGetUser = async (userId, callback) => {
        try {
            setUserLoading(true);

            const response = await getUser(userId, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                setUser(data);
                if (callback) callback();
            }

            setUserLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doRegisterDevice = async (payload, callback) => {
        try {
            setUserLoading(true);

            const response = await postRegisterDevice(payload, loggedInUser);
            if (response && response.status === 201) {
                if (callback) callback();
            }

            setUserLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <UserContext.Provider
            value={{
                users,
                branchUsers,
                user,
                currentPage,
                totalUsers,
                setUser,
                setUsers,
                doGetUsers,
                doGetUser,
                doRegisterDevice,
            }}
        >
            <ShiftContextProvider>
                {children}
            </ShiftContextProvider>
        </UserContext.Provider>
    );
};
