import { createContext, useContext, useState } from 'react';
import {
 getInvoices, getInvoice, addInvoice, updateInvoice, deleteInvoice, checkoutInvoice,
} from 'actions';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';

export const InvoiceContext = createContext({} as any);

export const InvoiceContextProvider = ({ children }) => {
    const { setInvoiceLoading, handleException } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [invoices, setInvoices] = useState<any[]>([]);
    const [invoice, setInvoice] = useState<any>();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalInvoices, setTotalInvoices] = useState(0);

    const doGetInvoices = async (params, callback) => {
        try {
            setInvoiceLoading(true);

            const response = await getInvoices({
                ...params,
                ordering: '-created_at',
            }, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const invoiceData = data.results || [];
                setInvoices(invoiceData);
                setCurrentPage(params?.page || 1);
                setTotalInvoices(data.count);
                if (callback) callback();
            }

            setInvoiceLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doGetInvoice = async (invoiceId, callback) => {
        try {
            setInvoiceLoading(true);

            const response = await getInvoice(invoiceId, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                setInvoice(data);
                if (callback) callback();
            }

            setInvoiceLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doAddInvoice = async (payload, callback) => {
        try {
            setInvoiceLoading(true);

            const response = await addInvoice(payload, loggedInUser);
            if (response && response.status === 201) {
                setInvoice(response.data);
                if (callback) callback(response.data);
            }

            setInvoiceLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doUpdateInvoice = async (invoiceId, payload, callback) => {
        try {
            setInvoiceLoading(true);

            const response = await updateInvoice(invoiceId, payload, loggedInUser);
            if (response && response.status === 200) {
                if (callback) callback();
            }

            setInvoiceLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doDeleteInvoice = async (invoiceId, callback) => {
        try {
            setInvoiceLoading(true);

            const response = await deleteInvoice(invoiceId, loggedInUser);
            if (response && response.status === 204) {
                if (callback) callback();
            }

            setInvoiceLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doCheckoutInvoice = async (invoiceId, payload, callback) => {
        try {
            setInvoiceLoading(true);

            const response = await checkoutInvoice(invoiceId, payload, loggedInUser);
            if (response && response.status === 200) {
                if (callback) callback();
            }

            setInvoiceLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <InvoiceContext.Provider
            value={{
                invoices,
                invoice,
                currentPage,
                totalInvoices,
                setInvoice,
                doGetInvoices,
                doGetInvoice,
                doAddInvoice,
                doUpdateInvoice,
                doDeleteInvoice,
                doCheckoutInvoice,
            }}
        >
            {children}
        </InvoiceContext.Provider>
    );
};
