import firebase from 'firebase/app';
import 'firebase/messaging';

import {
  PUSH_API,
} from 'actions';

var firebaseConfig = {
  apiKey: "AIzaSyCh4JjkCYVc3bOTZ7e16SC-BZ8D8XRjwtM",
  authDomain: "spago-2ba4d.firebaseapp.com",
  databaseURL: "https://spago-2ba4d.firebaseio.com",
  projectId: "spago-2ba4d",
  storageBucket: "spago-2ba4d.appspot.com",
  messagingSenderId: "35121314534",
  appId: "1:35121314534:web:dad602c8834df42ad71927",
  measurementId: "G-JRSBTG0KYC"
};

// var firebaseConfig = {
//   apiKey: "AIzaSyArrXsWBj_u3R4aeAZA705idi49nKE9Kkc",
//   authDomain: "authentication-44f4c.firebaseapp.com",
//   databaseURL: "https://authentication-44f4c.firebaseio.com",
//   projectId: "authentication-44f4c",
//   storageBucket: "authentication-44f4c.appspot.com",
//   messagingSenderId: "1012838268054",
//   appId: "1:1012838268054:web:949a8960efad82d0ad56b7",
//   measurementId: "G-DS0D56ZM1C"
// };

firebase.initializeApp(firebaseConfig);
let messaging = null;
if (firebase.messaging.isSupported()) {
  localStorage.setItem(PUSH_API, true);
  messaging = firebase.messaging()
} else {
  localStorage.setItem(PUSH_API, false);
}

export const onMessageListener = () => {
  if (!messaging) return Promise.resolve();
  return new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
}

export const getFirebaseToken = (setTokenFound, registerCallback) => {
  if (!messaging) return;
  return messaging.getToken({vapidKey: 'BOgCbYxRKPd3W_QuctR-e2esAJmjsewQFfYim9IJMlBq68jbhgyIZcEtNFKRdH6d2t_SKDOSPTVgdQpf40Y0T80'}).then((currentToken) => {
  // return messaging.getToken({vapidKey: 'BB0JDOAJWh-XPpVRZZy46yWBbzQXf71mzel7o7MXJTRrGQKv2YBScktbWzXSBcwhaF_W6K_7tVqHsy-ws-QU_48'}).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
      registerCallback({
        os: 'IOS',
        token: currentToken,
        is_active: true,
      })
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
};