import { createContext, useContext, useState } from 'react';
import { getDebts, getDebt } from 'actions';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';

export const DebtContext = createContext({} as any);

export const DebtContextProvider = ({ children }) => {
    const { setDebtLoading, handleException } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [debts, setDebts] = useState<any[]>([]);
    const [debt, setDebt] = useState<any>();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalDebts, setTotalDebts] = useState(0);
    const [totalDebtAmount, setTotalDebtAmount] = useState(0);

    const doGetDebts = async (params, callback) => {
        try {
            setDebtLoading(true);

            const response = await getDebts(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const notificationData = data.results || [];
                setDebts(notificationData);
                setCurrentPage(params?.page || 1);
                setTotalDebts(data.count);
                setTotalDebtAmount(data.total_debt || 0);
                if (callback) callback();
            }

            setDebtLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doGetDebt = async (debtId, callback) => {
        try {
            setDebtLoading(true);

            const response = await getDebt(debtId, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                setDebt(data);
                if (callback) callback();
            }

            setDebtLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <DebtContext.Provider
            value={{
                debts,
                debt,
                currentPage,
                totalDebts,
                totalDebtAmount,
                doGetDebts,
                doGetDebt,
            }}
        >
            {children}
        </DebtContext.Provider>
    );
};
