import { createContext, useContext, useState } from 'react';
import {
    getShifts,
} from 'actions';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';

export const ShiftContext = createContext({} as any);

export const ShiftContextProvider = ({ children }) => {
    const { setShiftLoading, handleException } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [shifts, setShifts] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalShifts, setTotalShifts] = useState(0);

    const doGetShifts = async (params, callback) => {
        try {
            setShiftLoading(true);

            const response = await getShifts(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const mediaData = data.results || [];
                setShifts(mediaData);
                setCurrentPage(params?.page || 1);
                setTotalShifts(data.count);
                if (callback) callback();
            }

            setShiftLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <ShiftContext.Provider
            value={{
                shifts,
                currentPage,
                totalShifts,
                doGetShifts,
            }}
        >
            {children}
        </ShiftContext.Provider>
    );
};
