import { createContext, useContext, useState } from 'react';
import {
 getSearchResults, getAppointments, getCustomers, SHORT_DATE_FORMAT,
} from 'actions';
import moment from 'moment';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';

export const SearchContext = createContext({} as any);

export const SearchContextProvider = ({ children }) => {
    const { setSearchLoading, handleException } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [appointmentResults, setAppointmentResults] = useState<any[]>([]);
    const [customerResults, setCustomerResults] = useState<any[]>([]);

    const doGetSearchResults = async (params, callback) => {
        try {
            setSearchLoading(true);

            const response = await getSearchResults(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                setAppointmentResults(data?.appointments || []);
                setCustomerResults(data?.customers || []);
                if (callback) callback();
            }

            setSearchLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doGetUpcomingAppointments = async (callback) => {
        try {
            setSearchLoading(true);

            const response = await getAppointments({
                ordering: 'appointment_date',
                state: 'NEW',
                appointment_date_after: moment().format(SHORT_DATE_FORMAT),
            }, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const appointmentData = data.results || [];
                setAppointmentResults(appointmentData);
                if (callback) callback();
            }

            setSearchLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doGetRecentAddedCustomers = async (callback) => {
        try {
            setSearchLoading(true);

            const response = await getCustomers({
                ordering: '-created_at',
            }, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const customerData = data.results || [];
                setCustomerResults(customerData);
                if (callback) callback();
            }

            setSearchLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <SearchContext.Provider
            value={{
                appointmentResults,
                customerResults,
                doGetSearchResults,
                doGetUpcomingAppointments,
                doGetRecentAddedCustomers,
            }}
        >
            {children}
        </SearchContext.Provider>
    );
};
