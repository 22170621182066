import axios from 'axios';
import {
 authHeaders, GET_INVOICES, GET_INVOICE, POST_INVOICE, PATCH_INVOICE, DELETE_INVOICE, POST_PAY_INVOICE,
} from 'actions';

/** Get invoices
 * @param {
 *      user: uuid
 *      issuer: uuid
 *      customer: uuid
 *      code: string
 *      branch: uuid
 *      tenant: uuid
 *      created_at: "YYYY-MM-DDTHH:mm:ss.mmmZ"
 *      search: string
 *      is_order: string
 *      is_card: string
 *      use_product: string
 *      seller: string
 *      page: int
 *      page_size: int
 * } payload
 */
export const getInvoices = (params, token) => axios.get(
        GET_INVOICES,
        params
            ? {
                  params,
                  ...authHeaders(token),
              }
            : authHeaders(token),
    );

/** Get invoice
 * @route {
 *      invoiceId: uuid
 * }
 */
export const getInvoice = (invoiceId, token) => axios.get(GET_INVOICE.replace(':invoiceId', invoiceId), authHeaders(token));

/** Add new invoice
 * @param {
 *      invoice_items: [
 *          {
 *              product: uuid
 *              quantity: int
 *          }
 *      ]
 *      created_at: "YYYY-MM-DDTHH:mm:ss.mmmZ"
 *      note: string
 *      tip: int
 *      discount: int
 *      extra_fee: int
 *      user: uuid
 *      issuer: uuid
 *      customer: uuid
 *      branch: uuid
 *      customer_treatment: uuid
 *      customer_item: uuid
 *      promotion: uuid
 *      appointment: uuid
 * }
 */
export const addInvoice = (payload, token) => {
    if (!payload) return null;
    return axios.post(POST_INVOICE, payload, authHeaders(token));
};

/** Update invoice
 * @param {
 *      invoice_items: [
 *          {
 *              product: uuid
 *              quantity: int
 *          }
 *      ]
 *      created_at: "YYYY-MM-DDTHH:mm:ss.mmmZ"
 *      note: string
 *      tip: int
 *      discount: int
 *      extra_fee: int
 *      user: uuid
 *      issuer: uuid
 *      customer: uuid
 *      branch: uuid
 *      customer_treatment: uuid
 *      customer_item: uuid
 *      promotion: uuid
 *      appointment: uuid
 * }
 */
export const updateInvoice = (invoiceId, payload, token) => {
    if (!payload) return null;
    return axios.patch(PATCH_INVOICE.replace(':invoiceId', invoiceId), payload, authHeaders(token));
};

/** Delete invoice
 * @route {
 *      invoiceId: uuid
 * }
 */
export const deleteInvoice = (invoiceId, token) => axios.patch(DELETE_INVOICE.replace(':invoiceId', invoiceId), authHeaders(token));

/** Checkout invoice
 * @param {
 *      payment_method: "CASH"
 *      reason: "SERVICE"
 *      amount: int
 *      note: string
 *      discount: int
 *      promotion: uuid
 * }
 */
export const checkoutInvoice = (invoiceId, payload, token) => {
    if (!payload) return null;
    return axios.post(POST_PAY_INVOICE.replace(':invoiceId', invoiceId), payload, authHeaders(token));
};
