export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const WINDOW_SIDEBAR_BREAKPOINT = 1440;

// export const URL_TEMPLATE_IM_CUSTOMERS = 'https://firebasestorage.googleapis.com/v0/b/spago-2ba4d.appspot.com/o/templates%2FSPAGO%40Template_Import_Customer.xlsx?alt=media&token=dd43bd00-42f3-4461-a182-1b3a6b2660be';
// export const URL_TEMPLATE_IM_PRODUCTS = 'https://firebasestorage.googleapis.com/v0/b/spago-2ba4d.appspot.com/o/templates%2FSPAGO%40Template_Import_San_Pham.xlsx?alt=media&token=08deb28e-01bc-4bbe-ae3f-a79f52f3d06e';
// export const URL_TEMPLATE_IM_SERVICES = 'https://firebasestorage.googleapis.com/v0/b/spago-2ba4d.appspot.com/o/templates%2FSPAGO%40Template_Import_Dich_Vu.xlsx?alt=media&token=fb06a1df-0d8b-4c16-b2fd-6d1a12dfb861';

export const URL_TEMPLATE_IM_CUSTOMERS = 'https://firebasestorage.googleapis.com/v0/b/spago-2ba4d.appspot.com/o/templates%2Fv2%2FSPAGO_Import%20Kha%CC%81ch%20ha%CC%80ng.xlsx?alt=media&token=dc4d957a-f1ed-420e-b32b-b344ce9ed4e8';
export const URL_TEMPLATE_IM_PRODUCTS = 'https://firebasestorage.googleapis.com/v0/b/spago-2ba4d.appspot.com/o/templates%2Fv2%2FSPAGO_Import%20Sa%CC%89n%20pha%CC%82%CC%89m.xlsx?alt=media&token=03cb413c-fbcf-4654-9b29-d243fadfc3a2';
export const URL_TEMPLATE_IM_SERVICES = 'https://firebasestorage.googleapis.com/v0/b/spago-2ba4d.appspot.com/o/templates%2Fv2%2FSPAGO_Import%20Di%CC%A3ch%20vu%CC%A3.xlsx?alt=media&token=93a57b49-a35f-4e66-be40-f0a7faf5790a';
export const URL_TEMPLATE_IM_CUSTOMER_ITEM = 'https://firebasestorage.googleapis.com/v0/b/spago-2ba4d.appspot.com/o/templates%2Fv2%2FSPAGO_Import%20The%CC%89%20lie%CC%A3%CC%82u%20tri%CC%80nh.xlsx?alt=media&token=2a9d4047-1c7b-4091-a8ad-019e8471685a';

// Routes
export const NOT_FOUND_URL = '/khong-tim-thay';
export const ACCOUNT_URL = '/tai-khoan';
export const LOGIN_URL = '/tai-khoan/dang-nhap';
export const REGISTER_URL = '/tai-khoan/dang-ky';
export const FORGOT_PASSWORD_URL = '/tai-khoan/quen-mat-khau';

export const ROOT_URL = '/';
export const HOME_URL = '/trang-chu';
export const SEARCH_URL = '/tim-kiem';
export const APPOINTMENT_URL = '/xem-lich-hen';
export const ADD_APPOINTMENT_URL = '/lich-hen/them-moi';
export const ADD_COMBO_APPOINTMENT_URL = '/the-lieu-trinh/lich-hen/:comboId';
export const VIEW_APPOINTMENT_URL = '/lich-hen/:appointmentId';
export const EDIT_APPOINTMENT_URL = '/lich-hen/sua/:appointmentId';
export const EDIT_COMBO_APPOINTMENT_URL = '/lich-hen/sua/:comboId/:appointmentId';
export const RESCHEDULE_APPOINTMENT_URL = '/lich-hen/doi-lich-trinh/:appointmentId';
export const APPOINTMENT_CHECKOUT_URL = '/lich-hen/thanh-toan/:appointmentId';
export const COMBO_CHECKOUT_URL = '/the-lieu-trinh/thanh-toan/:comboId';
export const TREATMENT_CHECKOUT_URL = '/phac-do/thanh-toan/:customerTreatmentId';
export const PRODUCT_CHECKOUT_URL = '/thanh-toan';
export const INVOICE_CHECKOUT_URL = '/hoa-don/thanh-toan/:invoiceId';
export const VIEW_INVOICE_URL = '/hoa-don/:invoiceId';
export const REBOOK_APPOINTMENT_URL = '/lich-hen/dat-lai/:appointmentId';
export const SALES_URL = '/ban-hang';
export const SALES_DAILY_SALES_URL = '/ban-hang/dong-tien-ngay';
export const SALES_APPOINTMENTS_URL = '/ban-hang/lich-hen';
export const SALES_INVOICES_URL = '/ban-hang/hoa-don';
export const STAFFS_URL = '/nhan-vien';
export const CLIENTS_URL = '/khach-hang';
export const ADD_CLIENT_URL = '/khach-hang/them-moi';
export const VIEW_CLIENT_URL = '/khach-hang/:clientId';
export const EDIT_CLIENT_URL = '/khach-hang/sua/:clientId';
export const SERVICES_URL = '/dich-vu';
export const LISTINGS_URL = '/danh-muc';
export const COMBO_URL = '/the-lieu-trinh';
export const ADD_COMBO_URL = '/the-lieu-trinh/them-moi';
export const VIEW_COMBO_URL = '/the-lieu-trinh/:comboId';
export const DEBT_URL = '/cong-no';
export const REVENUE_URL = '/thu-chi';
export const CHECKIN_OUT_URL = '/cham-cong';
export const REGIMEN_URL = '/phac-do-dieu-tri';
export const REPORT_URL = '/bao-cao';
export const LANDING_PAGE_URL = '/website-landing-page';
export const DISCOUNT_URL = '/khuyen-mai-va-voucher';
export const CUSTOMER_EXPERIENCE_URL = '/trai-nghiem-khach-hang';
export const OVERVIEW_URL = '/tong-quan';
export const SMART_MARKETING_URL = '/smart-marketing';
export const BRANCH_URL = '/chi-nhanh';
export const LOYALTY_URL = '/loyalty-va-tich-diem';
export const SETTINGS_URL = '/cai-dat';
export const MY_PROFILE_URL = '/trang-ca-nhan';

// UTILS
export const JQUERY_URL = '/lib/jquery-1.12.4.js';
export const JQUERY_UI_URL = '/lib/jquery-ui.js';
export const JQUERY_TOUCH_PUNCH_URL = '/lib/jquery.ui.touch-punch.min.js';

// API ENDPOINTS
// const API_BASE_URL = '/api';
const API_BASE_URL = '//api.spago.vn/api/v1';
export const POST_LOGIN = `${API_BASE_URL}/login`;
export const GET_USER_INFO = `${API_BASE_URL}/users/me`;
export const PATCH_USER_INFO = `${API_BASE_URL}/users/:userId`;
export const PUT_CHANGE_PASSWORD = `${API_BASE_URL}/change-password`;

export const GET_APPOINTMENTS = `${API_BASE_URL}/appointments`;
export const GET_APPOINTMENT = `${API_BASE_URL}/appointments/:appointmentId`;
export const POST_APPOINTMENT = `${API_BASE_URL}/appointments`;
export const POST_APPOINTMENT_NEW = `${API_BASE_URL}/customer_items/:appointmentId/use_card`;
export const PATCH_APPOINTMENT = `${API_BASE_URL}/appointments/:appointmentId`;
export const DELETE_APPOINTMENT = `${API_BASE_URL}/appointments/:appointmentId`;
export const POST_APPOINTMENT_CHECKOUT = `${API_BASE_URL}/appointments/:appointmentId/checkout`;
export const POST_COMBO_EXTRA_APPOINTMENT = `${API_BASE_URL}/appointments/additional_appointment`;

export const GET_BRANCHS = `${API_BASE_URL}/branches`;
export const GET_BRANCH = `${API_BASE_URL}/branches/:branchId`;

export const GET_STAFFS = `${API_BASE_URL}/users`;
export const GET_STAFF = `${API_BASE_URL}/users/:userId`;
export const POST_REGISTER_DEVICE = `${API_BASE_URL}/users/register_device`;

export const GET_PRODUCTS = `${API_BASE_URL}/products`;
export const GET_PRODUCT = `${API_BASE_URL}/products/:productId`;
export const POST_SELL_PRODUCT = `${API_BASE_URL}/products/purchase_card`;

export const GET_CUSTOMERS = `${API_BASE_URL}/customers`;
export const GET_CUSTOMER = `${API_BASE_URL}/customers/:customerId`;
export const POST_CUSTOMER = `${API_BASE_URL}/customers`;
export const PATCH_CUSTOMER = `${API_BASE_URL}/customers/:customerId`;
export const PATCH_CUSTOMERS_RANK = `${API_BASE_URL}/customers/rank_update`;
export const POST_CUSTOMER_INTERESTS = `${API_BASE_URL}/customers/:customerId/interests`;
export const POST_CUSTOMERS_EXCEL = `${API_BASE_URL}/customers/import_from_xlsx`;
export const GET_CUSTOMERS_EXCEL = `${API_BASE_URL}/customers/export`;
export const POST_ASSIGN_STAFF = `${API_BASE_URL}/customers/:customerId/assign_user`;
export const GET_CUSTOMER_STATISTICS = `${API_BASE_URL}/customers/:customerId/statistic`;
export const DELETE_CUSTOMER = `${API_BASE_URL}/customers/:customerId`;

export const GET_CUSTOMER_INTEREST_CATEGORIES = `${API_BASE_URL}/customer_interest_categories`;
export const GET_CUSTOMER_INTEREST_CATEGORY = `${API_BASE_URL}/customer_interest_categories/:customerInterestCategoryId`;
export const POST_CUSTOMER_INTEREST_CATEGORY = `${API_BASE_URL}/customer_interest_categories`;
export const PATCH_CUSTOMER_INTEREST_CATEGORY = `${API_BASE_URL}/customer_interest_categories/:customerInterestCategoryId`;
export const DELETE_CUSTOMER_INTEREST_CATEGORY = `${API_BASE_URL}/customer_interest_categories/:customerInterestCategoryId`;

export const GET_DEBTS = `${API_BASE_URL}/debts`;
export const GET_DEBT = `${API_BASE_URL}/debts/:debtId`;

export const GET_MEDIAS = `${API_BASE_URL}/medias`;
export const GET_MEDIA = `${API_BASE_URL}/medias/:mediaId`;
export const POST_MEDIA = `${API_BASE_URL}/medias`;
export const PATCH_MEDIA = `${API_BASE_URL}/medias/:mediaId`;
export const DELETE_MEDIA = `${API_BASE_URL}/medias/:mediaId`;

export const GET_PROFILES = `${API_BASE_URL}/profiles`;
export const GET_PROFILE = `${API_BASE_URL}/profiles/:profileId`;
export const POST_PROFILE = `${API_BASE_URL}/profiles`;
export const PATCH_PROFILE = `${API_BASE_URL}/profiles/:profileId`;
export const DELETE_PROFILE = `${API_BASE_URL}/profiles/:profileId`;

export const GET_RECEIPTS = `${API_BASE_URL}/receipts`;
export const GET_RECEIPT = `${API_BASE_URL}/receipts/:receiptId`;
export const POST_RECEIPT = `${API_BASE_URL}/receipts`;
export const PATCH_RECEIPT = `${API_BASE_URL}/receipts/:receiptId`;
export const DELETE_RECEIPT = `${API_BASE_URL}/receipts/:receiptId`;

export const GET_RANKS = `${API_BASE_URL}/ranks`;
export const GET_RANK = `${API_BASE_URL}/ranks/:rankId`;
export const POST_RANK = `${API_BASE_URL}/ranks`;
export const PATCH_RANK = `${API_BASE_URL}/ranks/:rankId`;
export const DELETE_RANK = `${API_BASE_URL}/ranks/:rankId`;

export const GET_SEARCH_ALL = `${API_BASE_URL}/search_all`;

export const GET_SOURCES = `${API_BASE_URL}/sources`;

export const GET_CUSTOMER_GROUPS = `${API_BASE_URL}/customer_groups`;

export const GET_COMBOS = `${API_BASE_URL}/customer_items`;
export const GET_COMBO = `${API_BASE_URL}/customer_items/:comboId`;
export const POST_COMBO = `${API_BASE_URL}/customer_items`;
export const PATCH_COMBO = `${API_BASE_URL}/customer_items/:comboId`;
export const DELETE_COMBO = `${API_BASE_URL}/customer_items/:comboId`;
export const PUT_CANCEL_COMBO = `${API_BASE_URL}/customer_items/:comboId/cancel_card`;
export const POST_USE_COMBO = `${API_BASE_URL}/customer_items/use_card`;

export const GET_INVOICES = `${API_BASE_URL}/invoices`;
export const GET_INVOICE = `${API_BASE_URL}/invoices/:invoiceId`;
export const POST_INVOICE = `${API_BASE_URL}/invoices`;
export const PATCH_INVOICE = `${API_BASE_URL}/invoices/:invoiceId`;
export const DELETE_INVOICE = `${API_BASE_URL}/invoices/:invoiceId`;
export const POST_PAY_INVOICE = `${API_BASE_URL}/invoices/:invoiceId/payment`;

export const GET_INVOICE_ITEMS = `${API_BASE_URL}/invoice_items`;

export const GET_DAILY_SALES = `${API_BASE_URL}/statistics/cash_flow`;

export const GET_NOTIFICATIONS = `${API_BASE_URL}/notifications`;
export const GET_NOTIFICATION = `${API_BASE_URL}/notifications/:notificationId`;

export const GET_NOTIFICATION_CATEGORIES = `${API_BASE_URL}/notification_categories`;

export const GET_FEEDBACK_CATEGORIES = `${API_BASE_URL}/feedback_categories`;

export const GET_FEEDBACKS = `${API_BASE_URL}/feedbacks`;
export const POST_FEEDBACK = `${API_BASE_URL}/feedbacks`;
export const PATCH_FEEDBACK = `${API_BASE_URL}/feedbacks/:feedbackId`;

export const GET_CALLS_HISTORY = `${API_BASE_URL}/calls`;
export const GET_CALL_HISTORY = `${API_BASE_URL}/calls/:callId`;

export const GET_CAREERS = `${API_BASE_URL}/careers`;

export const GET_CUSTOMER_TREATMENTS = `${API_BASE_URL}/customer_treatments`;
export const GET_CUSTOMER_TREATMENT = `${API_BASE_URL}/customer_treatments/:customerTreatmentId`;

export const GET_SHIFTS = `${API_BASE_URL}/shifts`;

export const GET_TENANT = `${API_BASE_URL}/tenants/:tenantId`;

export const GET_PROMOTIONS = `${API_BASE_URL}/promotions`;
export const GET_PROMOTION = `${API_BASE_URL}/promotions/:promotionId`;

export const GET_PRINTERS = `${API_BASE_URL}/printers`;

export const OPERATION_FAILED_MESSAGE = 'Đã có lỗi xảy ra. Vui lòng thử lại';
export const FEMALE = 'FEMALE';
export const MALE = 'MALE';
export const UNKNOWN = 'UNKNOWN';
export const NORMAL_DATE_FORMAT = 'YYYY-MM-DD HH:mm';
export const SHORT_DATE_FORMAT = 'YYYY-MM-DD';
export const SELECTED_CELL = 'SELECTED_CELL';
export const SELECTED_CLIENT = 'SELECTED_CLIENT';

export const DEFAULT_PAGE_SIZE = 100;
export const CUSTOMERS_PAGE_SIZE = 10;
export const COMBOS_PAGE_SIZE = 9;
export const APPOINTMENTS_PAGE_SIZE = 10;
export const INVOICES_PAGE_SIZE = 10;
export const NOTIFICATIONS_PAGE_SIZE = 10;

export const KEY_BRANCH = 'branch';
export const KEY_USER = 'user';
export const KEY_DATE = 'date';
export const KEY_SEARCH = 'search';
export const KEY_RANK = 'rank';
export const KEY_SOURCE = 'source';
export const KEY_CUSTOMER_GROUP = 'customer_group';
export const KEY_PAGINATION = 'pagination';
export const KEY_STATUS = 'status';
export const KEY_DATE_TYPE = 'dateType';

export const SINGLE_THERAPY = 'SINGLE_THERAPY';
export const PACKAGE_THERAPY = 'PACKAGE_THERAPY';
export const PRODUCT = 'PRODUCT';
export const OTHER = 'OTHER';
export const DEPOSIT = 'DEPOSIT';
export const EDUCATION = 'EDUCATION';
export const SERVICE = 'SERVICE';

export const CASH = 'CASH';
export const COD = 'COD';
export const CARD = 'CARD';
export const VOUCHER = 'VOUCHER';

export const SIDEBAR = 'SIDEBAR';
export const FINISH = 'FINISH';

export const DAY = 'DAY';
export const WEEK = 'WEEK';
export const MONTH = 'MONTH';

export const BLOCK = 15;

export const SELECTED_CUSTOMER = 'SELECTED_CUSTOMER';
export const SELECTED_PRODUCTS = 'SELECTED_PRODUCTS';
export const SELECTED_FEES = 'SELECTED_FEES';
export const SELECTED_PAYMENTS = 'SELECTED_PAYMENTS';
export const SELECTED_VOUCHER = 'SELECTED_VOUCHER';
export const SELECTED_PROMOTION = 'SELECTED_PROMOTION';
export const PREVIOUS_PAGE_URL = 'PREVIOUS_PAGE_URL';
export const SEARCH_PREVIOUS_PAGE_URL = 'SEARCH_PREVIOUS_PAGE_URL';
export const INVOICE_PREVIOUS_PAGE = 'INVOICE_PREVIOUS_PAGE';
export const ADD_CLIENT_PREVIOUS_PAGE = 'ADD_CLIENT_PREVIOUS_PAGE';
export const ADD_APPOINTMENT_PREVIOUS_PAGE = 'ADD_APPOINTMENT_PREVIOUS_PAGE';
export const CLIENT_SEARCH_TERM = 'CLIENT_SEARCH_TERM';
export const CLIENT_FILTERS = 'CLIENT_FILTERS';
export const SELECTED_COMBO = 'SELECTED_COMBO';
export const SELECTED_APPOINTMENTS = 'SELECTED_APPOINTMENTS';

export const PUSH_API = 'PUSH_API';
