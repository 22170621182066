import { useEffect } from 'react';

import './index.scss';
import { isModalOpen, toggleModalBodyClass } from 'utils';

export const Spinner = ({ className = '', transparent = false }) => {
	/*eslint-disable */
    useEffect(() => {
        toggleModalBodyClass(true);

        return () => {
            if (!isModalOpen()) toggleModalBodyClass(false);
        };
    }, []);
    /* eslint-enable */

	return (
		<div className={`spa__spinner ${transparent ? 'transparent' : 'full'} ${className}`}>
			<div className="spa__spinner--icon" />
		</div>
	);
};
