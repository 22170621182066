import axios from 'axios';
import {
 authHeaders, GET_PRODUCTS, GET_PRODUCT, POST_SELL_PRODUCT,
} from 'actions';

/** Get services
 * @param {
 *      name: string
 *      barcode: string
 *      sku: string
 *      type: string
 *      category: string
 *      search: string
 *      is_card: string
 *      brand: uuid
 *      ids: uuid[]
 *      null_category: string
 *      page: int
 *      page_size: int
 * } payload
 */
export const getProducts = (params, token) => axios.get(
        GET_PRODUCTS,
        params
            ? {
                  params,
                  ...authHeaders(token),
              }
            : authHeaders(token),
    );

/** Get services
 * @route {
 *      productId: string
 * } payload
 */
export const getProduct = (productId, token) => axios.get(GET_PRODUCT.replace(':productId', productId), authHeaders(token));

/** Purchase product
 * @param {
 *      id: uuid
 *      start_time: string
 *      main_staffs: uuid[]
 *      assistant_staffs: uuid[]
 *      seller: uuid
 *      branch: uuid
 *      customer: uuid
 *      expired_date: "YYYY-MM-DDTHH:mm:ss.mmmZ"
 *      usage_time: int
 *      interval: int
 *      price: int
 *      time_unit: int
 *      product_name: string
 *      extra_fee: int
 * }
 */
export const sellProduct = (productId, payload, token) => {
    if (!payload) return null;
    return axios.put(
        POST_SELL_PRODUCT,
        {
            id: productId,
            ...payload,
        },
        authHeaders(token),
    );
};
