export const authHeaders = (token, contentType = 'application/json') => ({
    headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': contentType,
    },
});

export * from 'actions/constants';
export * from 'actions/auth';
export * from 'actions/customer';
export * from 'actions/appointment';
export * from 'actions/branch';
export * from 'actions/user';
export * from 'actions/product';
export * from 'actions/media';
export * from 'actions/rank';
export * from 'actions/search';
export * from 'actions/combo';
export * from 'actions/invoice';
export * from 'actions/statistic';
export * from 'actions/notification';
export * from 'actions/feedback_category';
export * from 'actions/feedback';
export * from 'actions/call_history';
export * from 'actions/debt';
export * from 'actions/source';
export * from 'actions/customer_group';
export * from 'actions/career';
export * from 'actions/invoice_item';
export * from 'actions/profile';
export * from 'actions/receipt';
export * from 'actions/customer_treatment';
export * from 'actions/customer_interest_category';
export * from 'actions/shift';
export * from 'actions/tenant';
export * from 'actions/promotion';
export * from 'actions/notification_category';
export * from 'actions/printer';
