import { createContext, useContext, useState } from 'react';
import { getNotificationCategories } from 'actions';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';

export const NotificationCategoryContext = createContext({} as any);

export const NotificationCategoryContextProvider = ({ children }) => {
    const { setNotificationLoading, handleException } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [notificationCategories, setNotificationCategories] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalNotificationCategories, setTotalNotificationCategories] = useState(0);

    const doGetNotificationCategories = async (params, callback) => {
        try {
            setNotificationLoading(true);

            const response = await getNotificationCategories(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const notificationData = data.results || [];
                setNotificationCategories(notificationData);
                setCurrentPage(params?.page || 1);
                setTotalNotificationCategories(data.count);
                if (callback) callback();
            }

            setNotificationLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <NotificationCategoryContext.Provider
            value={{
                notificationCategories,
                currentPage,
                totalNotificationCategories,
                doGetNotificationCategories,
            }}
        >
            {children}
        </NotificationCategoryContext.Provider>
    );
};
