import { Form, Input as AntInput } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import './index.scss';

export const Input = ({
	className = '', type = 'text', name, label = '', rules = [] as any, ...rest
}) => {
	const InputField = () => {
		switch (type) {
		case 'text':
			return <AntInput {...rest} autoComplete="off" />;
		case 'textarea':
			return <AntInput.TextArea {...rest} autoComplete="off" />;
		case 'password':
			return <AntInput.Password {...rest} autoComplete="off" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />;
		case 'search':
			return <AntInput.Search {...rest} autoComplete="off" />;
		default:
			return <></>;
		}
	};
	return (
		<Form.Item className={`spa__input ${className}`} name={name} rules={rules} label={label}>
			{InputField()}
		</Form.Item>
	);
};
