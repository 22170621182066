import axios from 'axios';
import {
 authHeaders, GET_RANKS, GET_RANK, POST_RANK, PATCH_RANK, DELETE_RANK,
} from 'actions';

/** Get ranks
 * @param {
 *      page: int
 *      page_size: int
 * } payload
 */
export const getRanks = (params, token) => axios.get(
        GET_RANKS,
        params
            ? {
                  params,
                  ...authHeaders(token),
              }
            : authHeaders(token),
    );

/** Get rank
 * @route {
 *      rankId: uuid
 * }
 */
export const getRank = (rankId, token) => axios.get(GET_RANK.replace(':rankId', rankId), authHeaders(token));

/** Add new rank
 * @param {
 *      tenant: uuid,
 *      name: string,
 *      spend_money_condition: int,
 *      items_condition: int,
 * }
 */
export const addRank = (payload, token) => {
    if (!payload) return null;
    return axios.post(POST_RANK, payload, authHeaders(token));
};

/** Update rank
 * @param {
 *      tenant: uuid,
 *      name: string,
 *      spend_money_condition: int,
 *      items_condition: int,
 * }
 */
export const updateRank = (rankId, payload, token) => {
    if (!payload) return null;
    return axios.patch(PATCH_RANK.replace(':rankId', rankId), payload, authHeaders(token));
};

/** Delete rank
 * @route {
 *      rankId: uuid
 * }
 */
export const deleteRank = (rankId, token) => axios.delete(DELETE_RANK.replace(':rankId', rankId), authHeaders(token));
