import axios from 'axios';
import {
 authHeaders, POST_FEEDBACK, GET_FEEDBACKS, PATCH_FEEDBACK,
} from 'actions';

/** Get feedbacks
 * @route {
 *      customer_name: string
 *      customer_phone: string
 *      user: uuid
 *      appointment: uuid
 *      customer: uuid
 *      satisfy_state: string
 *      category: uuid
 *      search: string
 *      page: int
 *      page_size: int
 * }
 */
 export const getFeedbacks = (params, token) => axios.get(
    GET_FEEDBACKS,
    params
        ? {
              params,
              ...authHeaders(token),
          }
    : authHeaders(token),
);
/** Add feedback
 * @param {
 *      tenant: uuid
 *      satisfy_state: "GOOD"
 *      content: string
 *      appointment: uuid
 *      category: uuid
 *      user: uuid
 * }
 */
export const addFeedback = (payload, token) => {
    if (!payload) return null;
    return axios.post(POST_FEEDBACK, payload, authHeaders(token));
};

/** Update feedback
 * @param {
 *      tenant: uuid
 *      satisfy_state: "GOOD"
 *      content: string
 *      appointment: uuid
 *      category: uuid
 *      user: uuid
 * }
 */
export const updateFeedback = (feedbackId, payload, token) => {
    if (!payload) return null;
    return axios.patch(PATCH_FEEDBACK.replace(':feedbackId', feedbackId), payload, authHeaders(token));
};
