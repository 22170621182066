import { createContext, useContext, useState } from 'react';
import {
 getRanks, getRank, addRank, updateRank, deleteRank,
} from 'actions';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';

export const RankContext = createContext({} as any);

export const RankContextProvider = ({ children }) => {
    const { setLoading, handleException } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [ranks, setRanks] = useState<any[]>([]);
    const [rank, setRank] = useState<any>();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRanks, setTotalRanks] = useState(0);

    const doGetRanks = async (params, callback) => {
        try {
            setLoading(true);

            const response = await getRanks(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const mediaData = (data.results || []).sort((a, b) => a.spend_money_condition - b.spend_money_condition);
                setRanks(mediaData);
                setCurrentPage(params?.page || 1);
                setTotalRanks(data.count);
                if (callback) callback();
            }

            setLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doGetRank = async (rankId, callback) => {
        try {
            setLoading(true);

            const response = await getRank(rankId, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                setRank(data);
                if (callback) callback();
            }

            setLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doAddRank = async (payload, callback) => {
        try {
            setLoading(true);

            const response = await addRank(payload, loggedInUser);
            if (response && response.status === 201) {
                if (callback) callback();
            }

            setLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doUpdateRank = async (rankId, payload, callback) => {
        try {
            setLoading(true);

            const response = await updateRank(rankId, payload, loggedInUser);
            if (response && response.status === 200) {
                if (callback) callback();
            }

            setLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doDeleterank = async (rankId, callback) => {
        try {
            setLoading(true);

            const response = await deleteRank(rankId, loggedInUser);
            if (response && response.status === 204) {
                if (callback) callback();
            }

            setLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <RankContext.Provider
            value={{
                ranks,
                rank,
                currentPage,
                totalRanks,
                doGetRanks,
                doGetRank,
                doAddRank,
                doUpdateRank,
                doDeleterank,
            }}
        >
            {children}
        </RankContext.Provider>
    );
};
