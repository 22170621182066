import {
		lazy
} from 'react';
import moment from 'moment';
import { MALE, FEMALE, NORMAL_DATE_FORMAT, DEPOSIT, EDUCATION, OTHER, PRODUCT, SERVICE, DAY, WEEK, MONTH, FINISH, VIEW_APPOINTMENT_URL } from 'actions';
import {
	appointmentStatuses,
	payStatusOptions,
	fakeStatusOptions,
	fakeAddressList,
	clientCallings,
	notificationTitle,
} from 'utils';

export const delayImport = (comp) => lazy(() => {
	return Promise.all([
			comp,
			new Promise(resolve => setTimeout(resolve, 250))
	])
	.then(([moduleExports]) => moduleExports);
});

export const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.readAsDataURL(img);
	reader.addEventListener('load', () => callback(reader.result));
};

export const dateTimestampConverter = 1000 * 60 * 60 * 24;

export const htmlDecode = input => {
	var e = document.createElement('textarea');
	e.innerHTML = input;
	return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

export const pictureOnly = (file, message = () => {}) => {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
		if (!isJpgOrPng) {
			message('You can only upload JPG/PNG file!');
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message('Image must smaller than 2MB!');
		}
		return isJpgOrPng && isLt2M;
};

export const authHeaders = token => {
	return {
			headers: {
					'Authorization': `Bearer ${token}`
			}
	};
};

export const debounce = (fn, delay, immediate) => {
	let timeout;
	return function executedFn() {
			let context = this; // "this" context của executedFn
			let args = arguments; // "arguments" của fn

			let later = function() {
					timeout = null;
					if (!immediate) fn.apply(context, args);
			};

			let callNow = immediate && !timeout;

			clearTimeout(timeout);

			timeout = setTimeout(later, delay);

			if (callNow) fn.apply(context, args);
	}
};

export const loopThroughChildren = (subs, deep = 1) => {
	const newSubs = [];
	subs.forEach(s => {
		newSubs.push({
			...s,
			// name: `${Array(8 * deep).fill('&nbsp;').join('')}${s.name}`,
			styles: {
				paddingLeft: `${30 * deep}px`
			},
			subs: []
		});
		if (s.subs && s.subs.length > 0) newSubs.push(...loopThroughChildren(s.subs, deep + 1));
	});
	return newSubs;
}

export const enumerateDaysBetweenDates = (startDate, endDate) => {
	const dates = [startDate];

	let currDate = moment(startDate).startOf('day');
	const lastDate = moment(endDate).startOf('day');

	while(currDate.add(1, 'days').diff(lastDate) < 0) {
			dates.push(currDate.clone().toDate());
	}

	return dates;
};

export const getGender = gender => {
	switch (gender) {
		case FEMALE:
			return "Nữ";
		case MALE:
			return "Nam";
		default:
			return "Không xác định";
	}
}

export const getDob = (day, month, year) => {
	return `${day ? `0${day}`.slice(-2) + '/' : ''}${month ? `0${month}`.slice(-2) + '/' : ''}${year ? year : ''}`;
}

export const getFirstLetter = (name) => {
	if (!name) return;
	const nameParts = name.split(' ');
	const firstLetter = nameParts[nameParts.length - 1][0];

	return firstLetter;
}

export const getFullAddress = (addressList, address, city, district) => {
	const addressStr = address ? address : '';
	const province = addressList.find((fa) => fa.value === `${city},${district}`);
	return `${addressStr}${addressStr && province ? ', ' : ''}${province ? province.label : ''}`;
}

export const getRankMarkup = (rank, ranks, fakeRanks, iconIncluded = true) => {
	if (!rank) return null;
	let sRank = ranks.filter(r => r.id === rank);
	sRank = sRank.length > 0 ? sRank[0] : null;
	const selectedRank = fakeRanks.find((r) => r.id === sRank?.name);
	return iconIncluded ? (
		<div className={`spa__rank ${selectedRank?.color}`}>
			<i className="icon-cup" />
			<span>{selectedRank?.name}</span>
		</div>
	) : (
		<span>{selectedRank?.name}</span>
	);
};

export const getInvoiceStatus = (state) => {
	const selectedState = payStatusOptions.find(p => p.value === state);
	return <small className={`spa__status ${selectedState.value.toLowerCase()}`}>{selectedState.label}</small>
}

export const formatCurrency = (amount, unit = true, showSuffix = true) => typeof amount !== 'undefined' ? `${(amount && amount > 0 ? amount : 0).toLocaleString()}${showSuffix ? (unit ? 'đ' : '%') : ''}` : '';

export const convertCustomers = (customers, branchs = []) => {
	return customers.map(customer => {
		const { id, name, code, phone, gender, rank, branches, created_at, title, avatar_url, day_of_birth, month_of_birth, year_of_birth } = customer;
		const selectedBrs = branchs.filter(b => branches.some(br => br === b.id));
		const newCustomer = {
			value: id,
			avatar_url: null,
			avatar_letter: null,
			name,
			code,
			mobile: phone,
			dob: getDob(day_of_birth, month_of_birth, year_of_birth),
			gender: getGender(gender),
			date: moment(created_at).format(NORMAL_DATE_FORMAT),
			rank,
			selectedBranchs: selectedBrs,
			title,
			address: getFullAddress(fakeAddressList, customer.address, customer.district, customer.city),
			deposit: customer?.wallets?.find((w) => w.type === DEPOSIT)?.balance || 0,
		};
		if (avatar_url) newCustomer.avatar_url = avatar_url;
		else newCustomer.avatar_letter = getFirstLetter(name);

		return newCustomer;
	});
};

export const convertPagination = (pagination) => {
	return {
	  page: pagination.current,
	  page_size: pagination.pageSize
	}
}

export const getAppointmentState = (state) => {
	const selectedState = appointmentStatuses.find(a => a.value === state) || {value: '', label: ''};
	return <small className={`spa__status ${selectedState.value.toLowerCase()}`}>{selectedState.label}</small>
}

export const getPaymentStatus = (state, className = '') => {
	const selectedState = payStatusOptions.find(a => a.value === state) || {value: '', label: ''};
	return <small className={`spa__status ${className} ${selectedState.value.toLowerCase()}`}>{selectedState.label}</small>
}

export const getDuration = (timeInMinutes) => {
	const hours = parseInt(timeInMinutes / 60, 10);
	const minutes = timeInMinutes % 60;

	return timeInMinutes ? `${hours > 0 ? `${hours} giờ ` : ''}${minutes > 0 ? `${`0${minutes}`.slice(-2)} phút` : ''}` : timeInMinutes;
}

export const getFullDuration = (timeInSeconds) => {
	const hours = parseInt(timeInSeconds / 3600, 10);
	const minutes = parseInt((timeInSeconds - (hours * 3600)) / 60, 10);
	const seconds = timeInSeconds % 60;

	return timeInSeconds ? `${hours > 0 ? `${hours}h` : ''}${minutes > 0 ? `${`0${minutes}`.slice(-2)}m` : ''}${seconds > 0 ? `${`0${seconds}`.slice(-2)}s` : ''}` : timeInSeconds;
}

export const convertCombos = (combos) => {
	return combos.map(combo => {
		const { id, state, customer, code, interval, customer_name, customer_avatar, customer_phone, product_name, invoice, expired_date, total_appointment, done_appointment } = combo;
		const newCombo = {
			id,
			code,
			service: product_name,
			client: {
				id: customer,
				name: customer_name,
				avatar_url: null,
				avatar_letter: null,
				mobile: customer_phone,
			},
			price: invoice?.total || 0,
			totalQuantity: total_appointment,
			doneQuantity: done_appointment,
			frequency: interval ? `${interval} ngày/buổi` : '',
			expiredDate: expired_date,
			state,
			payment_state: payStatusOptions.find(p => p.value === invoice?.state),
		};
		if (customer_avatar) newCombo.client.avatar_url = customer_avatar;
		else newCombo.client.avatar_letter = getFirstLetter(customer_name);

		return newCombo;
	});
};

export const getReceiptReason = (reason) => {
	let receiptReason = '';
	switch (reason) {
		case DEPOSIT:
			receiptReason = 'Đặt cọc';
			break;
		case EDUCATION:
			receiptReason = 'Đào tạo';
			break;
		case OTHER:
			receiptReason = 'Khác';
			break;
		case PRODUCT:
			receiptReason = 'Sản phẩm';
			break;
		case SERVICE:
			receiptReason = 'Dịch vụ';
			break;
		default:
			break;
	}
	return receiptReason;
}

export const convertAppointments = (appointments, branchs = []) => {
	return appointments.map(appointment => {
		const {
			id,
			branch, 
			customer,
			code,
			customer_avatar,
			customer_name,
			appointment_items,
			appointment_date,
			start_time,
			duration,
			state,
			invoice,
		} = appointment;
		const staffs = [];
		let total = 0;
		let services = [];
		appointment_items.forEach(a => {
			const { id, main_staffs, assistant_staffs, product_name, product_price, quantity } = a;
			services.push({
				id,
				name: product_name,
			});
			total += (product_price || 0) * (quantity || 0);
			staffs.push(...(main_staffs || []).filter((s) => !staffs.some((st) => st.id === s.id)));
			staffs.push(...(assistant_staffs || []).filter((s) => !staffs.some((st) => st.id === s.id)));
		});
		const selectedBr = branchs.find(b => b.id === branch);
		const newAppointment = {
			id,
			code,
			client: {
				id: customer,
				name: customer_name,
				avatar_url: null,
				avatar_letter: null,
			},
			services,
			date: `${appointment_date} ${start_time || ''}`,
			duration: getDuration(duration),
			branch: {
				value: branch,
				label: selectedBr?.name
			},
			staffs,
			total,
			status: fakeStatusOptions.find(s => s.value === state),
			paid: invoice?.state === FINISH,

		};
		if (customer_avatar) newAppointment.client.avatar_url = customer_avatar;
		else newAppointment.client.avatar_letter = getFirstLetter(customer_name);

		return newAppointment;
	});
}

export const convertScheduleAppointments = (appointments, users, shifts) => {
	const unAssignedUser = {
		id: null,
		name: 'Chưa được gán',
		avatar_url: null,
		color: '#d8d8d8',
		appointments: [],
	};
	const newUsers = users.map((u) => {
		const { id, fullname, avatar_url, color, shift } = u;
		const selectedShift = shifts.find((s) => s.id === shift);
		return {
			id,
			name: fullname,
			avatar_url,
			color,
			shift: selectedShift,
			appointments: [],
		}
	});

	appointments.forEach((a) => {
		a.appointment_items.forEach((ai) => {
			const start = moment(`${a.appointment_date} ${ai.start_time || ''}`);
			const newAppointment = {
				appointmentId: a.id,
				id: ai.id,
				start: start.format(NORMAL_DATE_FORMAT),
				end: moment(start).add(ai.duration, 'm').format(NORMAL_DATE_FORMAT),
				duration: ai.duration,
				note: ai.note,
				client: {
					id: a.customer,
					name: a.customer_name,
					title: a.customer_title,
					avatar: a.customer_avatar
				},
				service: {
					id: ai.product_id,
					name: ai.product_name,
					price: ai.product_price,
					quantity: ai.quantity,
				},
				status: fakeStatusOptions.find((st) => st.value === a.state),
				paid: a?.invoice?.state === FINISH,
			};
			const isAssigned = [...(ai.main_staffs || []), ...(ai.assistant_staffs || [])];
			if (isAssigned.length > 0) {
				newUsers.forEach((u, uIndex) => {
					const isAssign = isAssigned.some((as) => as.id === u.id);
					if (isAssign) {
						newUsers[uIndex].appointments.push(newAppointment)
					}
				});
			} else {
				unAssignedUser.appointments.push(newAppointment);
			}
		});
	});

	return [
		unAssignedUser,
		...newUsers,
	];
}

export const convertInvoices = (invoices, branchs) => {
	return invoices.map(invoice => {
		const {
			id,
			code,
			customer_name,
			customer,
			created_at,
			branch,
			tip,
			discount,
			extra_fee,
			promotion,
			total,
			state
		} = invoice;
		const selectedBr = branchs.find(b => b.id === branch);
		const newInvoice = {
			id,
			code,
			client: {
				id: customer,
				name: customer_name,
			},
			date: moment(created_at).format(NORMAL_DATE_FORMAT),
			branch: {
				value: branch,
				label: selectedBr?.name
			},
			tip,
			discount,
			fee: extra_fee,
			promotion,
			total,
			status: payStatusOptions.find(p => p.value === state),
		};

		return newInvoice;
	});
};

export const getNormalFrequency = (interval, unit) => {
	let prefix = interval;
	let suffix = '';

	switch (unit) {
		case DAY:
			suffix = 'ngày';
			break;
		case WEEK:
			suffix = 'tuần';
			break;
		case MONTH:
			suffix = 'tháng';
			break;
		default: 
			break;
	}

	return `${prefix} ${suffix}`;
}

export const getFrequency = (interval, unit) => {
	let prefix = '';
	let suffix = '';

	switch (unit) {
		case DAY:
			suffix = 'ngày';
			break;
		case WEEK:
			suffix = 'tuần';
			break;
		case MONTH:
			suffix = 'tháng';
			break;
		default: 
			break;
	}

	if (interval === 1) {
		prefix = 'Hằng';
	} else {
		prefix = `${interval} ${suffix}`;
		suffix = '1 lần';
	}

	return `${prefix} ${suffix}`;
}

export const getFeedbackIcon = (feedback) => {
	let icon = 'icon-user';
	if (feedback) {
		if (feedback.satisfy_state === 'BAD') icon = 'icon-bad';
		else icon = 'icon-good';
	}
	return icon;
}

export const getRealAmount = (amount, price) => {
	let realAmount = 0;
	if (amount <= 100) realAmount = (amount / 100) * price;
	else realAmount = amount;
	return realAmount;
}

export const repeatFrequencies = [
    {
        value: 0,
		interval: 0,
        label: 'Không lặp lại',
		unit: null,
    },
    ...Array(6).fill(0).map((i, index) => {
        return {
            value: `${index + 1}${DAY}`,
			interval: index + 1,
            label: getFrequency(index + 1, DAY),
            unit: DAY,
        };
    }),
    ...Array(10).fill(0).map((i, index) => {
        return {
            value: `${index + 1}${WEEK}`,
			interval: index + 1,
            label: getFrequency(index + 1, WEEK),
            unit: WEEK,
        };
    }),
    ...Array(11).fill(0).map((i, index) => {
        return {
            value: `${index + 1}${MONTH}`,
			interval: index + 1,
            label: getFrequency(index + 1, MONTH),
            unit: MONTH,
        };
    })
];

export const getAmountOrPercent = (amount) => {
	return amount <= 100 ? `${amount}${amount === 0 ? 'đ' : '%'}` : formatCurrency(amount);
}

export const decodeQueryString = (search) => {
	const newSearch = search.substring(1);
	return JSON.parse('{"' + newSearch.replace(/&/g, '","').replace(/=/g,'":"') + '"}', (key, value) => {
		return key===""?value:decodeURIComponent(value)
	});
}

export const getTimes = (block) => {
	if (Number.isNaN(block)) return [];
	const allTimes = [];
	const minutesInADay = 24 * 60;
	let timeStart = 0;
	for (let i = 0; timeStart < minutesInADay; i++) {
		const hour = Math.floor(timeStart / 60);
		const minutes = timeStart % 60;
		allTimes.push(`${`0${hour}`.slice(-2)}:${`0${minutes}`.slice(-2)}`);
		timeStart += block;
	}
	return allTimes;
};

export const fakeDurations = [
    ...[5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
    ...[65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120],
    ...[135, 150, 165, 180],
    ...[195, 210, 225, 240],
    ...[255, 270, 285, 300],
    ...[315, 330, 345, 360],
    ...[375, 390, 405],
].map((t) => ({
    value: t,
    label: getDuration(t)
}));

export const convertTime = (timeInMinutes) => {
	if (!timeInMinutes) return '';
	const newTimes = parseInt(timeInMinutes, 10);
	const hour = Math.floor(newTimes / 60);
	const minutes = newTimes % 60;

	return `${`0${hour}`.slice(-2)}:${`0${minutes}`.slice(-2)}`;
};
export const convertTimeBack = (timeStr) => {
	const timeParts = (timeStr || '0:0').split(':');
	const hour = parseInt(timeParts[0], 10);
	const minutes = parseInt(timeParts[1], 10);

	return hour * 60 + minutes;
};

export const getGenderByTitle = (title) => {
	const selectedCalling = clientCallings.find((c) => c.value === title);
	return selectedCalling?.gender || null;
}

export const convertFeeAmount = (fees, totalPrice) => {
	const { extra_fee, discount, tip } = fees;
	return {
		extra_fee: extra_fee > 100 ? extra_fee : (extra_fee / 100) * totalPrice,
		discount: discount > 100 ? discount : (discount / 100) * totalPrice,
		tip: tip > 100 ? tip : (tip / 100) * totalPrice,
	}
}

export const getVoucherValue = (voucher) => {
	const { value, type } = voucher;
	let str = '';

	switch (type) {
		case 'PERCENTAGE':
			str += `giảm ${value}%`;
			break;
		case 'MONEY':
			str += `giảm ${formatCurrency(value)}`;
			break;
		case 'GIFT':
			str += `quà tặng ${typeof gift_value === 'number' ? (gift_value > 100 ? formatCurrency(gift_value) : `${gift_value}%`) : gift_value}`;
			break;
		default:
			break;
	}

	return str;
}

export const getPromotionAmount = (amount, promotion) => {
	let value = 0;
	switch (promotion?.type) {
		case 'PERCENTAGE':
			value = amount * (promotion?.value / 100);
			break;
		case 'MONEY':
			value = promotion?.value;
			break;
		default:
			break;
	}
	return value;
}

export const convertNotifications = (notifications, categories) => {
	return notifications.map((n) => {
		const isAppointment = ['Appointment', 'AppointmentItem'].some((i) => i === n.model);
		const selectedCategory = categories.find((c) => c.id === n.category);
		const selectedTitle = notificationTitle.find((t) => t.id === selectedCategory?.name);
		return {
			...n,
			title: selectedTitle?.title || 'Chấm công',
			icon: selectedTitle?.icon || 'icon-calendar-2',
			url: isAppointment ? VIEW_APPOINTMENT_URL.replace(':appointmentId', n.model_id) : '',
		}
	})
}

export const toDataURL = (url) => {
    return fetch(url).then((response) => {
            return response.blob();
        }).then(blob => {
            return URL.createObjectURL(blob);
        });
}

export * from 'utils/mock';
export * from 'utils/window';
export * from 'utils/cookie';