import axios from 'axios';
import {
 authHeaders, GET_COMBOS, GET_COMBO, POST_COMBO, PATCH_COMBO, DELETE_COMBO, PUT_CANCEL_COMBO, POST_USE_COMBO,
} from 'actions';

/** Get combos
 * @param {
 *      customer: string
 *      product: string
 *      branch: string
 *      expired_date: "YYYY-MM-DDTHH:mm:ss.mmmZ"
 *      code: string
 *      state: string
 *      page: int
 *      page_size: int
 * } payload
 */
export const getCombos = (params, token) => axios.get(
        GET_COMBOS,
        params
            ? {
                  params,
                  ...authHeaders(token),
              }
            : authHeaders(token),
    );

/** Get a combo
 * @route {
 *      comboId: uuid
 * }
 */
export const getCombo = (comboId, token) => axios.get(GET_COMBO.replace(':comboId', comboId), authHeaders(token));

/** Add new combo
 * @param {
 *      tenant: uuid,
 *      note: string,
 *      expired_date: "YYYY-MM-DDTHH:mm:ss.mmmZ",
 *      price: int,
 *      interval: int,
 *      usage_time: int,
 *      time_unit: "DAY",
 *      display_product_name: int,
 *      state: "DEFAULT",
 *      customer: uuid,
 *      product: uuid,
 *      branch: uuid,
 * }
 */
export const addCombo = (payload, token) => {
    if (!payload) return null;
    return axios.post(POST_COMBO, payload, authHeaders(token));
};

/** Update combo
 * @param {
 *      tenant: uuid,
 *      note: string,
 *      expired_date: "YYYY-MM-DDTHH:mm:ss.mmmZ",
 *      price: int,
 *      interval: int,
 *      usage_time: int,
 *      time_unit: "DAY",
 *      display_product_name: int,
 *      state: "DEFAULT",
 *      customer: uuid,
 *      product: uuid,
 *      branch: uuid,
 * }
 */
export const updateCombo = (comboId, payload, token) => {
    if (!payload) return null;
    return axios.patch(PATCH_COMBO.replace(':comboId', comboId), payload, authHeaders(token));
};

/** Delete combo
 * @route {
 *      comboId: uuid
 * }
 */
export const deleteCombo = (comboId, token) => axios.delete(DELETE_COMBO.replace(':comboId', comboId), authHeaders(token));

/** Cancel combo
 * @param {
 *      appointment_date: 'YYYY-MM-DD'
 *      start_time: string
 *      main_staffs: uuid[]
 *      assistant_staffs: uuid[]
 * }
 */
export const cancelCombo = (comboId, payload, token) => {
    if (!payload) return null;
    return axios.put(PUT_CANCEL_COMBO.replace(':comboId', comboId), payload, authHeaders(token));
};

/** Use combo
 * @param {
 *      appointment_date: 'YYYY-MM-DD'
 *      start_time: string
 *      main_staffs: uuid[]
 *      assistant_staffs: uuid[]
 * }
 */
export const useCombo = (comboId, payload, token) => {
    if (!payload) return null;
    return axios.put(POST_USE_COMBO.replace(':comboId', comboId), payload, authHeaders(token));
};
