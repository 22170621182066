import axios from 'axios';
import {
    authHeaders,
    GET_CUSTOMERS,
    GET_CUSTOMER,
    POST_CUSTOMER,
    PATCH_CUSTOMER,
    PATCH_CUSTOMERS_RANK,
    POST_CUSTOMER_INTERESTS,
    POST_CUSTOMERS_EXCEL,
    GET_CUSTOMERS_EXCEL,
    POST_ASSIGN_STAFF,
    GET_CUSTOMER_STATISTICS,
    DELETE_CUSTOMER,
} from 'actions';

/** Get customers
 * @param {
 *      rank: string
 *      source: string
 *      phone: string
 *      name: string
 *      customer_group: string
 *      search: string
 *      created_at: string
 *      customer_interests: string
 *      customer_interest_branch: string
 *      customer_interest_created_at: string
 *      ordering: string
 *      branch: string
 *      page: int
 *      page_size: int
 * } payload
 */
export const getCustomers = (params, token) => axios.get(
        GET_CUSTOMERS,
        params
            ? {
                  params,
                  ...authHeaders(token),
              }
            : authHeaders(token),
    );

/** Get a customer
 * @route {
 *      customerId: uuid
 * }
 */
export const getCustomer = (customerId, token) => axios.get(GET_CUSTOMER.replace(':customerId', customerId), authHeaders(token));

/** Get a customer statistics
 * @route {
 *      customerId: uuid
 * }
 */
export const getCustomerStatistics = (customerId, token) => axios.get(GET_CUSTOMER_STATISTICS.replace(':customerId', customerId), authHeaders(token));

/** Add new customer
 * @param {
 *      tenant: uuid,
 *      name: string,
 *      ascii_name: string,
 *      phone: uuid,
 *      email: string,
 *      title: string,
 *      gender: uuid,
 *      address: string,
 *      district: string,
 *      city: uuid,
 *      day_of_birth: int,
 *      month_of_birth: int,
 *      year_of_birth: int,
 *      facebook: string,
 *      is_auto_ranking: boolean,
 *      is_allow_customer_care: boolean,
 *      is_allow_marketing: boolean,
 *      note: uuid,
 *      is_prospective: boolean,
 *      channel_ids: {},
 *      created_by: string,
 *      source: uuid,
 *      rank: uuid,
 *      customer_group: uuid,
 *      career: int,
 *      branches: uuid[]
 * }
 */
export const addCustomer = (payload, token) => {
    if (!payload) return null;
    return axios.post(POST_CUSTOMER, payload, authHeaders(token));
};

/** Update customer info
 * @param {
 *      tenant: uuid,
 *      name: string,
 *      ascii_name: string,
 *      phone: uuid,
 *      email: string,
 *      title: string,
 *      gender: uuid,
 *      address: string,
 *      district: string,
 *      city: uuid,
 *      day_of_birth: int,
 *      month_of_birth: int,
 *      year_of_birth: int,
 *      facebook: string,
 *      is_auto_ranking: boolean,
 *      is_allow_customer_care: boolean,
 *      is_allow_marketing: boolean,
 *      note: uuid,
 *      is_prospective: boolean,
 *      channel_ids: {},
 *      created_by: string,
 *      source: uuid,
 *      rank: uuid,
 *      customer_group: uuid,
 *      career: int,
 *      branches: uuid[]
 * }
 */
export const updateCustomerInfo = (customerId, payload, token, isFormData = false) => {
    if (!payload) return null;
    return axios.patch(PATCH_CUSTOMER.replace(':customerId', customerId), payload, (isFormData ? authHeaders(token, 'multipart/form-data') : authHeaders(token)));
};

/** Update customer rank
 * @param {
 *      rank: uuid,
 *      ids: uuid[]
 * }
 */
export const updateCustomerRank = (payload, token) => {
    if (!payload) return null;
    return axios.patch(PATCH_CUSTOMERS_RANK, payload, authHeaders(token));
};

/** Update customer interests
 * @param {
 *      branch: uuid,
 *      customer_interests: uuid[]
 * }
 */
export const updateCustomerInterests = (customerId, payload, token) => {
    if (!payload) return null;
    return axios.post(POST_CUSTOMER_INTERESTS.replace(':customerId', customerId), payload, authHeaders(token));
};

/** Import customers from Excel
 * @param {
 *      upload_file: string
 * }
 */
export const importCustomersFromExcel = (payload, token) => {
    if (!payload) return null;
    return axios.post(POST_CUSTOMERS_EXCEL, payload, authHeaders(token, 'multipart/form-data'));
};

/** Export customers to Excel
 * @param {
 *      rank: string
 *      source: string
 *      phone: string
 *      name: string
 *      customer_group: string
 *      search: string
 *      created_at: string
 *      customer_interests: string
 *      customer_interest_branch: string
 *      customer_interest_created_at: string
 *      ordering: string
 *      branch: string
 *      page: int
 *      page_size: int
 * } payload
 */
export const exportCustomersToExcel = (params, token) => axios.get(
        GET_CUSTOMERS_EXCEL,
        params
            ? {
                  params,
                  responseType: 'arraybuffer',
                  ...authHeaders(token),
              }
            : authHeaders(token),
    );

/** Assign staff to customer
 * @param {
 *      users: uuid[]
 * }
 */
export const assignStaffToCustomer = (customerId, payload, token) => {
    if (!payload) return null;
    return axios.post(POST_ASSIGN_STAFF.replace(':customerId', customerId), payload, authHeaders(token));
};

/** Delete customer
 * @route {
 *      customerId: uuid
 * }
 */
 export const deleteCustomer = (customerId, token) => axios.delete(DELETE_CUSTOMER.replace(':customerId', customerId), authHeaders(token));
