import axios from 'axios';
import {
 authHeaders, GET_RECEIPTS, GET_RECEIPT, POST_RECEIPT, PATCH_RECEIPT, DELETE_RECEIPT,
} from 'actions';

/** Get receipts
 * @param {
 *      invoice: uuid
 *      user: uuid
 *      customer: uuid
 *      payment_method: string
 *      branch: uuid
 *      reason: string
 *      created_at: string
 *      wallet_action: string
 *      customer_search: string
 *      page: int
 *      page_size: int
 * } payload
 */
export const getReceipts = (params, token) => axios.get(
    GET_RECEIPTS,
    params
        ? {
                params,
                ...authHeaders(token),
            }
        : authHeaders(token),
);

/** Get receipt
 * @route {
 *      receiptId: uuid
 * }
 */
export const getReceipt = (receiptId, token) => axios.get(GET_RECEIPT.replace(':receiptId', receiptId), authHeaders(token));

/** Add new receipt
 * @param {
 *      payment_method: string,
 *      amount: int,
 *      reason: string,
 *      note: string,
 *      deposit_value: int,
 *      invoice: uuid,
 *      user: uuid,
 *      customer: uuid,
 *      branch: uuid,
 * }
 */
export const addReceipt = (payload, token) => {
    if (!payload) return null;
    return axios.post(POST_RECEIPT, payload, authHeaders(token));
};

/** Update receipt
 * @param {
 *      payment_method: string,
 *      amount: int,
 *      reason: string,
 *      note: string,
 *      deposit_value: int,
 *      invoice: uuid,
 *      user: uuid,
 *      customer: uuid,
 *      branch: uuid,
 * }
 */
export const updateReceipt = (receiptId, payload, token) => {
    if (!payload) return null;
    return axios.patch(PATCH_RECEIPT.replace(':receiptId', receiptId), payload, authHeaders(token));
};

/** Delete receipt
 * @route {
 *      receiptId: uuid
 * }
 */
export const deleteReceipt = (receiptId, token) => axios.delete(DELETE_RECEIPT.replace(':receiptId', receiptId), authHeaders(token));
