import { createContext, useContext, useState } from 'react';
import {
 getProfiles, getProfile, addProfile, updateProfile, deleteProfile,
} from 'actions';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';

export const ProfileContext = createContext({} as any);

export const ProfileContextProvider = ({ children }) => {
    const { setProfileLoading, handleException } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [profiles, setProfiles] = useState<any[]>([]);
    const [profile, setProfile] = useState<any>();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalProfiles, setTotalProfiles] = useState(0);

    const doGetProfiles = async (params, callback) => {
        try {
            setProfileLoading(true);

            const response = await getProfiles(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const mediaData = data.results || [];
                setProfiles(mediaData);
                setCurrentPage(params?.page || 1);
                setTotalProfiles(data.count);
                if (callback) callback();
            }

            setProfileLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doGetProfile = async (profileId, callback) => {
        try {
            setProfileLoading(true);

            const response = await getProfile(profileId, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                setProfile(data);
                if (callback) callback();
            }

            setProfileLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doAddProfile = async (payload, callback) => {
        try {
            setProfileLoading(true);

            const response = await addProfile(payload, loggedInUser);
            if (response && response.status === 201) {
                if (callback) callback();
            }

            setProfileLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doUpdateProfile = async (profileId, payload, callback) => {
        try {
            setProfileLoading(true);

            const response = await updateProfile(profileId, payload, loggedInUser);
            if (response && response.status === 200) {
                if (callback) callback();
            }

            setProfileLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doDeleteProfile = async (profileId, callback) => {
        try {
            setProfileLoading(true);

            const response = await deleteProfile(profileId, loggedInUser);
            if (response && response.status === 204) {
                if (callback) callback();
            }

            setProfileLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <ProfileContext.Provider
            value={{
                profiles,
                profile,
                currentPage,
                totalProfiles,
                doGetProfiles,
                doGetProfile,
                doAddProfile,
                doUpdateProfile,
                doDeleteProfile,
            }}
        >
            {children}
        </ProfileContext.Provider>
    );
};
