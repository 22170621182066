import { ADD_APPOINTMENT_PREVIOUS_PAGE, ADD_CLIENT_PREVIOUS_PAGE } from 'actions';
import { useHistory } from 'react-router';
import './index.scss';

export const ModalPage = ({
	className = '', title = '', onCancel, sidebar = null as any, children, footer = null as any,
}) => {
	const history = useHistory();
	return (
		<div className={`spa__modal-page ${className}`}>
			<div className="spa__modal-page--title">
				<button
					className="backBtn"
					type="button"
					onClick={() => {
						localStorage.removeItem(ADD_APPOINTMENT_PREVIOUS_PAGE);
						localStorage.removeItem(ADD_CLIENT_PREVIOUS_PAGE);
						history.goBack();
					}}
				>
					<i className="icon-chevron-left" />
				</button>
				<h1 className="h1 f-bold text-center m-0">{title}</h1>
				<button className="closeBtn" type="button" onClick={onCancel}>
					<i className="icon-remove" />
				</button>
			</div>
			<div className="spa__modal-page--body d-flex">
				<div className="spa__modal-page--body--form">{children}</div>
				{sidebar && <div className="spa__modal-page--body--sidebar">{sidebar}</div>}
			</div>
			{footer && <div className="spa__modal-page--footer d-flex align-items-center justify-content-end">{footer}</div>}
		</div>
	);
};
