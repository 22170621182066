import { createContext, useContext, useState } from 'react';
import { getDailySales } from 'actions';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';

export const StatisticContext = createContext({} as any);

export const StatisticContextProvider = ({ children }) => {
    const { setStatisticLoading, handleException } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [dailySales, setDailySales] = useState<any[]>([]);

    const doGetDailySales = async (params, callback) => {
        try {
            setStatisticLoading(true);

            const response = await getDailySales(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                setDailySales(data || []);
                if (callback) callback();
            }

            setStatisticLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <StatisticContext.Provider
            value={{
                dailySales,
                doGetDailySales,
            }}
        >
            {children}
        </StatisticContext.Provider>
    );
};
