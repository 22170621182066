import axios from 'axios';
import { authHeaders, GET_BRANCHS, GET_BRANCH } from 'actions';

/** Get branchs
 * @param {
 *      page: int
 *      page_size: int
 * } payload
 */
export const getBranchs = (params, token) => axios.get(
    GET_BRANCHS,
    params
        ? {
              params,
              ...authHeaders(token),
          }
        : authHeaders(token),
);

/** Get branch
 * @route {
 *      branchId: uuid
 * }
 */
 export const getBranch = (branchId, token) => axios.get(GET_BRANCH.replace(':branchId', branchId), authHeaders(token));
