import { useEffect, useState } from 'react';
import { Upload, message, Form } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './index.scss';

export const AvatarUpload = ({
 url = '', avatar = '', onChange, type = '', uploadClassName = '', ...rest
}) => {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [fileList, setFileList] = useState<any[]>([]);

    /*eslint-disable */
    useEffect(() => {
        if (avatar) {
            setFileList(old => [
                ...old,
                {
                    uid: old.length,
                    name: avatar,
                    url: avatar,
                },
            ]);
        }
    }, [avatar]);
    /* eslint-enable */

    const uploadButton = <div className={`ant-icon ${loading ? 'loading' : ''}`}>{loading ? <LoadingOutlined /> : <i className="icon-camera" />}</div>;
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const beforeUpload = (file, showErrors = true) => {
        let isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        const isValidType = type && file.type === type;
        if (type) {
            if (!isValidType) {
                if (showErrors) message.error('Định dạng file không hợp lệ!');
                return false;
            }
            isJpgOrPng = true;
        } else if (!isJpgOrPng) {
            if (showErrors) message.error('Ảnh hợp lệ chỉ ở dạng JPG, JPEG hoặc PNG!');
            return false;
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            if (showErrors) message.error('Kích thước file tối đa cho phép là 2MB!');
            return false;
        }
        return isJpgOrPng && isLt2M;
    };
    const handleChange = (info) => {
        setFileList([info.file]);
        if (!type) {
            getBase64(info.file.originFileObj, (image) => {
                setImageUrl(image);
                setLoading(false);
            });
        }
    };
    const previewDefaultAvatar = () => avatar && <img src={avatar} alt="avatar" className="avatar" style={{ width: '100%' }} />;
    return (
        <Form.Item {...rest}>
            <Upload
                listType="picture"
                className={`spa__avatar-uploader ${uploadClassName}`}
                showUploadList={false}
                fileList={fileList}
                action={url}
                beforeUpload={(file) => beforeUpload(file)}
                onChange={handleChange}
                customRequest={(request) => {
                    if (onChange) {
                        onChange(request.file);
                    }
                }}
            >
                {imageUrl ? <img src={imageUrl} alt="avatar" className="avatar" style={{ width: '100%' }} /> : previewDefaultAvatar()}
                {uploadButton}
            </Upload>
        </Form.Item>
    );
};
