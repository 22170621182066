import { Tabs, Spin } from 'antd';

import './index.scss';
import { Drawer } from 'components';
import { useContext, useEffect, useState } from 'react';
import { HomeContext, NotificationCategoryContext, NotificationContext } from 'contexts';
import moment from 'moment';
import { ScrollbarsIf } from 'components/common';
import { DEFAULT_PAGE_SIZE, NOTIFICATIONS_PAGE_SIZE } from 'actions';
import { convertNotifications } from 'utils';
import { useHistory } from 'react-router';

export const Notification = ({ open = false, ...rest }) => {
    const history = useHistory();
    const { notificationLoading } = useContext(HomeContext);
    const { notificationCategories, doGetNotificationCategories } = useContext(NotificationCategoryContext);
    const {
 actionNotifications, newNotifications, doGetNotifications, currentPage, totalNewNotifications, totalActionNotifications,
} = useContext(NotificationContext);
    const [formattedActionNotifications, setFormattedActionNotifications] = useState<any[]>([]);

    /*eslint-disable */
    useEffect(() => {
        if (!notificationCategories.length)
            doGetNotificationCategories({
                page_size: DEFAULT_PAGE_SIZE,
            });
        doGetNotifications({
            page_size: NOTIFICATIONS_PAGE_SIZE,
            type: 'ACTION',
        });
    }, []);

    useEffect(() => {
        if (actionNotifications.length > 0 && notificationCategories.length > 0) {
            setFormattedActionNotifications(convertNotifications(actionNotifications, notificationCategories));
        }
    }, [notificationCategories, actionNotifications]);
    /* eslint-enable */

    const handleViewMore = (e) => {
        e.preventDefault();
        doGetNotifications({
            page: currentPage + 1,
            page_size: NOTIFICATIONS_PAGE_SIZE,
        });
    };

    const handleTabChange = (tab) => {
        switch (tab) {
            case '1':
                if (!actionNotifications.length) {
                    doGetNotifications({
                        page_size: NOTIFICATIONS_PAGE_SIZE,
                        type: 'ACTION',
                    });
                }
                break;
            case '2':
                if (!newNotifications.length) {
                    doGetNotifications({
                        page_size: NOTIFICATIONS_PAGE_SIZE,
                        type: 'NEWS',
                    });
                }
                break;
            default:
                break;
        }
    };
    return (
        <Drawer
            className="spa__notification"
            open={open}
            title="Thông báo"
            afterVisibleChange={(visible) => {
                if (visible) {
                    doGetNotifications(
                        {
                            page_size: NOTIFICATIONS_PAGE_SIZE,
                        },
                        null,
                        true,
                    );
                }
            }}
            {...rest}
        >
            <Tabs defaultActiveKey="1" onTabClick={handleTabChange}>
                <Tabs.TabPane tab="Hoạt động" key="1">
                    <ScrollbarsIf>
                        {notificationLoading && !formattedActionNotifications.length ? (
                            <div className="p-3 d-flex align-items-center justify-content-center">
                                <Spin spinning={notificationLoading} />
                            </div>
                        ) : formattedActionNotifications.length > 0 ? (
                            <>
                                <div className="spa__notification--activities">
                                    {formattedActionNotifications.map((n) => (
                                        <button
                                            type="button"
                                            className="spa__notification--activities--item"
                                            key={`${n.id}-${n.category}`}
                                            style={{ cursor: n.url ? 'pointer' : 'default' }}
                                            onClick={() => {
                                                if (n.url) {
                                                    history.push(n.url);
                                                }
                                            }}
                                        >
                                            <i className={n.icon} />
                                            <div className="content">
                                                <h5 className="h5 f-medium d-flex align-items-center justify-content-between">
                                                    <span>{n.title}</span>
                                                    <small>{moment(n.created_at).fromNow()}</small>
                                                </h5>
                                                <p>{n.content}</p>
                                            </div>
                                        </button>
                                    ))}
                                </div>
                                <div className={`${notificationLoading ? 'p-3' : ''} d-flex align-items-center justify-content-center`}>
                                    <Spin spinning={notificationLoading} />
                                </div>
                                {totalActionNotifications > actionNotifications.length && (
                                    <div className="p-3 text-center">
                                        <a href="/" onClick={handleViewMore} className="spa__link">
                                            Xem thêm
                                        </a>
                                    </div>
                                )}
                            </>
                        ) : (
                            <p className="p-3 text-center">Chưa có thông báo nào</p>
                        )}
                    </ScrollbarsIf>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Tin tức" key="2">
                    <ScrollbarsIf>
                        {notificationLoading && !newNotifications.length ? (
                            <div className="p-3 d-flex align-items-center justify-content-center">
                                <Spin spinning={notificationLoading} />
                            </div>
                        ) : newNotifications.length > 0 ? (
                            <>
                                <div className="spa__notification--news">
                                    {newNotifications.map((n) => (
                                        <a href={n.data?.data?.object} target="_blank" className="spa__notification--news--item" key={`${n.id}-${n.category}`}>
                                            <i className="icon-home" />
                                            <div className="content">
                                                <h5 className="h5 f-medium">{n.data?.body}</h5>
                                                <p>{n.description}</p>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <a className="spa__link" href={n.data?.data?.object} target="_blank">
                                                        Xem chi tiết
                                                    </a>
                                                    <small>{moment(n.created_at).fromNow()}</small>
                                                </div>
                                            </div>
                                        </a>
                                    ))}
                                </div>
                                <div className={`${notificationLoading ? 'p-3' : ''} d-flex align-items-center justify-content-center`}>
                                    <Spin spinning={notificationLoading} />
                                </div>
                                {totalNewNotifications > newNotifications.length && (
                                    <div className="p-3 text-center">
                                        <a href="/" onClick={handleViewMore} className="spa__link">
                                            Xem thêm
                                        </a>
                                    </div>
                                )}
                            </>
                        ) : (
                            <p className="p-3 text-center">Chưa có tin tức nào</p>
                        )}
                    </ScrollbarsIf>
                </Tabs.TabPane>
            </Tabs>
        </Drawer>
    );
};
