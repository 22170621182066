import axios from 'axios';
import {
 authHeaders, GET_INVOICE_ITEMS,
} from 'actions';

/** Get invoice items
 * @param {
 *      invoice: uuid
 *      created_at: string
 *      customer: uuid
 *      product_type: 'PRODUCT' | 'SINGLE_THERAPY'
 *      page: int
 *      page_size: int
 * } payload
 */
export const getInvoiceItems = (params, token) => axios.get(
        GET_INVOICE_ITEMS,
        params
        ? {
                params,
                ...authHeaders(token),
            }
        : authHeaders(token),
);
