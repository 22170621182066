import { Form, Select as AntSelect } from 'antd';
import './index.scss';

export const Select = ({
	className = '', name, rules = [] as any, label = '', children = null as any, note = '', ...rest
}) => (
	<div className={`spa__select ${className}`}>
		<Form.Item className={className} name={name} rules={rules} label={label}>
			<AntSelect {...rest} suffixIcon={<i className="icon-chevron-down" />}>
				{children}
			</AntSelect>
		</Form.Item>
		{note && <small>{note}</small>}
	</div>
);
