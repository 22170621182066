import { createContext, useContext, useState } from 'react';
import { getBranchs, getBranch } from 'actions';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';

export const BranchContext = createContext({} as any);

export const BranchContextProvider = ({ children }) => {
    const { setBranchLoading, handleException } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [branchs, setBranchs] = useState<any[]>([]);
    const [branch, setBranch] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalBranchs, setTotalBranchs] = useState(0);

    const doGetBranchs = async (params, callback) => {
        try {
            setBranchLoading(true);

            const response = await getBranchs(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const branchData = data.results || [];
                setBranchs(branchData);
                setCurrentPage(params?.page || 1);
                setTotalBranchs(data.count);
                if (callback) callback(branchData);
            }

            setBranchLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doGetBranch = async (branchId, callback) => {
        try {
            setBranchLoading(true);

            const response = await getBranch(branchId, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                setBranch(data);
                if (callback) callback();
            }

            setBranchLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <BranchContext.Provider
            value={{
                branchs,
                branch,
                currentPage,
                totalBranchs,
                setBranch,
                doGetBranchs,
                doGetBranch,
            }}
        >
            {children}
        </BranchContext.Provider>
    );
};
