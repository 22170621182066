import { Upload, message } from 'antd';

import './index.scss';
import { useContext, useEffect, useState } from 'react';
import { HomeContext, MediaContext } from 'contexts';

export const PicturesUpload = ({
 clientId, url = '', onChange, onRemove, ...rest
}) => {
    const [fileList, setFileList] = useState<any>([]);
    const { mediaLoading } = useContext(HomeContext);
    const { doGetMedias, medias } = useContext(MediaContext);
    /*eslint-disable */
    useEffect(() => {
        if (clientId) {
            doGetMedias({
                customer: clientId
            });
        }
    }, [clientId])
    useEffect(() => {
        setFileList(medias.map(m => {
            const { id, url } = m;
            return {
                uid: id,
                name: id,
                url,
            }
        }))
    }, [medias])
    /* eslint-enable */
    const uploadButton = (
        <div>
            <i className="icon-camera" />
        </div>
    );
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Ảnh hợp lệ chỉ ở dạng JPG, JPEG hoặc PNG!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Kích thước ảnh tối đa cho phép là 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    return !mediaLoading ? (
        <Upload
            name="pictures"
            listType="picture-card"
            className="spa__pictures-uploader"
            showUploadList={{
                showRemoveIcon: true,
                showDownloadIcon: false,
            }}
            fileList={fileList}
            action={url}
            beforeUpload={beforeUpload}
            onRemove={(file) => {
                if (onRemove) onRemove(file);
            }}
            customRequest={(request) => {
                if (onChange) onChange(request.file);
            }}
            {...rest}
        >
            {uploadButton}
        </Upload>
    ) : <></>;
};
