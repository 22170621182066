import {
 createContext, useContext, useEffect, useState,
} from 'react';
import {
 getReceipts, getReceipt, addReceipt, updateReceipt, deleteReceipt,
} from 'actions';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';
import { UserContext } from './UserContext';

export const ReceiptContext = createContext({} as any);

export const ReceiptContextProvider = ({ children }) => {
    const { setReceiptLoading, handleException } = useContext(HomeContext);
    const { user, doGetUser } = useContext(UserContext);
    const { loggedInUser } = useContext(AuthContext);
    const [receipts, setReceipts] = useState<any[]>([]);
    const [receipt, setReceipt] = useState<any>();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalReceipts, setTotalReceipts] = useState(0);

    /*eslint-disable */
    useEffect(() => {
        if (receipt && ((user && user.id !== receipt.user) || !user)) {
            doGetUser(receipt.user);
        }
    }, [receipt])
    /* eslint-enable */

    const doGetReceipts = async (params, callback) => {
        try {
            setReceiptLoading(true);

            const response = await getReceipts(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const mediaData = data.results || [];
                setReceipts(mediaData);
                setCurrentPage(params?.page || 1);
                setTotalReceipts(data.count);
                if (callback) callback();
            }

            setReceiptLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doGetReceipt = async (receiptId, callback) => {
        try {
            setReceiptLoading(true);

            const response = await getReceipt(receiptId, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                setReceipt(data);
                if (callback) callback();
            }

            setReceiptLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doAddReceipt = async (payload, callback) => {
        try {
            setReceiptLoading(true);

            const response = await addReceipt(payload, loggedInUser);
            if (response && response.status === 201) {
                setReceipts((old) => [...old, response.data]);
                setReceipt(response.data);
                if (callback) callback();
            }

            setReceiptLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doUpdateReceipt = async (receiptId, payload, callback) => {
        try {
            setReceiptLoading(true);

            const response = await updateReceipt(receiptId, payload, loggedInUser);
            if (response && response.status === 200) {
                const rc = response.data;
                setReceipts((old) => old.map((o) => (o.id === rc.id ? rc : o)));
                setReceipt(rc);
                if (callback) callback();
            }

            setReceiptLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doDeleteReceipt = async (receiptId, callback) => {
        try {
            setReceiptLoading(true);

            const response = await deleteReceipt(receiptId, loggedInUser);
            if (response && response.status === 204) {
                if (callback) callback();
            }

            setReceiptLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <ReceiptContext.Provider
            value={{
                receipts,
                receipt,
                currentPage,
                totalReceipts,
                setReceipt,
                doGetReceipts,
                doGetReceipt,
                doAddReceipt,
                doUpdateReceipt,
                doDeleteReceipt,
            }}
        >
            {children}
        </ReceiptContext.Provider>
    );
};
