import { Form, InputNumber as AntInputNumber } from 'antd';

import './index.scss';

export const InputNumber = ({
	className = '', name, rules = [] as any, label = '', suffix = 'đ', ...rest
}) => (
	<div className={`spa__input-number ${className}`}>
		<Form.Item name={name} rules={rules} label={label}>
			<AntInputNumber {...rest} />
		</Form.Item>
		<div className="suffix">
			{suffix && <p>{suffix}</p>}
		</div>
	</div>
);
