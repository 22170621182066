import axios from 'axios';
import { authHeaders, GET_NOTIFICATIONS, GET_NOTIFICATION } from 'actions';

/** Get notifications
 * @param {
 *      type: string
 *      page: int
 *      page_size: int
 * } payload
 */
export const getNotifications = (params, token) => axios.get(
        GET_NOTIFICATIONS,
        params
            ? {
                  params,
                  ...authHeaders(token),
              }
            : authHeaders(token),
    );

/** Get notification
 * @route {
 *      notificationId: uuid
 * }
 */
export const getNotification = (notificationId, token) => axios.get(GET_NOTIFICATION.replace(':notificationId', notificationId), authHeaders(token));
