import { createContext, useContext, useState } from 'react';
import {
 getAppointments, getAppointment, addAppointment, addAppointmentNew, updateAppointment, deleteAppointment, checkoutAppointment, addExtraComboAppointment,
} from 'actions';
import moment from 'moment';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';

export const AppointmentContext = createContext({} as any);

export const AppointmentContextProvider = ({ children }) => {
    const { setAppointmentLoading, handleException } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [appointments, setAppointments] = useState<any[]>([]);
    const [featureAppointments, setFeatureAppointments] = useState<any[]>([]);
    const [pastAppointments, setPastAppointments] = useState<any[]>([]);
    const [appointment, setAppointment] = useState<any>();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalAppointments, setTotalAppointments] = useState(0);

    const doGetAppointments = async (params, callback, categorized = false, orderByCreatedDate = false, isExistingApptsOnly = false) => {
        try {
            setAppointmentLoading(true);

            const response = await getAppointments({
                ...params,
                ordering: params?.ordering || (orderByCreatedDate ? '-created_at' : '-appointment_date'),
            }, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const apptData = data.results || [];
                if (isExistingApptsOnly) {
                    if (callback) {
                        callback({
                            count: data.count || 0,
                            rootAppointment: apptData[0],
                        });
                    }
                } else {
                    setAppointments(apptData);
                    setCurrentPage(params?.page || 1);
                    setTotalAppointments(data.count);
                    if (categorized) {
                        const feature: any[] = [];
                        const past: any[] = [];
                        apptData.forEach((c) => {
                            if (moment(`${c.appointment_date} ${c.start_time}`).diff(moment()) >= 0) feature.push(c);
                            else past.push(c);
                        });

                        setFeatureAppointments(feature);
                        setPastAppointments(past);
                    }
                    if (callback) callback();
                }
            }

            setAppointmentLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doGetAppointment = async (appointmentId, callback) => {
        try {
            setAppointmentLoading(true);

            const response = await getAppointment(appointmentId, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                setAppointment(data);
                if (callback) callback(data);
            }

            setAppointmentLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doAddAppointment = async (payload, callback) => {
        try {
            setAppointmentLoading(true);

            const response = await addAppointment(payload, loggedInUser);
            if (response && response.status === 201) {
                if (callback) callback(response.data);
            }

            setAppointmentLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doAddAppointmentNew = async (appointmentId, payload, callback) => {
        try {
            setAppointmentLoading(true);

            const response = await addAppointmentNew(appointmentId, payload, loggedInUser);
            if (response && response.status === 201) {
                if (callback) callback(response.data);
            }

            setAppointmentLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doUpdateAppointment = async (appointmentId, payload, callback) => {
        try {
            setAppointmentLoading(true);

            const response = await updateAppointment(appointmentId, payload, loggedInUser);
            if (response && response.status === 200) {
                setAppointment(response.data);
                if (callback) callback(response.data);
            }

            setAppointmentLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doDeleteAppointment = async (appointmentId, callback) => {
        try {
            setAppointmentLoading(true);

            const response = await deleteAppointment(appointmentId, loggedInUser);
            if (response && response.status === 204) {
                if (callback) callback();
            }

            setAppointmentLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doCheckoutAppointment = async (appointmentId, payload, callback) => {
        try {
            setAppointmentLoading(true);

            const response = await checkoutAppointment(appointmentId, payload, loggedInUser);
            if (response && response.status === 200) {
                if (callback) callback(response.data);
            }

            setAppointmentLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doAddExtraComboAppointment = async (payload, callback) => {
        try {
            setAppointmentLoading(true);

            const response = await addExtraComboAppointment(payload, loggedInUser);
            if (response && response.status === 201) {
                if (callback) callback(response.data);
            }

            setAppointmentLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <AppointmentContext.Provider
            value={{
                appointments,
                featureAppointments,
                pastAppointments,
                appointment,
                currentPage,
                totalAppointments,
                setAppointment,
                doGetAppointments,
                doGetAppointment,
                doAddAppointment,
                doAddAppointmentNew,
                doUpdateAppointment,
                doDeleteAppointment,
                doCheckoutAppointment,
                doAddExtraComboAppointment,
            }}
        >
            {children}
        </AppointmentContext.Provider>
    );
};
