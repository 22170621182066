import './index.scss';

export const Footer = () => (
	<div className="spa__footer text-center">
		<a className="spa__link" href="https://spago.vn" target="_blank">
			Cần hỗ trợ?
		</a>
		<p className="mt-2">&copy; 2021 SPAGO. Phần mềm quản lý SPA</p>
	</div>
);
