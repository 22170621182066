import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'styles/index.scss';
import 'react-toastify/dist/ReactToastify.css';

import {
 Suspense, useContext, useEffect, useState,
} from 'react';
import ReactDOM from 'react-dom';
import {
 Router, withRouter, Switch, Route, Redirect,
} from 'react-router-dom';
import { ConfigProvider, Alert } from 'antd';
import viVN from 'antd/lib/locale/vi_VN';
import 'moment/locale/vi';
import { createBrowserHistory } from 'history';
import { delayImport, getToken } from 'utils';
import {
 NOT_FOUND_URL, ACCOUNT_URL, ROOT_URL, PUSH_API,
} from 'actions';
import { Spinner, Modal, Button } from 'components';
import { AuthContext, HomeContextProvider, UserContext } from 'contexts';
import notificationLogo from 'assets/images/favicon.png';
import { toast, ToastContainer } from 'react-toastify';
import { getFirebaseToken, onMessageListener } from './firebase';

const HomeLayout = delayImport(import('layouts/HomeLayout'));
const NotFound = delayImport(import('pages/NotFound'));
const AuthenticationLayout = delayImport(import('layouts/AuthenticationLayout'));

const customHistory = createBrowserHistory();

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            const accessToken = getToken();

            return accessToken ? <Component {...props} /> : <Redirect to={ACCOUNT_URL} />;
        }}
    />
);

const LoggedInRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            const accessToken = getToken();

            return accessToken ? <Redirect to={ROOT_URL} /> : <Component {...props} />;
        }}
    />
);

const App = () => {
    const { loggedInUser } = useContext(AuthContext);
    const { doRegisterDevice } = useContext(UserContext);
    const [isTokenFound, setTokenFound] = useState(false);
    const [notificationSupport, setNotificationSupport] = useState<any>(true);
    const [closed, setClosed] = useState(false);

    const [newModal, setNewModal] = useState<boolean>(false);
    const [selectedNew, setSelectedNew] = useState<any>();

    /*eslint-disable */
    useEffect(() => {
        const pushSupported = localStorage.getItem(PUSH_API);
        setNotificationSupport(pushSupported === 'true');
    }, []);
    useEffect(() => {
        if (loggedInUser) {
            getFirebaseToken(setTokenFound, doRegisterDevice);
            onNotification();
        }
    }, [loggedInUser]);
    useEffect(() => {
        setNewModal(!!selectedNew);
    }, [selectedNew]);
    /* eslint-enable */

    const onNotification = () => {
        onMessageListener()
            .then((payload) => {
                const { notification, data } = payload;
                const isNews = data?.action === 'news' || data?.action === 'cms_news';
                if (isNews) {
                    setSelectedNew({
                        ...notification,
                        url: data?.object,
                    });
                } else {
                    toast(
                        <div className="spa__toast">
                            <img src={notificationLogo} alt="SPAGO" />
                            <div className="spa__toast--content pl-3">
                                <h4 className="h4 f-medium mb-2">{notification.title}</h4>
                                <p className="m-0">{notification.body}</p>
                            </div>
                        </div>,
                    );
                }
                console.log(payload);
                onNotification();
            })
            .catch((err) => console.log(`failed: ${err}`));
    };

    return (
        <>
            {!isTokenFound && loggedInUser && notificationSupport && !closed && <Alert className="spa__notify" message={notificationSupport ? 'Thông báo đã bị tắt. Vui lòng cho phép hiện thông báo' : 'Trình duyệt không hỗ trợ thông báo'} type="warning" showIcon closable onClose={() => setClosed(true)} />}
            <ToastContainer autoClose={5000} position="top-right" hideProgressBar newestOnTop style={{ width: '400px' }} />
            <ConfigProvider locale={viVN}>
                <Switch>
                    <Route path={NOT_FOUND_URL} component={NotFound} />
                    <LoggedInRoute path={ACCOUNT_URL} component={AuthenticationLayout} />
                    <PrivateRoute path={ROOT_URL} component={HomeLayout} />
                </Switch>
            </ConfigProvider>
            <Modal onCancel={() => setNewModal(false)} footer={null} visible={newModal}>
                <div className="spa__news">
                    <h2 className="h2 f-bold text-center pt-2 mb-3">Tin mới</h2>
                    <h3 className="h3 f-regular pt-2 mb-3">{selectedNew?.body}</h3>
                    <Button
                        type="primary"
                        onClick={() => {
                            if (window) window.open(selectedNew?.url);
                        }}
                    >
                        Xem chi tiết
                    </Button>
                </div>
            </Modal>
        </>
    );
};

const AppRootWithRouter = withRouter(App);

const Root = () => (
    <Suspense fallback={<Spinner />}>
        <HomeContextProvider>
            <Router history={customHistory}>
                <AppRootWithRouter />
            </Router>
        </HomeContextProvider>
    </Suspense>
);

ReactDOM.render(<Root />, document.getElementById('root'));
