import axios from 'axios';
import { authHeaders, GET_DAILY_SALES } from 'actions';

/** Get daily sales
 * @param {
 *      day: int
 *      month: int
 *      year: int
 *      tenant: uuid
 *      branch: uuid
 * } payload
 */
export const getDailySales = (params, token) => axios.get(
        GET_DAILY_SALES,
        params
            ? {
                  params,
                  ...authHeaders(token),
              }
            : authHeaders(token),
    );
