import axios from 'axios';
import { authHeaders, GET_FEEDBACK_CATEGORIES } from 'actions';

/** Get feeback categories
 * @param {
 *      page: int
 *      page_size: int
 * } payload
 */
export const getFeedbackCategories = (params, token) => axios.get(
        GET_FEEDBACK_CATEGORIES,
        params
            ? {
                  params,
                  ...authHeaders(token),
              }
            : authHeaders(token),
    );
