import axios from 'axios';
import {
 authHeaders, GET_STAFFS, GET_STAFF, POST_REGISTER_DEVICE,
} from 'actions';

/** Get users
 * @param {
 *      name: string
 *      phone: string
 *      email: string
 *      role: string
 *      shift: string
 *      appointment_allowed: string
 *      ordering: string
 *      search: string
 *      page: int
 *      page_size: int
 * } payload
 */
export const getUsers = (params, token) => axios.get(
        GET_STAFFS,
        params
            ? {
                  params,
                  ...authHeaders(token),
              }
            : authHeaders(token),
    );

/** Get user
 */
export const getUser = (userId, token) => axios.get(GET_STAFF.replace(':userId', userId), authHeaders(token));

/** Register device
 * @param {
 *      os: string,
 *      token: string,
 *      is_active: boolean
 * }
 */
export const postRegisterDevice = (payload, token) => {
    if (!payload) return null;
    return axios.post(POST_REGISTER_DEVICE, payload, authHeaders(token));
};
