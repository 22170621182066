import {
    createContext, useContext, useState,
} from 'react';
import { getPromotions, getPromotion } from 'actions';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';

export const PromotionContext = createContext({} as any);

export const PromotionContextProvider = ({ children }) => {
    const { setPromotionLoading, handleException } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [promotions, setPromotions] = useState<any[]>([]);
    const [promotion, setPromotion] = useState<any>();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPromotions, setTotalPromotions] = useState(0);

    const doGetPromotions = async (params, callback) => {
        try {
            setPromotionLoading(true);

            const response = await getPromotions(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const promotionData = data.results || [];
                setPromotions(promotionData);
                setCurrentPage(params?.page || 1);
                setTotalPromotions(data.count);
                if (callback) callback(promotionData);
            }

            setPromotionLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doGetPromotion = async (promotionId, callback) => {
        try {
            setPromotionLoading(true);

            const response = await getPromotion(promotionId, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                setPromotion(data);
                if (callback) callback();
            }

            setPromotionLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <PromotionContext.Provider
            value={{
                promotions,
                promotion,
                currentPage,
                totalPromotions,
                doGetPromotions,
                doGetPromotion,
            }}
        >
            {children}
        </PromotionContext.Provider>
    );
};
