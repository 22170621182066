import axios from 'axios';
import { authHeaders, GET_CALLS_HISTORY, GET_CALL_HISTORY } from 'actions';

/** Get calls history
 * @param {
 *      user: uuid
 *      hangup_cause: string
 *      customer_id: uuid
 *      unknown_customer: string
 *      direction: string
 *      search: string
 *      start_datetime: string
 *      page: int
 *      page_size: int
 * } payload
 */
export const getCalls = (params, token) => axios.get(
        GET_CALLS_HISTORY,
        params
            ? {
                  params,
                  ...authHeaders(token),
              }
            : authHeaders(token),
    );

/** Get call history
 * @route {
 *      callId: uuid
 * }
 */
export const getCall = (callId, token) => axios.get(GET_CALL_HISTORY.replace(':callId', callId), authHeaders(token));
