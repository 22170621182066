import {
 createContext, useContext, useEffect, useState,
} from 'react';
import {
 ACCESS_TOKEN, login, getUserInfo, updateUser, changePassword,
} from 'actions';
import { getToken } from 'utils';
import { HomeContext } from './HomeContext';
import { TenantContextProvider } from './TenantContext';
import { PromotionContextProvider } from './PromotionContext';
import { PrinterContextProvider } from './PrinterContext';

export const AuthContext = createContext({} as any);

export const AuthContextProvider = ({ children }) => {
    const {
 setLoading, setAnotherLoading, setUserInfoLoading, handleException,
} = useContext(HomeContext);
    const [loggedInUser, setLoggedInUser] = useState<any>();
    const [userInfo, setUserInfo] = useState<any>();

    /*eslint-disable */
    useEffect(() => {
        setLoggedInUser(getToken());
    }, []);
    /* eslint-enable */

    const doLogin = async (payload: any, callback: any) => {
        try {
            setLoading(true);

            const response = await login(payload);
            if (response && response.status === 200) {
                const { token } = response.data;
                setLoggedInUser(token);
                localStorage.setItem(ACCESS_TOKEN, token);
                if (callback) callback();
            }

            setLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doGetUserInfo = async (callback) => {
        try {
            setUserInfoLoading(true);

            const response = await getUserInfo(loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                setUserInfo(data);
                if (callback) callback(data);
            }

            setUserInfoLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doUpdateUser = async (payload, callback) => {
        if (!userInfo) return;
        try {
            setLoading(true);

            const response = await updateUser(userInfo?.id, payload, loggedInUser);
            if (response && response.status === 200) {
                if (callback) callback();
            }

            setLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doChangePassword = async (payload, callback) => {
        if (!userInfo) return;
        try {
            setAnotherLoading(true);

            const response = await changePassword(payload, loggedInUser);
            if (response && response.status === 200) {
                if (callback) callback();
            }

            setAnotherLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <AuthContext.Provider
            value={{
                loggedInUser,
                userInfo,
                doLogin,
                doGetUserInfo,
                doUpdateUser,
                doChangePassword,
            }}
        >
            <TenantContextProvider>
                <PromotionContextProvider>
                    <PrinterContextProvider>
                        {children}
                    </PrinterContextProvider>
                </PromotionContextProvider>
            </TenantContextProvider>
        </AuthContext.Provider>
    );
};
