import { createContext, useContext, useState } from 'react';
import {
 getCombos, getCombo, addCombo, updateCombo, deleteCombo, cancelCombo, useCombo as actionUseCombo,
} from 'actions';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';

export const ComboContext = createContext({} as any);

export const ComboContextProvider = ({ children }) => {
    const { setComboLoading, handleException } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [combos, setCombos] = useState<any[]>([]);
    const [combo, setCombo] = useState<any>();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCombos, setTotalCombos] = useState(0);

    const doGetCombos = async (params, callback) => {
        try {
            setComboLoading(true);

            const response = await getCombos(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const customerData = data.results || [];
                setCombos(customerData);
                setCurrentPage(params?.page || 1);
                setTotalCombos(data.count);
                if (callback) callback();
            }

            setComboLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doGetCombo = async (comboId, callback) => {
        try {
            setComboLoading(true);

            const response = await getCombo(comboId, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                setCombo(data);
                if (callback) callback();
            }

            setComboLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doAddCombo = async (payload, callback) => {
        try {
            setComboLoading(true);

            const response = await addCombo(payload, loggedInUser);
            if (response && response.status === 201) {
                if (callback) callback(response.data);
            }

            setComboLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doUpdateCombo = async (comboId, payload, callback) => {
        try {
            setComboLoading(true);

            const response = await updateCombo(comboId, payload, loggedInUser);
            if (response && response.status === 200) {
                if (callback) callback();
            }

            setComboLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doDeleteCombo = async (comboId, callback) => {
        try {
            setComboLoading(true);

            const response = await deleteCombo(comboId, loggedInUser);
            if (response && response.status === 204) {
                if (callback) callback();
            }

            setComboLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doCancelCombo = async (comboId, payload, callback) => {
        try {
            setComboLoading(true);

            const response = await cancelCombo(comboId, payload, loggedInUser);
            if (response && response.status === 204) {
                if (callback) callback();
            }

            setComboLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doUseCombo = async (comboId, payload, callback) => {
        try {
            setComboLoading(true);

            const response = await actionUseCombo(comboId, payload, loggedInUser);
            if (response && response.status === 201) {
                if (callback) callback();
            }

            setComboLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <ComboContext.Provider
            value={{
                combos,
                combo,
                currentPage,
                totalCombos,
                setCombo,
                doGetCombos,
                doGetCombo,
                doAddCombo,
                doUpdateCombo,
                doDeleteCombo,
                doCancelCombo,
                doUseCombo,
            }}
        >
            {children}
        </ComboContext.Provider>
    );
};
