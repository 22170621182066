import { createContext, useContext, useState } from 'react';
import { getNotifications, getNotification, ACCESS_TOKEN } from 'actions';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';

export const NotificationContext = createContext({} as any);

export const NotificationContextProvider = ({ children }) => {
    const { setNotificationLoading, handleException } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [newNotifications, setNewNotifications] = useState<any[]>([]);
    const [actionNotifications, setActionNotifications] = useState<any[]>([]);
    const [notification, setNotification] = useState<any>();
    const [currentActionPage, setCurrentActionPage] = useState(1);
    const [currentNewPage, setCurrentNewPage] = useState(1);
    const [totalActionNotifications, setTotalActionNotifications] = useState(0);
    const [totalNewNotifications, setTotalNewNotifications] = useState(0);

    const doGetNotifications = async (params, callback, refresh = false) => {
        try {
            setNotificationLoading(true);
            const token = loggedInUser || localStorage.getItem(ACCESS_TOKEN);
            if (!token) {
                setNotificationLoading(false);
                return;
            }
            const response = await getNotifications(params, token);
            const { data, status } = response;

            if (status === 200) {
                const notificationData = data.results || [];
                if (params?.type === 'NEWS') {
                    setCurrentNewPage(params?.page || 1);
                    setNewNotifications((old) => (refresh ? notificationData : [...old, ...notificationData]));
                    setTotalNewNotifications(data.count);
                }
                if (params?.type === 'ACTION') {
                    setCurrentActionPage(params?.page || 1);
                    setActionNotifications((old) => (refresh ? notificationData : [...old, ...notificationData]));
                    setTotalActionNotifications(data.count);
                }
                if (callback) callback();
            }

            setNotificationLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doGetNotification = async (notificationId, callback) => {
        try {
            setNotificationLoading(true);

            const response = await getNotification(notificationId, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                setNotification(data);
                if (callback) callback();
            }

            setNotificationLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <NotificationContext.Provider
            value={{
                actionNotifications,
                newNotifications,
                notification,
                currentActionPage,
                currentNewPage,
                totalActionNotifications,
                totalNewNotifications,
                doGetNotifications,
                doGetNotification,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};
