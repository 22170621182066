import { useHistory } from 'react-router';
import { Dropdown, Menu, Form } from 'antd';
import moment from 'moment';

import './index.scss';
import { Select, Button, DatePicker } from 'components';
import { fakeStatusOptions, fakeDateTypeOptions, allOption } from 'utils';
import {
 ADD_APPOINTMENT_URL, DEFAULT_PAGE_SIZE, KEY_BRANCH, KEY_DATE, KEY_DATE_TYPE, KEY_STATUS, KEY_USER, PRODUCT_CHECKOUT_URL, SELECTED_CLIENT, SHORT_DATE_FORMAT,
} from 'actions';
import { useContext, useState, useEffect } from 'react';
import {
 AppointmentContext, BranchContext, HomeContext, UserContext,
} from 'contexts';
import { CalendarMode } from '../AppointmentCalendar';

export const AppointmentFilter = ({ onFiltersUpdate, setDayMode }) => {
    const history = useHistory();
    const { branchLoading, userLoading } = useContext(HomeContext);
    const { doGetAppointments } = useContext(AppointmentContext);
    const { branchs, doGetBranchs } = useContext(BranchContext);
    const { users, doGetUsers } = useContext(UserContext);
    const today = moment();
    const [selectedStaff, setSelectedStaff] = useState<any>(allOption.value);
    const [dateFilter, setDateFilter] = useState<any>();
    const [selectedBranch, setSelectedBranch] = useState<any>(allOption.value);
    const [selectedState, setSelectedState] = useState<any>(allOption.value);
    const [selectedDateType, setSelectedDateType] = useState<any>(fakeDateTypeOptions[0].value);
    const [hoverDate, setHoverDate] = useState();
    const [appointmentFilterForm] = Form.useForm();
    /*eslint-disable */
    useEffect(() => {
        doGetBranchs({
            page_size: DEFAULT_PAGE_SIZE,
        });
    }, []);
    useEffect(() => {
        doGetUsers({
            branch: !selectedBranch ? null : selectedBranch,
        });
        setDateFilter(dateFilter || moment());
    }, [selectedBranch]);
    useEffect(() => {
        appointmentFilterForm.resetFields();
    }, [selectedBranch, selectedStaff, selectedDateType, dateFilter]);
    useEffect(() => {
        if (dateFilter) {
            appointmentFilterForm.resetFields();
            handleFilter(KEY_DATE, dateFilter);
        }
    }, [dateFilter]);
    /* eslint-enable */
    const handleCustomize = (item) => {
        switch (item.key) {
            case 'appointment': {
                const data = {
                    branch: selectedBranch,
                    startDate: dateFilter.format(SHORT_DATE_FORMAT),
                };
                if (!selectedBranch) delete data.branch;
                localStorage.removeItem(SELECTED_CLIENT);
                history.push(`${ADD_APPOINTMENT_URL}?${new URLSearchParams(data).toString()}`);
                break;
            }
            case 'sale':
                history.push(PRODUCT_CHECKOUT_URL);
                break;
            default:
                break;
        }
    };
    const handleCheckDateType = (type) => {
        const params = {
            appointment_date: null,
            appointment_date_before: null,
            appointment_date_after: null,
        };
        if (type === 'day') {
            params.appointment_date_before = (dateFilter ? moment(dateFilter).format(SHORT_DATE_FORMAT) : null) as any;
            params.appointment_date_after = (dateFilter ? moment(dateFilter).format(SHORT_DATE_FORMAT) : null) as any;
            // params.appointment_date = (dateFilter ? moment(dateFilter).format(SHORT_DATE_FORMAT) : null) as any;
        } else {
            const newEndDate = moment(dateFilter).add(type === 'week' ? 7 : 3, 'd');
            params.appointment_date_before = (newEndDate ? moment(newEndDate).format(SHORT_DATE_FORMAT) : null) as any;
            params.appointment_date_after = (dateFilter ? moment(dateFilter).format(SHORT_DATE_FORMAT) : null) as any;
        }
        return params;
    };
    const handleFilter = (key, value) => {
        let params = {
            branch: !selectedBranch ? null : selectedBranch,
            user: !selectedStaff ? null : selectedStaff,
            state: !selectedState ? null : selectedState,
            ...handleCheckDateType(selectedDateType),
        };
        switch (key) {
            case KEY_BRANCH: {
                setSelectedBranch(value);
                setSelectedStaff(allOption.value);
                params.user = null;
                params.branch = !value ? null : value;
                break;
            }
            case KEY_USER: {
                setSelectedStaff(value);
                params.user = !value ? null : value;
                break;
            }
            case KEY_DATE: {
                setDateFilter(value);
                params = {
                    ...params,
                    ...handleCheckDateType(selectedDateType),
                };
                break;
            }
            case KEY_STATUS: {
                setSelectedState(value);
                params.state = !value ? null : value;
                break;
            }
            case KEY_DATE_TYPE: {
                setSelectedDateType(value);
                setDayMode(value as CalendarMode);
                params = {
                    ...params,
                    ...handleCheckDateType(value),
                };
                break;
            }
            default:
                break;
        }

        doGetAppointments(params, () => {
            if (onFiltersUpdate) onFiltersUpdate(params);
        });
    };
    return (
        <Form
            name="appointmentFilter"
            className="spa__appointment-filter d-flex justify-content-between"
            initialValues={{
                dateFilter,
                branch: selectedBranch,
                user: selectedStaff,
                dateType: selectedDateType,
                status: selectedState,
            }}
            form={appointmentFilterForm}
        >
            <div className="d-flex">
                <Select
                    className="mb-0 mr-1"
                    placeholder="Chọn chi nhánh"
                    name={KEY_BRANCH}
                    options={[
                        allOption,
                        ...branchs.map((b) => ({
                            value: b.id,
                            label: b.name,
                        })),
                    ]}
                    onChange={(value) => handleFilter(KEY_BRANCH, value)}
                    style={{ width: 180 }}
                    loading={branchLoading}
                />
                <Select
                    className="mb-0 mr-1"
                    placeholder="Chọn nhân viên"
                    name={KEY_USER}
                    options={[
                        allOption,
                        ...users.map((b) => ({
                            value: b.id,
                            label: b.fullname,
                        })),
                    ]}
                    onChange={(value) => handleFilter(KEY_USER, value)}
                    style={{ width: 180 }}
                    loading={userLoading}
                />
            </div>
            <div className="date-picker d-flex">
                <Button className="prev" onClick={() => setDateFilter(moment(dateFilter?.toDate()).subtract(1, 'd'))}>
                    <i className="icon-chevron-left" />
                </Button>
                <Button className="today" disabled={today.isSame(dateFilter, 'day')} onClick={() => setDateFilter(today)}>
                    Hôm nay
                </Button>
                <DatePicker
                    name="dateFilter"
                    className={selectedDateType}
                    allowClear={false}
                    inputReadOnly
                    showToday={false}
                    onChange={(value) => setDateFilter(value)}
                    mode="date"
                    suffixIcon={null}
                    style={{ width: 200 }}
                    placeholder="Chọn ngày"
                    dropdownClassName={`appointment ${selectedDateType}`}
                    format="dddd, DD MMM, YYYY"
                    dateRender={(current) => {
                        const style = {
                            backgroundColor: '',
                            color: '',
                        };
                        let inRange = false;
                        if (selectedDateType === CalendarMode.OneWeek || selectedDateType === CalendarMode.ThreeDay) {
                            const noDays = selectedDateType === CalendarMode.OneWeek ? 7 : 3;
                            if (current.diff(dateFilter, 'd') < noDays && current.diff(dateFilter, 'd') > 0) {
                                style.backgroundColor = '#202020';
                                style.color = '#fff';
                            }
                        }
                        if (hoverDate) {
                            const noDays = selectedDateType === CalendarMode.OneWeek ? 7 : 3;
                            if (current.diff(dateFilter, 'd') < noDays && current.diff(dateFilter, 'd') > 0) {
                                inRange = true;
                            }
                        }
                        return (
                            <div className={`ant-picker-cell-inner ${inRange ? 'in-range' : ''}`} style={style} onMouseEnter={() => setHoverDate(current)}>
                                {current.date()}
                            </div>
                        );
                    }}
                />
                <Button className="next" onClick={() => setDateFilter(moment(dateFilter.toDate()).add(1, 'd'))}>
                    <i className="icon-chevron-right" />
                </Button>
            </div>
            <div className="d-flex">
                <Select
                    className="mr-1 mb-0"
                    name="status"
                    placeholder="Chọn trạng thái"
                    options={[allOption, ...fakeStatusOptions]}
                    style={{ width: 140 }}
                    onChange={(value) => handleFilter(KEY_STATUS, value)}
                />
                <Select
                    className="mr-1 mb-0"
                    name="dateType"
                    placeholder="Chọn loại hình"
                    options={fakeDateTypeOptions}
                    style={{ width: 110 }}
                    onChange={(value) => handleFilter(KEY_DATE_TYPE, value)}
                />
                <Dropdown
                    overlay={(
                        <Menu onClick={handleCustomize}>
                            <Menu.Item key="appointment">Tạo buổi lẻ</Menu.Item>
                            <Menu.Item key="sale">Bán hàng</Menu.Item>
                        </Menu>
                      )}
                    trigger={['click']}
                    overlayClassName="add-new"
                >
                    <Button type="primary" className="add-new">
                        Tạo mới
                    </Button>
                </Dropdown>
            </div>
        </Form>
    );
};
