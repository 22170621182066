import { Link } from 'react-router-dom';

import './index.scss';
import playStore from 'assets/images/googleplay.png';
import appStore from 'assets/images/appstore.png';
import { LOGIN_URL } from 'actions';

export const AppDownload = ({ className = '', backEnabled = false }) => (
	<div className={`spa__app-download text-center ${className}`}>
		{!backEnabled && <p className="note">Vui lòng tải ứng dụng SPAGO để có trải nghiệm tốt và đầy đủ nhất</p>}
		<div className="spa__app-download-ctas mt-4">
			<a href={process.env.REACT_APP_PLAY_STORE} target="_blank">
				<img src={playStore} alt="SPAGO PlayStore" />
			</a>
			<a href={process.env.REACT_APP_APP_STORE} target="_blank">
				<img src={appStore} alt="SPAGO AppStore" />
			</a>
		</div>
		{backEnabled && (
			<Link to={LOGIN_URL} className="spa__link">
				<i className="icon-arrow-left" />
				<span>Trở về trang đăng nhập</span>
			</Link>
		)}
	</div>
);
