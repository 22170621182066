import { createContext, useState } from 'react';
import { message } from 'antd';
import { OPERATION_FAILED_MESSAGE } from 'actions';
import { removeToken } from 'utils';
import { AuthContextProvider } from './AuthContext';
import { CustomerContextProvider } from './CustomerContext';
import { AppointmentContextProvider } from './AppointmentContext';
import { BranchContextProvider } from './BranchContext';
import { ComboContextProvider } from './ComboContext';
import { InvoiceContextProvider } from './InvoiceContext';
import { NotificationContextProvider } from './NotificationContext';
import { ProductContextProvider } from './ProductContext';
import { RankContextProvider } from './RankContext';
import { SearchContextProvider } from './SearchContext';
import { StatisticContextProvider } from './StatisticContext';
import { UserContextProvider } from './UserContext';
import { SourceContextProvider } from './SourceContext';
import { CustomerGroupContextProvider } from './CustomerGroupContext';
import { CareerContextProvider } from './CareerContext';
import { InvoiceItemContextProvider } from './InvoiceItemContext';
import { ProfileContextProvider } from './ProfileContext';
import { NotificationCategoryContextProvider } from './NotificationCategoryContext';

export const HomeContext = createContext({} as any);

export const HomeContextProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [anotherLoading, setAnotherLoading] = useState(false);
    const [userInfoLoading, setUserInfoLoading] = useState(false);
    const [customerLoading, setCustomerLoading] = useState(false);
    const [appointmentLoading, setAppointmentLoading] = useState(false);
    const [mediaLoading, setMediaLoading] = useState(false);
    const [comboLoading, setComboLoading] = useState(false);
    const [invoiceLoading, setInvoiceLoading] = useState(false);
    const [invoiceItemLoading, setInvoiceItemLoading] = useState(false);
    const [profileLoading, setProfileLoading] = useState(false);
    const [receiptLoading, setReceiptLoading] = useState(false);
    const [customerTreatmentLoading, setCustomerTreatmentLoading] = useState(false);
    const [callLoading, setCallLoading] = useState(false);
    const [customerInterestCategoryLoading, setCustomerInterestCategoryLoading] = useState(false);
    const [statisticLoading, setStatisticLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [debtLoading, setDebtLoading] = useState(false);
    const [userLoading, setUserLoading] = useState(false);
    const [branchLoading, setBranchLoading] = useState(false);
    const [feedbackLoading, setFeedbackLoading] = useState(false);
    const [feedbackCategoryLoading, setFeedbackCategoryLoading] = useState(false);
    const [productLoading, setProductLoading] = useState(false);
    const [shiftLoading, setShiftLoading] = useState(false);
    const [tenantLoading, setTenantLoading] = useState(false);
    const [promotionLoading, setPromotionLoading] = useState(false);
    const [notificationLoading, setNotificationLoading] = useState(false);
    const [checkoutLoading, setCheckoutLoading] = useState(false);
    const [printerLoading, setPrinterLoading] = useState(false);

    const handleException = (error: any) => {
        setLoading(false);
        setAnotherLoading(false);
        setUserInfoLoading(false);
        setCustomerLoading(false);
        setAppointmentLoading(false);
        setMediaLoading(false);
        setComboLoading(false);
        setInvoiceLoading(false);
        setInvoiceItemLoading(false);
        setProfileLoading(false);
        setReceiptLoading(false);
        setCustomerTreatmentLoading(false);
        setCallLoading(false);
        setCustomerInterestCategoryLoading(false);
        setStatisticLoading(false);
        setSearchLoading(false);
        setDebtLoading(false);
        setUserLoading(false);
        setBranchLoading(false);
        setFeedbackLoading(false);
        setFeedbackCategoryLoading(false);
        setProductLoading(false);
        setShiftLoading(false);
        setTenantLoading(false);
        setPromotionLoading(false);
        setNotificationLoading(false);
        setCheckoutLoading(false);
        setPrinterLoading(false);
        if (typeof error === 'string') {
            message.error(error);
            return;
        }
        if (error?.response?.status === 401) {
            removeToken();
            window.location.reload();
        }
        const { data } = error?.response || {
            data: {
                errors: [],
            },
        };
        const errors = Object.keys(data);
        message.error((data && (data.detail || data.message || data.error || data[errors[0]] || data.errors[0])) || OPERATION_FAILED_MESSAGE);
    };

    return (
        <HomeContext.Provider
            value={{
                loading,
                anotherLoading,
                userInfoLoading,
                customerLoading,
                appointmentLoading,
                mediaLoading,
                comboLoading,
                invoiceLoading,
                invoiceItemLoading,
                profileLoading,
                receiptLoading,
                customerTreatmentLoading,
                callLoading,
                customerInterestCategoryLoading,
                statisticLoading,
                searchLoading,
                debtLoading,
                userLoading,
                branchLoading,
                feedbackLoading,
                feedbackCategoryLoading,
                productLoading,
                shiftLoading,
                tenantLoading,
                promotionLoading,
                notificationLoading,
                checkoutLoading,
                printerLoading,
                setLoading,
                setAnotherLoading,
                setUserInfoLoading,
                setCustomerLoading,
                setAppointmentLoading,
                setMediaLoading,
                setComboLoading,
                setInvoiceLoading,
                setInvoiceItemLoading,
                setProfileLoading,
                setReceiptLoading,
                setCustomerTreatmentLoading,
                setCallLoading,
                setCustomerInterestCategoryLoading,
                setStatisticLoading,
                setSearchLoading,
                setDebtLoading,
                setUserLoading,
                setBranchLoading,
                setFeedbackLoading,
                setFeedbackCategoryLoading,
                setProductLoading,
                setShiftLoading,
                setTenantLoading,
                setPromotionLoading,
                setNotificationLoading,
                setCheckoutLoading,
                handleException,
                setPrinterLoading,
            }}
        >
            <AuthContextProvider>
                <NotificationCategoryContextProvider>
                    <NotificationContextProvider>
                        <StatisticContextProvider>
                            <SearchContextProvider>
                                <BranchContextProvider>
                                    <UserContextProvider>
                                        <RankContextProvider>
                                            <SourceContextProvider>
                                                <CustomerGroupContextProvider>
                                                    <CareerContextProvider>
                                                        <CustomerContextProvider>
                                                            <ProductContextProvider>
                                                                <AppointmentContextProvider>
                                                                    <ComboContextProvider>
                                                                        <InvoiceContextProvider>
                                                                            <InvoiceItemContextProvider>
                                                                                <ProfileContextProvider>
                                                                                    {children}
                                                                                </ProfileContextProvider>
                                                                            </InvoiceItemContextProvider>
                                                                        </InvoiceContextProvider>
                                                                    </ComboContextProvider>
                                                                </AppointmentContextProvider>
                                                            </ProductContextProvider>
                                                        </CustomerContextProvider>
                                                    </CareerContextProvider>
                                                </CustomerGroupContextProvider>
                                            </SourceContextProvider>
                                        </RankContextProvider>
                                    </UserContextProvider>
                                </BranchContextProvider>
                            </SearchContextProvider>
                        </StatisticContextProvider>
                    </NotificationContextProvider>
                </NotificationCategoryContextProvider>
            </AuthContextProvider>
        </HomeContext.Provider>
    );
};
