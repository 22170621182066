import { createContext, useContext, useState } from 'react';
import {
 getMedias, getMedia, addMedia, updateMedia, deleteMedia,
} from 'actions';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';

export const MediaContext = createContext({} as any);

export const MediaContextProvider = ({ children }) => {
    const { setMediaLoading, handleException } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [medias, setMedias] = useState<any[]>([]);
    const [media, setMedia] = useState<any>();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalMedias, setTotalMedias] = useState(0);

    const doGetMedias = async (params, callback) => {
        try {
            setMediaLoading(true);

            const response = await getMedias(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const mediaData = data.results || [];
                setMedias(mediaData);
                setCurrentPage(params?.page || 1);
                setTotalMedias(data.count);
                if (callback) callback();
            }

            setMediaLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doGetMedia = async (mediaId, callback) => {
        try {
            setMediaLoading(true);

            const response = await getMedia(mediaId, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                setMedia(data);
                if (callback) callback();
            }

            setMediaLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doAddMedia = async (payload, callback) => {
        try {
            setMediaLoading(true);

            const response = await addMedia(payload, loggedInUser);
            if (response && response.status === 201) {
                if (callback) callback();
            }

            setMediaLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doUpdateMedia = async (mediaId, payload, callback) => {
        try {
            setMediaLoading(true);

            const response = await updateMedia(mediaId, payload, loggedInUser);
            if (response && response.status === 200) {
                if (callback) callback();
            }

            setMediaLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doDeleteMedia = async (mediaId, callback) => {
        try {
            setMediaLoading(true);

            const response = await deleteMedia(mediaId, loggedInUser);
            if (response && response.status === 204) {
                if (callback) callback();
            }

            setMediaLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <MediaContext.Provider
            value={{
                medias,
                media,
                currentPage,
                totalMedias,
                doGetMedias,
                doGetMedia,
                doAddMedia,
                doUpdateMedia,
                doDeleteMedia,
            }}
        >
            {children}
        </MediaContext.Provider>
    );
};
