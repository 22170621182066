import './index.scss';

export const AppRequired = ({ title }) => (
	<div className="spa__app-required">
		<div className="spa__app-required--icon">
			<i className="icon-download" />
		</div>
		{title && <p className="mt-4 mb-4 text-center">{title}</p>}
	</div>
);
