import { createContext, useContext, useState } from 'react';
import { getFeedbackCategories } from 'actions';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';

export const FeedbackCategoryContext = createContext({} as any);

export const FeedbackCategoryContextProvider = ({ children }) => {
    const { setFeedbackCategoryLoading, handleException } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [feedbackCategories, setFeedbackCategories] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalFeedbackCategories, setTotalFeedbackCategories] = useState(0);

    const doGetFeedbackCategories = async (params, callback) => {
        try {
            setFeedbackCategoryLoading(true);

            const response = await getFeedbackCategories(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const feedbackCategoryData = data.results || [];
                setFeedbackCategories(feedbackCategoryData);
                setCurrentPage(params?.page || 1);
                setTotalFeedbackCategories(data.count);
                if (callback) callback();
            }

            setFeedbackCategoryLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <FeedbackCategoryContext.Provider
            value={{
                feedbackCategories,
                currentPage,
                totalFeedbackCategories,
                doGetFeedbackCategories,
            }}
        >
            {children}
        </FeedbackCategoryContext.Provider>
    );
};
