import axios from 'axios';
import {
 authHeaders, POST_LOGIN, GET_USER_INFO, PATCH_USER_INFO, PUT_CHANGE_PASSWORD,
} from 'actions';

/** Login
 * @param {
 *      phoneNumber: string
 *      password: string
 * } payload
 */
export const login = (payload) => {
    if (!payload) return null;
    return axios.post(POST_LOGIN, payload);
};

/** Get info
 */
export const getUserInfo = (token) => axios.get(GET_USER_INFO, authHeaders(token));

/** Update info
 */
export const updateUser = (userId, payload, token) => {
    if (!payload) return null;
    return axios.patch(PATCH_USER_INFO.replace(':userId', userId), payload, authHeaders(token, 'multipart/form-data'));
};

/** Change password
 */
export const changePassword = (payload, token) => {
    if (!payload) return null;
    return axios.post(PUT_CHANGE_PASSWORD, payload, authHeaders(token));
};
