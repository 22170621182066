import { createContext, useContext, useState } from 'react';
import {
    getCustomerInterestCategories,
    getCustomerInterestCategory,
    addCustomerInterestCategory,
    updateCustomerInterestCategory,
    deleteCustomerInterestCategory,
} from 'actions';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';

export const CustomerInterestCategoryContext = createContext({} as any);

export const CustomerInterestCategoryContextProvider = ({ children }) => {
    const { setCustomerInterestCategoryLoading, handleException } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [customerInterestCategories, setCustomerInterestCategories] = useState<any[]>([]);
    const [customerInterestCategory, setCustomerInterestCategory] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCustomerInterestCategories, setTotalCustomerInterestCategories] = useState(0);

    const doGetCustomerInterestCategories = async (params, callback) => {
        try {
            setCustomerInterestCategoryLoading(true);

            const response = await getCustomerInterestCategories(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const customerInterestCategoryData = data.results || [];
                setCustomerInterestCategories(customerInterestCategoryData);
                setCurrentPage(params?.page || 1);
                setTotalCustomerInterestCategories(data.count);
                if (callback) callback();
            }

            setCustomerInterestCategoryLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doGetCustomerInterestCategory = async (customerInterestCategoryId, callback) => {
        try {
            setCustomerInterestCategoryLoading(true);

            const response = await getCustomerInterestCategory(customerInterestCategoryId, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                setCustomerInterestCategory(data);
                if (callback) callback();
            }

            setCustomerInterestCategoryLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doAddCustomerInterestCategory = async (payload, callback) => {
        try {
            setCustomerInterestCategoryLoading(true);

            const response = await addCustomerInterestCategory(payload, loggedInUser);
            if (response && response.status === 201) {
                if (callback) callback();
            }

            setCustomerInterestCategoryLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doUpdateCustomerInterestCategory = async (customerInterestCategoryId, payload, callback) => {
        try {
            setCustomerInterestCategoryLoading(true);

            const response = await updateCustomerInterestCategory(customerInterestCategoryId, payload, loggedInUser);
            if (response && response.status === 200) {
                if (callback) callback();
            }

            setCustomerInterestCategoryLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    const doDeleteCustomerInterestCategory = async (customerInterestCategoryId, callback) => {
        try {
            setCustomerInterestCategoryLoading(true);

            const response = await deleteCustomerInterestCategory(customerInterestCategoryId, loggedInUser);
            if (response && response.status === 204) {
                if (callback) callback();
            }

            setCustomerInterestCategoryLoading(false);
        } catch (error) {
            handleException(error);
        }
    };

    return (
        <CustomerInterestCategoryContext.Provider
            value={{
                customerInterestCategories,
                customerInterestCategory,
                currentPage,
                totalCustomerInterestCategories,
                doGetCustomerInterestCategories,
                doGetCustomerInterestCategory,
                doAddCustomerInterestCategory,
                doUpdateCustomerInterestCategory,
                doDeleteCustomerInterestCategory,
            }}
        >
            {children}
        </CustomerInterestCategoryContext.Provider>
    );
};
